import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commentsfilter',
  pure: false
})
export class CommentsfilterPipe implements PipeTransform {

  transform(items: any, filter: Object): unknown {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.parent === filter);
  }

}
