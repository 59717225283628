import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: any, format: any = 'M/D/YYYY', timezone?: any): any {
    if (value && value !== undefined) {
      const testDateUtc = value;
      if (timezone) {
        return moment(testDateUtc).tz(timezone).format(format);
      } else {
        return moment(testDateUtc).local().format(format);
      }

    }
  }

}
