import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class MenuOverlayService {

  overlayOpen: any = false;

  private overlayOpenSource = new BehaviorSubject<any>('');
  currentOverlayOpen = this.overlayOpenSource.asObservable();

  constructor() { }

  async updateOverlayOpen(overlayOpen: any) {
    this.overlayOpenSource.next(overlayOpen);
  }

}
