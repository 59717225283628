import { NgModule } from '@angular/core';

import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {MatBadgeModule} from '@angular/material/badge';


@NgModule({
  imports: [
    MatSortModule,
    MatTableModule,
    MatBadgeModule,
  ],
  exports: [
    MatSortModule,
    MatTableModule,
    MatBadgeModule,
  ],
})
export class MaterialModule {}
