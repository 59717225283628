// Angular
import { Component, OnInit } from '@angular/core';

// Ionic
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-session-complete',
  templateUrl: './session-complete.page.html',
  styleUrls: ['./session-complete.page.scss'],
})
export class SessionCompletePage implements OnInit {

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

}
