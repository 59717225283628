/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// Ionic
import { AlertController } from '@ionic/angular';

// Interfaces
import Exercise, { ExerciseFormData, ExerciseList, ExerciseSingle } from '../_interfaces/Exercise.interface';
import BodyPart from '../_interfaces/BodyParts.interface';

// Env
import Env from './../env';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController
  ) { }

  /**
   * Fetch by ID
   *
   * @param exercise_id Exercise's ID
   * @returns promise
   */
  async fetchByID(exercise_id: number) {
    return new Promise((resolve, reject) => {

      if (!exercise_id) {
        reject('No exercise_id provided');
      }
      this.http.get(`${Env.api()}/exercises/${exercise_id}`).subscribe((response: ExerciseSingle) => {
        resolve(response.data);
      }, err => {
        this.alertCtrl.create({
          header: 'Error Fetching Exercise',
          message: 'There was an error fetching the exercise. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Fetch List
   *
   * @returns promise
   */
  async fetchList() {
    return new Promise((resolve, reject) => {

      this.http.get(`${Env.api()}/exercises`).pipe(map((res: ExerciseList) => {
        if (res.data.exercises.length) {
          res.data.exercises.forEach((movement) => {
            let bodyPartString = '';
            const bodyparts = [];
            if (movement.body_parts_primary.length) {
              movement.body_parts_primary.forEach((body_part: BodyPart) => {
                bodyparts.push(body_part.name);
              });
            }
            if (movement.body_parts_secondary.length) {
              movement.body_parts_secondary.forEach((body_part: BodyPart) => {
                bodyparts.push(body_part.name);
              });
            }
            bodyPartString = bodyparts.join(', ');
            movement.body_parts_string = bodyPartString;
          });
        }
       return res;
      })).subscribe((response: ExerciseList) => {
        const filtered = response.data.exercises;
        resolve(filtered);
      }, err => {
        switch (err?.status) {
          default:
            this.alertCtrl.create({
              header: 'Error Fetching Exercise List',
              message: 'There was an error fetching the exercise list. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
        }
      });

    });
  }

  /**
   * Filter List
   *
   * @returns promise
   */
   async filterList(searchTerm: string) {
    return new Promise((resolve, reject) => {

      this.http.get(`${Env.api()}/exercises`).pipe(map((res: ExerciseList) => {
        if (res.data.exercises.length) {
          res.data.exercises.forEach((movement) => {
            let bodyPartString = '';
            const bodyparts = [];
            if (movement.body_parts_primary.length) {
              movement.body_parts_primary.forEach((body_part: BodyPart) => {
                bodyparts.push(body_part.name);
              });
            }
            if (movement.body_parts_secondary.length) {
              movement.body_parts_secondary.forEach((body_part: BodyPart) => {
                bodyparts.push(body_part.name);
              });
            }
            bodyPartString = bodyparts.join(', ');
            movement.body_parts_string = bodyPartString;
          });
        }
       return res;
      })).subscribe((response: ExerciseList) => {


        const filtered =  response.data.exercises.filter(ex => {
          return ex.name.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1;
        });

        resolve(filtered);

      }, err => {
        switch (err?.status) {
          default:
            this.alertCtrl.create({
              header: 'Error Fetching Exercise List',
              message: 'There was an error fetching the exercise list. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
        }
      });

    });
  }

  /**
   * Create
   *
   * @param exercise Exercise form values
   * @returns promise
   */
  async create(exercise: ExerciseFormData) {
    return new Promise((resolve, reject) => {

      if (!exercise) {
        reject('No exercise provided');
      }

      this.http.post(`${Env.api()}/exercises/create`, exercise).subscribe((response: ExerciseSingle) => {
        if (response.status === 201) {
          this.update(response.data.id, exercise).then(() => {
            resolve(true);
          });
        }
      }, err => {
        switch (err?.status) {
          case 422:
            this.alertCtrl.create({
              header: 'Fields Required',
              message: 'Please complete all required fields.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
          default:
            this.alertCtrl.create({
              header: 'Error Creating Exercise',
              message: 'There was an error creating the exercise. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
        }
      });

    });
  }

  /**
   * Attach Body Part to Exercise
   *
   * @param exercise Exercise ID
   * @param body_part Body Part ID
   * @param type Type of body part
   * @returns promise
   */
  async addBodyPartExercise(exercise, body_part, focus_type: 'primary' | 'secondary') {
    return new Promise((resolve, reject) => {
      if (!exercise || !body_part) {
        reject('Parameters not met');
      }
      this.http.post(`${Env.api()}/exercises/add/body-part`, {
        exercise, body_part, focus_type
      }).subscribe(res => {
        resolve(true);
      });
    });
  }

    /**
   * Attach Subs to Exercise
   *
   * @param primary_exercise Exercise ID
   * @param sub_exercise Subs ID
   * @returns promise
   */
     async addSubExercise(primary_exercise, sub_exercise, note) {
      return new Promise((resolve, reject) => {
        if (!primary_exercise || !sub_exercise || !note) {
          reject('Parameters not met');
        }
        this.http.post(`${Env.api()}/exercises/subs/create`, {
          primary_exercise, sub_exercise, note
        }).subscribe(res => {
          console.log(res);
          resolve(true);
        });
      });
    }

  /**
   * Update
   *
   * @param exerciseID ID of Exercise to Update
   * @param exercise Exercise form values
   * @param body_parts Array of Body Part IDs from the original entry
   * @returns promise
   */
  async update(exerciseID: number, exercise: ExerciseFormData, body_parts?: []) {
    return new Promise((resolve, reject) => {

      if (!exercise) {
        reject('No exercise provided');
      }

      this.http.post(`${Env.api()}/exercises/update/${exerciseID}`, exercise).subscribe((response: ExerciseSingle) => {
        if (response.status === 200) {
          resolve(true);
        }
      }, err => {
        switch (err?.status) {
          case 422:
            this.alertCtrl.create({
              header: 'Fields Required',
              message: 'Please complete all required fields.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
          default:
            this.alertCtrl.create({
              header: 'Error Updating Exercise',
              message: 'There was an error updating the exercise. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
            break;
        }
      });

    });
  }

  /**
   * Soft Delete
   *
   * @param exercise Exercise object
   * @returns promise
   */
  async delete(exercise: Exercise) {
    return new Promise((resolve, reject) => {

      if (!exercise) {
        reject('No exercise provided');
      }

      this.http.post(`${Env.api()}/exercises/delete/${exercise.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.alertCtrl.create({
          header: 'Error Deleting Exercise',
          message: 'There was an error deleting the exercise. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });

    });
  }

}
