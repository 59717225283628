import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

// Service
import { UserService } from 'src/app/_services/user.service';
import { NotificationsService } from 'src/app/_services/notifications.service';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import { Notification } from 'src/app/_interfaces/Notification.interface';
import Utils from 'src/app/utils';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  userProfile: UserProfile;

  userTimezone: string;

  notificationUpdate: any;
  notificationItems: Notification[];

  page = 1;

  isDisable:Boolean = false;
  showOnlyRead:Boolean = false;

  constructor(
    private app: AppComponent,
    private user: UserService,
    private router: Router,
    private notificationsService: NotificationsService,
  ) {

    this.notificationsService.notificationsList.subscribe((notifications: Notification[]) => {

      // this.notificationItems = notifications;

      // this.notificationItems?.sort(Utils.fieldSorter(['-created_at']));

    });

   }

  ngOnInit() {

    this.page = 1;

    this.showOnlyRead = false;

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;

      if (this.userProfile?.timezone) {
        this.userTimezone = this.userProfile?.timezone;
      } else {
        this.userTimezone = 'America/New_York';
      }

      if(this.userProfile) {
        this.fetchNotifications(this.userProfile?.id);
      }


    });



  }

  ionViewWillEnter() {

    this.page = 1;

    this.showOnlyRead = false;

    if(this.userProfile) {
      this.fetchNotifications(this.userProfile?.id);
    }

  }

  ionViewWillLeave() {

    this.page = 1;
    this.isDisable = false;

  }

  /**
   * Close the notification list
   * @param link Router link to navigate to after closing (optional)
   * @param delay Delay (in milliseconds) that the notification list should close
   */
   closeNotifications(link: any = false, delay: number, notificationId?: number) {
    if (link) {
      this.markOneAsRead(notificationId);
      this.router.navigate([link]);
    }
    setTimeout(() => {
      this.page = 1;
      this.isDisable = false;
      this.showOnlyRead = false;
      this.fetchNotifications(this.userProfile?.id);
      this.app.closeNotifications();
    }, delay);
  }

  /**
   * Fetch List of All Notifications
   */
   async fetchNotifications(user_id: number) {
    return new Promise((resolve) => {


      this.notificationsService.getNotifications(user_id, this.page).then((notifications) => {

        this.notificationItems = notifications;

        // this.notificationItems?.sort(Utils.fieldSorter(['-created_at']));

        resolve(true);

      });

    });
  }

  markOneAsRead(notificationId) {


    this.notificationsService.readNotification(notificationId).then((data) => {
        this.fetchNotifications(this.userProfile?.id);
        // console.log('data', data);
        // console.log('!!!!!!!!!!Marked one read', notificationId);
      })
      .catch((reason) => {
        console.log('err:', reason);
      });
  }

  markAsRead() {


    this.notificationsService.readAllNotifications(this.userProfile?.id).then((data) => {
        this.fetchNotifications(this.userProfile?.id);
        // console.log('data', data);
        // console.log('!!!!!!!!!!Marked one read', notificationId);
      })
      .catch((reason) => {
        console.log('err:', reason);
      });
  }

   /**
   * Refresh List of Posts
   *
   * @param event Refresh event
   */
    doRefresh(event) {
      this.page = 1;
      this.isDisable = false;
      this.showOnlyRead = false;
      this.fetchNotifications(this.userProfile?.id).then(() => {
        setTimeout(() => {
          event.target.complete();
        }, 1000);
      });
    }

  /**
   * Infinite Load Event
   *
   * @param e Event
   * @param category_id Category selected
   */
   infiniteLoad(e) {
    this.page++;
    this.showOnlyRead = false;
    this.notificationsService.getNotifications(this.userProfile?.id, this.page).then((notifications) => {
      if (notifications.length) {
        this.notificationItems = [...this.notificationItems, ...notifications];
        console.log(this.notificationItems);
        // e.target.complete();
      } else {
        this.isDisable = true;
      }
    });
  }

  onlyUnread() {
    console.log('onlyUnread: ', this.showOnlyRead);
    this.showOnlyRead = !this.showOnlyRead;
    if(this.showOnlyRead === true) {
      this.notificationsService.getNotificationsUnreaded(this.userProfile?.id).then((notifications) => {
        if (notifications.length) {
          this.notificationItems = notifications;
          // e.target.complete();
        } else {
          this.notificationItems = notifications;
          this.isDisable = true;
        }
      });
    } else {
      this.page = 1;
      this.isDisable = false;
      this.fetchNotifications(this.userProfile?.id);
    }

  }

}
