/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Ionic
import { AlertController, IonContent, IonSlides, ModalController, PopoverController, ToastController } from '@ionic/angular';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import { Measurement, MeasurementForm } from 'src/app/_interfaces/Measurements.interface';
import { SkillLevel } from 'src/app/_interfaces/SkillLevel.interface';
import { Goals } from 'src/app/_interfaces/Goals.interface';
import { MacroQuestionLossGoal, MacroSet } from 'src/app/_interfaces/Onboarding.interface';
import Macros from 'src/app/_interfaces/Macros.interface';
import Program from 'src/app/_interfaces/Program.interface';

// Services
import { UserService } from 'src/app/_services/user.service';
import { MeasurementsService } from 'src/app/_services/measurements.service';
import { SkilllevelsService } from 'src/app/_services/skilllevels.service';
import { MacrosService } from 'src/app/_services/macros.service';
import { GoalsService } from 'src/app/_services/goals.service';
import { ProgramsService } from 'src/app/_services/programs.service';

// Components
import { TooltipPage } from 'src/app/_components/tooltip/tooltip.page';

// Utils
import Utils from './../../utils';
import * as moment from 'moment';
import { ProgramDetailsPage } from 'src/app/_components/program-details/program-details.page';
import { UserProgram } from 'src/app/_interfaces/UserPrograms.interface';
import { UserProgramsService } from 'src/app/_services/user-programs.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.page.html',
  styleUrls: ['./onboarding.page.scss'],
})
export class OnboardingPage implements OnInit {

  @Input() mode = 'onboarding';
  @Input() firstTime = false;

  // Optional parameters to pass to the swiper instance.
  // See http://idangero.us/swiper/api/ for valid options.
  @ViewChild(IonSlides, {static: false}) slides: IonSlides;
  slideOpts: any;

  userProfile: UserProfile;
  userCurrentProgram: UserProgram;
  userCurrentProgramDetails: Program;

  basicInfo: FormGroup;
  backgroundInfo: FormGroup;
  macroInfo: FormGroup;

  slideIndex: number;

  unit_of_measurement: string;

  skillLevels: SkillLevel[];
  goals: Goals[];

  macroPreference: string = 'straight';
  macroSetsCalculated: false;
  macroQuestionLossGoals: MacroQuestionLossGoal[];

  height: number; // inches
  weight: number; // lbs
  goal: Goals;
  macros: MacroSet;

  macroGoals: Macros[]; // Array of macros a user may already have

  programs: Program[];

  constructor(
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    public user: UserService,
    private measurementsService: MeasurementsService,
    private skillLevelsService: SkilllevelsService,
    private programsService: ProgramsService,
    private userProgramService: UserProgramsService,
    private macrosService: MacrosService,
    private goalsService: GoalsService,
    private toastCtrl: ToastController,
    public popoverController: PopoverController,
    private alertCtrl: AlertController,
  ) {

    // Basic Info
    this.basicInfo = this.fb.group({
      dob: [''],
      height: [''],
      height_ft: [''],
      height_in: [''],
      starting_weight_date: [''],
      weight: [''],
      weight_lb: [''],
      weight_kg: [''],
      gender: [''],
      unit_of_measurement: ['']
    });

    this.basicInfo.valueChanges.subscribe(data => {
      this.convertHeightForPayload(data, false);
      this.convertWeightForPayload(data, false);
    });

    // Background
    this.backgroundInfo = this.fb.group({
      skill_level: ['', Validators.required],
      goal: ['', Validators.required]
    });

    // Background: Enforce numerical values
    this.backgroundInfo.valueChanges.subscribe(data => {
      data.skill_level = Number(data.skill_level);
      data.goal = Number(data.goal);
      this.fetchListOfProgramsBasedOnBackgroundSelection(data);
    });

    // Macro Info
    this.macroInfo = this.fb.group({
      main_goal: [''],
      demanding_profession: [false],
      weight_loss_goal: [12]
    });

    this.macroInfo.valueChanges.subscribe(data => {

      if (data.main_goal !== 'Lose Fat') {
        this.macroInfo.patchValue({
          weight_loss_goal: 12
        }, {emitEvent: false});
      }

      this.calculateMacros();
    });

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;

      // If we have a user object, fetch their associated macros and program
      if (this.userProfile?.id) {

        this.macrosService.fetchMacrosByUserID(this.userProfile).then(macros => {
          this.macroGoals = macros;
        });

        this.fetchUserPrograms();

      }

    });

    this.macroQuestionLossGoals = macrosService.macroQuestionLossGoals();

  }

  ionViewWillEnter(){

    // Ensure slides are re-initiated after modal is opened
    this.slides.update();

    // Assign unit of measurement, default to imperial
    this.unit_of_measurement = (this.userProfile.unit_of_measurement) ? this.userProfile.unit_of_measurement : 'imperial';

    // Prepare Form 1
    this.basicInfo.patchValue({
       dob: moment(this.userProfile.dob).format('YYYY-MM-DD'),
       gender: this.userProfile.gender,
       unit_of_measurement: this.unit_of_measurement
    }, {emitEvent: false});

    // Convert and patch height
    this.convertHeightForInputFields(Number(this.userProfile.height));

    // If no weight, default to zero
    this.userProfile.weight = (this.userProfile.weight) ? this.userProfile.weight : '0';

    // Convert and patch weight
    this.convertWeightForInputField(Number(this.userProfile.weight));

    // Prepare Form 2
    this.backgroundInfo.patchValue({
      skill_level: this.userProfile.skill_level,
      goal: this.userProfile.goal
    }, {emitEvent: false});

    // Fetch list of skill levels
    this.skillLevelsService.fetchSkillLevels().then(skillLevels => {
      this.skillLevels = skillLevels;
    });

    // Fetch list of goals
    this.goalsService.fetchGoals().then(goals => {
      this.goals = goals;
    });

  }

  ionViewDidEnter() {

    // Determine active slide index
    this.slides.ionSlideDidChange.subscribe(() => {
      this.slides.getActiveIndex().then(index => {

        this.slideIndex = index;
        console.log('slideIndex', this.slideIndex);

        // Check for macros based on input
        this.calculateMacros();

        // Ensure background info
        this.backgroundInfo.updateValueAndValidity({ onlySelf: false, emitEvent: true });

      });
    });

  }

  ngOnInit() {

    // Setup slides
    this.slideOpts = {
      initialSlide: 0,
      speed: 400,
      noSwipingClass: 'swiper-no-swiping',
    };

  }

  /**
   * Navigate to previous slide
   */
  prevSlide() {
    this.slides.slidePrev();
  }

  /**
   * Navigate to next slide
   */
  nextSlide() {
    this.slides.slideNext();
  }

  /**
   * Change Date of Birth
   *
   * @param event Input event data
   */
  changeDOB(event) {
    const dob = moment(event.target.value).format('YYYY-MM-DD');
    this.basicInfo.patchValue({
      dob
    }, {emitEvent: false});
  }

  /**
   * Change Starting Weight Date
   *
   * @param event Input event data
   */
  changeStartingWeightDate(event) {
    const starting_weight_date = moment(event.target.value).format('YYYY-MM-DD');
    this.basicInfo.patchValue({
      starting_weight_date
    });
  }

  /**
   * Select Unit of Measurement
   *
   * @param event Radio Select Event
   */
  unitOfMeasurementSelect(event) {
    this.basicInfo.patchValue({
      unit_of_measurement: event.target.value
    });
  }

  /**
   * Convert Height to Inches for API
   *
   * @param data Form data
   */
  convertHeightForPayload(data: any, patch: boolean = true) {

      let heightInches = 0;

      switch (data.unit_of_measurement) {
        case 'imperial':
          heightInches = Number(data.height_ft) * 12 + Number(data.height_in);
          break;
        case 'hybrid':
          heightInches = Number(data.height_ft) * 12 + Number(data.height_in);
          break;
        case 'metric':
          heightInches = Utils.toInches(data.height, true);
          break;
      }

      if (patch) {
        // Ensure both input fields are populated with the latest values if the user switches
        this.convertHeightForInputFields(Number(heightInches));
      }

      this.height = heightInches;

      return heightInches;
  }

  /**
   * Convert Weight to Lbs for API
   *
   * @param data Form data
   */
  convertWeightForPayload(data: any, patch: boolean = true) {

      let weightPounds = 0;

      switch (data.unit_of_measurement) {
        case 'imperial':
          weightPounds = Number(data.weight_lb);
          break;
        case 'hybrid':
          weightPounds = Number(data.weight_lb);
          break;
        case 'metric':
          weightPounds = Number(Utils.toPounds(data.weight_kg, false));
          break;
      }

      this.weight = weightPounds;

      if (patch) {
        // Ensure both input fields are populated with the latest values if the user switches
        this.convertWeightForInputField(weightPounds);
      }

      return weightPounds;
  }


  /**
   * Convert height in inches and populate input fields
   *
   * @param height Height in inches
   */
  convertHeightForInputFields(height: number) {

    if (!height) { return; }

    const heightInches = Number(height);

    // Imperial
    const feet = Math.floor(heightInches / 12);
    const inches = heightInches - (feet * 12);

    // Metric
    const cm = Utils.toCentimeters(heightInches, false);

    // Patch Form
    this.basicInfo.patchValue({
      height_ft: feet,
      height_in: inches,
      height: cm,
    }, {emitEvent: false});

  }

  /**
   * Convert weight and populate input fields
   *
   * @param weight Weight in pounds
   */
  convertWeightForInputField(weight: number | string) {

    if (!weight) { return; }

    this.basicInfo.patchValue({
      weight_lb: Number(weight).toFixed(2)
    }, {emitEvent: false});

    this.basicInfo.patchValue({
      weight_kg: Number(Utils.toKilograms(Number(weight))).toFixed(2)
    }, {emitEvent: false});

  }

  /**
   * Save Weight Measurements
   */
  saveMeasurements(data) {
    return new Promise<string>((resolve) => {

      if (!data.starting_weight_date || !data.weight) {
        resolve('No starting_weight_date or weight provided');
        return;
      }

      const payload: MeasurementForm = {
        user: this.userProfile.id,
        date_index: moment(data.starting_weight_date).format('YYYY-MM-DD'),
        date_recorded: moment(data.starting_weight_date).format('YYYY-MM-DD HH:mm:SS'),
        weight: data.weight,
      };

      // Check API if this user has measurements for a given date
      // If they are found, update the record
      this.measurementsService.fetchAll(this.userProfile, moment(data.starting_weight_date).format('YYYY-MM-DD')).then((measurement: Measurement[]) => {
        if (measurement.length) {

          payload.belly_high = measurement[0].belly_high;
          payload.belly_button = measurement[0].belly_button;
          payload.belly_low = measurement[0].belly_low;
          payload.hips = measurement[0].hips;
          payload.chest = measurement[0].chest;
          payload.left_quad = measurement[0].left_quad;
          payload.right_quad = measurement[0].right_quad;
          payload.left_bicep = measurement[0].left_bicep;
          payload.right_bicep = measurement[0].right_bicep;

          this.measurementsService.update(measurement[0], payload).then(() => {
            resolve('Measurement record updated');
          });

        // If they are not found, write a new record
        } else {

          this.measurementsService.add(payload).then(() => {
            resolve('New measurements record added');
          });

        }
      });

    });
  }

  /**
   * When proceeding to the second onboarding step, save basic info data
   *
   * @param data Form Data
   */
  saveBasicInfoData(data = this.basicInfo.value) {

    // Normalize height and weight for API
    data.height = this.convertHeightForPayload(data);
    data.weight = this.convertWeightForPayload(data);

    // Save starting weight info as new measurement record
    this.saveMeasurements(data).then(response => {
      console.log(response);
    });

    // Remove extra keys from payload for profile
    delete data.weight;
    delete data.height_ft;
    delete data.height_in;
    delete data.weight_lb;
    delete data.weight_kg;

    // Update profile information
    this.user.update(this.userProfile, data).then(() => {
      console.log('Profile updated');
    });

  }

  /**
   * Fetch List of Programs Based on Background Fields
   *
   * @param data Form Data
   */
  fetchListOfProgramsBasedOnBackgroundSelection(data = this.backgroundInfo.value) {
    this.programsService.findProgramByGoalAndSkill(Number(data.goal), Number(data.skill_level)).then(programs => {
      this.programs = programs;
    });
  }

  /**
   * Fetch full list of user programs, filtered by this user ID
   */
   fetchUserPrograms() {

    // User's current program
    this.userProgramService.fetchUserCurrentProgram(this.userProfile.id, 1, 0).then(userProgram => {

      this.userCurrentProgram = userProgram;

      // Program details
      this.programsService.fetchByID(userProgram.program_id).then(program => {

        this.userCurrentProgramDetails = program;

      });

    });

  }

  /**
   * Open Program Details Modal
   *
   * @param program Program object
   */
   async programDetails(program?: Program) {

    const modal = await this.modalCtrl.create({
      component: ProgramDetailsPage,
      cssClass: 'anyman-modal dark',
      componentProps: {
        userProfile: this.userProfile,
        program,
        userCurrentProgram: this.userCurrentProgram,
      }
    });
    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        console.log('modal dismissed');
      });
    });
  }

  /**
   * Enroll user in program
   *
   * @param program Program object
   */
   enroll(program: Program) {
    // Are you sure?
    this.alertCtrl.create({
      header: `Enroll in the ${program.name}?`,
      message: `Please confirm that you would like to enroll in the ${program.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Enroll',
          handler: () => {
            this.userProgramService.enrollInProgram(program, this.userProfile.id).then((response) => {
              this.user.fetchProfile(this.userProfile.id);
              this.alertCtrl.create({
                header: `All Set! 🎉`,
                message: `You've been successfully in the ${program.name}.`,
                buttons: [
                  {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                      this.scrollToBottom();
                    }
                  }
                ]}).then(alertEl => alertEl.present());
              });
          }}
        ]}).then(alertEl => alertEl.present());
  }

  /**
   * When proceeding to the third onboarding step, save step 2 data
   *
   * @param data Form Data
   */
  saveBackgroundInfoData(data = this.backgroundInfo.value) {

    data.goal = Number(data.goal);
    data.skill_level = Number(data.skill_level);

    if (!data.goal && !data.skill_level) {
      console.log('No goal and skill level provided');
      return;
    }

    // Update profile information
    this.user.update(this.userProfile, data).then(() => {
      console.log('Profile updated');
    });

  }

  /**
   * Calculate macros based on onboarding input
   */
  calculateMacros() {

    const goalSelected = this.macroInfo.value?.main_goal;
    if (!goalSelected) {
      console.warn('No goal selected');
      return;
    }

    console.log('weight', this.weight);
    console.log('height', this.height);
    console.log('gender', this.basicInfo.value.gender);
    console.log('demanding_profession', this.macroInfo.value.demanding_profession);
    console.log('multiplier', this.macroInfo.value.weight_loss_goal);

    switch (this.unit_of_measurement) {
      case 'imperial':
        this.weight = this.weight;
        this.height = this.height;
        break;
      case 'hybrid':
        this.weight = this.weight;
        this.height = this.height;
        break;
      case 'metric':
        this.weight = Number(Utils.toPounds(this.weight, false));
        this.height = Utils.toInches(this.height, true);
        break;
    }

    this.macrosService.calculateMacros(
      this.weight,
      this.height,
      this.basicInfo.value.gender,
      this.macroInfo.value.demanding_profession,
      this.macroInfo.value.weight_loss_goal,
      goalSelected
    ).then(macros => {
      this.macros = macros;

      // If we do not have training or rest macros, default to straight macro type
      if (!this.macros?.training && !this.macros?.rest) {
        this.macroPreference = 'straight';
      }

    });

  }

  /**
   * Scroll to bottom
   */
  scrollToBottom() {
    const trainingProgram = document.getElementById('training-program') as HTMLDivElement;
    trainingProgram.scrollTop = trainingProgram.scrollHeight;
  }

  /**
   * Set Macro Change Preference
   *
   * @param event Change event
   */
  macroPreferenceChanged(event) {
    this.macroPreference = event.target.value;
  }

  /**
   * Save Macros based on Straight or Cycling Preference and Continue to Next Slide
   */
  saveMacrosAndContinue() {

    const macrosToSave: MacroSet = {
      straight: null,
      training: null,
      rest: null
    };

    if (this.macroPreference === 'straight') {
      macrosToSave.straight = this.macros.straight;
    }
    if (this.macroPreference === 'cycling') {
      macrosToSave.training = this.macros.training;
      macrosToSave.rest = this.macros.rest;
    }

    this.macrosService.saveMacros(this.userProfile, macrosToSave, this.macroGoals).then(() => {
      this.savedMacros();
      this.nextSlide();
    });

  }

  /**
   * Macros Confirmation
   */
   async savedMacros() {
    const toast = await this.toastCtrl.create({
      message: 'Macros Saved',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Save Profile
   */
   async saved() {
    const toast = await this.toastCtrl.create({
      message: 'Profile updated',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Complete Onboarding, then dismiss modal
   */
  complete() {

    this.user.update(this.userProfile, {
      onboarding_complete: 1,
      onboarding_complete_date: moment().format('YYYY-MM-DD HH:mm:ss')
    }).then(() => {
      this.modalCtrl.dismiss();
      this.saved();
    });

  }

  /**
   * Present Tooltip Text
   *
   * @param event Click event used to keep the tooltip relative to element clicked
   * @param tooltip String to display as tooltip
   */
   async tooltip(event, tooltip: string) {
    const popover = await this.popoverController.create({
      component: TooltipPage,
      cssClass: 'amf-tooltip',
      translucent: false,
      event,
      mode: 'md',
      componentProps: {
        tooltip
      }
    });
    await popover.present();
  }

}
