// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Security
import { TokenInterceptor } from './../token.interceptor';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Network } from '@ionic-native/network/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
// import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';


// Service Worker
import { ServiceWorkerModule } from '@angular/service-worker';

// Env
import { environment } from '../environments/environment';

// Components
import { MaterialModule } from './material.module';
import { MenuUserModule } from './_components/menu-user/menu-user.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { MentionModule } from 'angular-mentions';
import { NotificationsComponent } from './_components/notifications/notifications.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [AppComponent,NotificationsComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    // Register the ServiceWorker as soon as the app is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
    }),
    MenuUserModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    IonicSelectableModule,
    MentionModule,
    PipesModule,
    HighchartsChartModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MaterialModule,
    MenuUserModule,
    Badge,
    Network,
    BackgroundMode,
    InAppPurchase2,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
