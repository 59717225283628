/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Interfaces
import Session from '../_interfaces/Session.interface';
import UserSessionList, { UserSession, UserSessionByID } from '../_interfaces/UserSession.interface';

// Services
import { ResponderService } from './responder.service';

// Environment
import Env from './../env';

// Utils
import Utils from '../utils';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private todaysSessionSource = new BehaviorSubject<any>('');
  todaysSession = this.todaysSessionSource.asObservable();

  // Observable used to track user session data through views
  private userSessionSource = new BehaviorSubject<any>(null);
  userSession = this.userSessionSource.asObservable();

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  selectedSession(selectedSession: Session) {
    this.todaysSessionSource.next(selectedSession);
  }

  /**
   * Finder User Session by Session ID
   *
   * @param session_id Session's ID
   * @returns promise
   */
  async findUserSessionByID(session_id: number, exerciseBeginObservable: boolean = true) {
    return new Promise((resolve, reject) => {

      if (!session_id) {
        reject('No session_id provided');
      }

      this.http.get(`${Env.api()}/users/sessions/${session_id}`).subscribe((res: UserSessionByID) => {
        if (res) {
          if (res.data) {
            if (exerciseBeginObservable) {
              this.userSessionSource.next(res.data);
            }
            resolve(res.data);
          } else {
            resolve(false);
          }
        }
      }, err => {
        this.responder.error(err,
          'Error Finding User Sessions',
          'There was an error finding user sessions. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch User Sessions by User ID
   *
   * @param user_id User's ID
   * @returns promise
   */
  async findUserSessionsByUserID(user_id: number) {
    return new Promise<UserSession[]>((resolve, reject) => {
      // console.log('findUserSessionsByUserID');
      if (!user_id) {
        reject('No user_id provided');
        return;
      }

      this.http.get(`${Env.api()}/users/sessions/find/${user_id}`).subscribe((res: UserSessionList) => {
        if (res.data.user_programs_sessions.length) {

          let ssns = res.data.user_programs_sessions;

          // Filter sessions without a start
          ssns = ssns.filter(ssn => ssn.started);

          // Sort sessions in descending order
          ssns = ssns.sort((a, b) => (moment(a.started).unix() > moment(b.started).unix()) ? -1 : 1);

          resolve(ssns);
        }
      }, err => {
        this.responder.error(err,
          'Error Finding User Sessions',
          'There was an error finding user sessions. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch User Favorite Sessions by User ID
   *
   * @param user_id User's ID
   * @returns promise
   */
   async findUserFavoriteSessionsByUserID(user_id: number) {
    return new Promise<UserSession[]>((resolve, reject) => {
      // console.log('findUserSessionsByUserID');
      if (!user_id) {
        reject('No user_id provided');
        return;
      }

      this.http.get(`${Env.api()}/users/sessions/find/${user_id}`).subscribe((res: UserSessionList) => {
        if (res.data.user_programs_sessions.length) {

          let ssns = res.data.user_programs_sessions;

          // Filter sessions without a start
          ssns = ssns.filter(ssn => ssn.favorited === 1);

          // Sort sessions in descending order
          ssns = ssns.sort((a, b) => (moment(a.started).unix() > moment(b.started).unix()) ? -1 : 1);

          resolve(ssns);
        }
      }, err => {
        this.responder.error(err,
          'Error Finding User Sessions',
          'There was an error finding user sessions. Please try again or contact support.');
      });

    });
  }

  /**
   * Find sessions relative to a user and program
   *
   * @param user_id User's ID
   * @param user_to_program_id Program's ID
   * @param session_id Session's ID
   * @returns promise
   */
  async findUserSessions(user_id: number, user_to_program_id: number, session_id: number) {
    return new Promise((resolve, reject) => {
      console.log('findUserSessions');
      if (!user_id) {
        reject('No user_id provided');
      }

      if (!user_to_program_id) {
        reject('No user_to_program_id provided');
      }

      if (!session_id) {
        reject('No session_id provided');
      }

      this.http.get(`${Env.api()}/users/sessions/find`).subscribe((res: UserSessionList) => {
        if (res.data && res.data.user_programs_sessions) {
          const filtered = res.data.user_programs_sessions.filter(session =>
            (session.user_id === user_id) && (session.user_to_program_id === user_to_program_id) && (session.session_id === session_id)
          );
          if (filtered) {
            resolve(filtered);
          } else {
            resolve(false);
          }
        }

      }, err => {
        this.responder.error(err,
          'Error Finding User Sessions',
          'There was an error finding user sessions. Please try again or contact support.');
      });

    });
  }

  /**
   * When beginning a session, create the session
   *
   * @param user_id User's ID
   * @param user_to_program_id Program's ID
   * @param session_id Session's ID
   * @returns promise
   */
  async createUserSession(user_id: number, user_to_program_id: number, session_id: number) {
    return new Promise((resolve, reject) => {
      console.log('createUserSession');
      if (!user_id) {
        reject('No user_id provided');
      }

      if (!user_to_program_id) {
        reject('No user_to_program_id provided');
      }

      if (!session_id) {
        reject('No session_id provided');
      }

      const payload = {
        user_id,
        user_to_program_id,
        session_id
      };

      console.log('createUserSession', payload);

      this.http.post(`${Env.api()}/users/sessions/create`, payload).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Creating User Session',
          'There was an error creating the user to program session. Please try again or contact support.');
      });

    });
  }

  /**
   * Start Session
   *
   * @param session UserSession
   * @returns promise
   */
  async startSession(session: UserSession) {
    return new Promise((resolve, reject) => {

      if (!session) {
        reject('No session provided');
      }

      const payload = {
        user_id: session.user_id,
        user_to_program_id: session.user_to_program_id,
        session_id: session.session_id,
        started: moment().format('YYYY-MM-DD HH:mm:ss'),
        completed: session.completed
      };

      this.http.post(`${Env.api()}/users/sessions/update/${session.id}`, payload).subscribe((res: any) => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Completing User Session',
          'There was an error completing the user session. Please try again or contact support.');
      });

    });
  }

  /**
   * Complete a session
   *
   * @param session UserSession
   * @returns promise
   */
  async completeSession(session: UserSession) {
    return new Promise((resolve, reject) => {

      if (!session) {
        reject('No session provided');
      }

      const payload = {
        user_id: session.user_id,
        user_to_program_id: session.user_to_program_id,
        session_id: session.session_id,
        started: session.started,
        completed: moment().format('YYYY-MM-DD HH:mm:ss')
      };

      this.http.post(`${Env.api()}/users/sessions/update/${session.id}`, payload).subscribe((res: any) => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Completing User Session',
          'There was an error completing the user session. Please try again or contact support.');
      });

    });
  }

  /**
   * Delete a session
   *
   * @param session UserSession
   * @returns promise
   */
   async deleteSession(session: UserSession, deleted: number) {
    return new Promise((resolve, reject) => {

      if (!session) {
        reject('No session provided');
      }

      const payload = {
        user_id: session.user_id,
        user_to_program_id: session.user_to_program_id,
        session_id: session.session_id,
        started: session.started,
        completed: session.completed,
        deleted
      };

      this.http.post(`${Env.api()}/users/sessions/update/${session.id}`, payload).subscribe((res: any) => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Deleting User Session',
          'There was an error deleting the user session. Please try again or contact support.');
      });

    });

  }

  /**
   * To set or remove favorite session
   *
   * @param session UserSession
   * @returns promise
   */
   async updateFavoriteSession(session: UserSession, favorite: number) {
    return new Promise((resolve, reject) => {

      if (!session) {
        reject('No session provided');
      }

      const payload = {
        user_id: session.user_id,
        user_to_program_id: session.user_to_program_id,
        session_id: session.session_id,
        favorited: favorite,
      };

      this.http.post(`${Env.api()}/users/sessions/update/${session.id}`, payload).subscribe((res: any) => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Updating User Session',
          'There was an error updating the user session. Please try again or contact support.');
      });

    });
  }

}
