import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'timeNow'
})
export class TimeNowPipe implements PipeTransform {

  transform(value: any, format: any = 'M/D/YYYY', timezone?: any): any {
    if (value && value !== undefined) {
      const testDateUtc = value;
      const now = moment();
      if (timezone) {
        if(moment(now.format('YYYY-MM-DD')).diff(moment(testDateUtc).format('YYYY-MM-DD'))) {
          return moment(testDateUtc).tz(timezone).format(format);
        } else {
          const apply_tz = moment(testDateUtc).tz(timezone);
          return apply_tz.from(now);
        }
      } else {
        if(moment(now.format('YYYY-MM-DD')).diff(moment(testDateUtc).format('YYYY-MM-DD'))) {
          return moment(testDateUtc).local().format(format);
        } else {
          const apply_local = moment(testDateUtc).local();
          return apply_local.from(now);
        }
      }

    }
  }

}
