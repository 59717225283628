/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Interfaces
import { UserProfile } from '../_interfaces/UserData.interface';

// Services
import { ResponderService } from './responder.service';

// Env
import Env from 'src/app/env';
import { UpdateProgressImagePayload } from '../_interfaces/Uploads.interface';

@Injectable({
  providedIn: 'root',
})
export class UploadService {

  constructor(
    private httpClient: HttpClient,
    private responder: ResponderService
  ) {}

  /**
   * Upload Profile Image
   *
   * @param userProfile User Profile Object
   * @param formData File data
   * @returns promise
   */
  public uploadProfile(userProfile: UserProfile, formData) {
    console.log('formData', formData);
    return this.httpClient.post<any>(`${Env.api()}/users/upload/profile_image/${userProfile.id}`, formData);
  }

  /**
   * Upload Progress Image
   *
   * @param userProfile User Profile Object
   * @param formData File data
   * @returns promise
   */
  public uploadProgress(userProfile: UserProfile, formData) {
    console.log('formData', formData);
    return new Promise((resolve, reject) => {
      this.httpClient.post<any>(`${Env.api()}/users/upload/progress_image/${userProfile.id}`, formData).subscribe(data => {
        resolve(data);
      }, err => {
        this.responder.error(err,
          'Error Updating Measurements',
          'There was an error updating measurements. Please try again or contact support.');
      });
    });
  }

  /**
   * Update Upload Record
   *
   * @param userProfile User Profile Object
   * @param payload Payload data
   * @returns promise
   */
  public updateUpload(payload: UpdateProgressImagePayload) {
    return new Promise((resolve, reject) => {
      this.httpClient.post<any>(`${Env.api()}/users/update/progress_image/${payload.upload_id}`, payload).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Updating Upload',
          'There was an error updating the recent upload. Please try again or contact support.');
      });
    });
  }

}
