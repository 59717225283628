/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable arrow-body-style */

// Angular
import { Injectable } from '@angular/core';

@Injectable()
export default class Utils {

  static parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map((c: any) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(base64);
  }

  static checkJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    try {
      JSON.parse(base64);
    } catch (e) {
      return false;
    }
    return true;
  }

  static calculateAverage(arrayToRound, value) {
    let sum = 0;
    if (arrayToRound.length) {
      for (let i = 0; i < arrayToRound.length; i++) {
        if (value) {
          sum += Number(arrayToRound[i].value);
        } else {
          sum += Number(arrayToRound[i]);
        }
      }

      const average = Math.round(sum / arrayToRound.length);

      return average;
    } else {
      return 'Array not found or has no data.';
    }
  }

  static dateISO8601(date: string) {
    if (date) {
      let newDate: Date;
      newDate = new Date(date.replace(/-/g, '/'));
      return newDate;
    } else {
      return;
    }
  }

  static diff_years(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    const age = Math.abs(Math.round(diff / 365.25));
    if (isNaN(age)) {
      return '-';
    } else {
      return age;
    }
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static chatkitID(user_id, role_id) {
    return user_id + '_' + role_id;
  }

  static daysToWeeks(days: number, roundDown: boolean = true) {
    let weeks = days / 7;
    weeks = roundDown ? Math.floor(weeks) : weeks;
    return weeks;
  }

  /**
   * Convert pounds to kilograms
   *
   * @param pounds The weight in pounds.
   * @param round Determines whether or not to round the result. false by default.
   */
  static toKilograms(pounds: number, round: boolean = false) {
    let kilograms = pounds * 0.45359237;
    kilograms = round ? Math.round(kilograms) : kilograms;
    return kilograms;
  }

  /**
   * Convert kilograms to pounds
   *
   * @param kilograms The weight in kilograms.
   * @param round Determines whether or not to round the result. True by default.
   */
  static toPounds(kilograms: number, round: boolean = true) {
    let pounds = kilograms * 2.205;
    pounds = round ? Math.round(pounds) : pounds;
    return pounds;
  }

  /**
   * Convert inches to centimeters
   *
   * @param inches The number in inches.
   * @param round Determines whether or not to round the result. false by default.
   */
  static toCentimeters(inches: number, round: boolean = false) {
    let centimeters = inches * 2.54;
    centimeters = round ? Math.round(centimeters) : centimeters;
    return centimeters;
  }

  /**
   * Convert centimeters to inches
   *
   * @param centimeters The number in inches.
   * @param round Determines whether or not to round the result. True by default.
   */
  static toInches(centimeters: number, round: boolean = true) {
    let inches = centimeters / 2.54;
    inches = round ? Math.round(inches) : inches;
    return inches;
  }

  /**
   * Convert ounces to millileters
   *
   * @param ounces The number in ounces
   * @param round Determines whether or not to round the result. false by default.
   */
  static toMillileters(ounces: number, round: boolean = false) {
    let millileters = ounces * 29.574;
    millileters = round ? Math.round(millileters) : millileters;
    return millileters;
  }

  /**
   * Convert millileters to ounces
   *
   * @param millileters The number in millileters
   * @param round Determines whether or not to round the result. false by default.
   */
  static toOunces(millileters: number, round: boolean = false) {
    let ounces = millileters / 29.574;
    ounces = round ? Math.round(ounces) : ounces;
    return ounces;
  }

  /**
   * Filter Deleted Items from Array
   *
   * @param object Object
   * @returns Array without filtered items
   */
  static filterDeleted(object: any) {
    return object.filter(obj => !obj.deleted);
  }

  /**
   * Field Sorter. Useful for sorting arrays by multiple key values.
   * Specify sort order using array. Prepend minus to specify descending order.
   *
   * @param fields Array with multiple key value
   */
  static fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
      dir = -1;
      o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);


  /**
   * getDateWithTimezone. Useful for set date base on timezone from userData profile
   *
   * @param tz Timezone
   * @param hereDate original Date
   */
  static getDateWithTimezone(tz, hereDate) {
    hereDate = new Date(hereDate || Date.now());
    hereDate.setMilliseconds(0); // for nice rounding

    const
    hereOffsetHrs = hereDate.getTimezoneOffset() / 60 * -1,
    thereLocaleStr = hereDate.toLocaleString('en-US', {timeZone: tz}),
    thereDate = new Date(thereLocaleStr),
    diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
    thereOffsetHrs = hereOffsetHrs + diffHrs;

    // console.log(thereDate, 'UTC'+(thereOffsetHrs < 0 ? '' : '+')+thereOffsetHrs);
    // console.log(thereOffsetHrs);
    return thereDate;


}

/**
 * Group array by different key
 *
 * @param array Array
 * @param key okey
 */
static groupBy(array, key) {
  return array.reduce(
    (
      result, currentValue
    ) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
}

}
