/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Ionic
import { AlertController } from '@ionic/angular';

// Interfaces
import Program from '../_interfaces/Program.interface';
import UserProgramList, { CurrentUserProgram, UserProgram } from '../_interfaces/UserPrograms.interface';

// Services
import { ResponderService } from './responder.service';

// Environment
import Env from './../env';

// Utils
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserProgramsService {

  public userProgramSource = new BehaviorSubject<UserProgram>(null);
  userProgram = this.userProgramSource.asObservable();

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  /**
   * Fetch List of User Programs
   *
   * @param user_id User's id
   * @returns promise
   */
  async fetchUserProgramList(user_id: number) {
    return new Promise((resolve, reject) => {
      console.log('fetchUserProgramList');
      if (!user_id) {
        reject('No user_id provided');
      }

      this.http.get(`${Env.api()}/users/programs/find`).subscribe((response: UserProgramList) => {
        const filtered = response.data.user_programs.filter(userProgram => userProgram.user_id === user_id);
        resolve(filtered);
      }, err => {
        this.responder.error(err,
          'Error Fetching User Program List',
          'There was an error fetching the user program list. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch User's Current Program
   *
   * @param user_id User's id
   * @returns promise
   */
  async fetchUserCurrentProgram(user_id: number, page?: number, size?: number) {
    return new Promise<UserProgram>((resolve, reject) => {
      // console.log('fetchUserCurrentProgram');
      if (!user_id) {
        reject('No user_id provided');
      }

      // console.log('page',page);
      // console.log('size',size);

      let url;

      if(page && size) {
        url = `${Env.api()}/users/programs/user/${user_id}?page=${page}&size=${size}&orderBy=id&orderByType=desc`;
      } else if (size && !page) {
        url = `${Env.api()}/users/programs/user/${user_id}?size=${size}&orderBy=id&orderByType=desc`;
      } else if (page && !size) {
        url = `${Env.api()}/users/programs/user/${user_id}?page=${page}&orderBy=id&orderByType=desc`;
      } else {
        url = `${Env.api()}/users/programs/user/${user_id}`;
      }

      this.http.get(url).subscribe((response: CurrentUserProgram) => {
        resolve(response?.data);
      }, err => {
        console.warn('Error fetching current user program, or no user program selection exists');
        // this.responder.error(err,
        //   'Error Fetching Current User Program',
        //   'There was an error fetching the current user program. Please try again or contact support.');
      });

    });
  }

  /**
   * Create User to Program Record
   * This is useful when creating a user to program association without enrollment
   *
   * @param user_id User's id
   * @param program_id Program ID
   * @returns promise
   */
  async createUserProgram(user_id: number, program_id: number) {
    return new Promise<UserProgram>((resolve, reject) => {

      if (!user_id) {
        reject('No user_id provided');
        return;
      }

      if (!program_id) {
        reject('No program_id provided');
        return;
      }

      const payload = {
        user_id,
        program_id,
        start_date: moment().format('YYYY-MM-DD HH:mm:ss')
      };

      this.http.post(`${Env.api()}/users/programs/create`, payload).subscribe((res: {
        status: number;
        message: string;
        data: { id: number };
      }) => {

        this.http.post(`${Env.api()}/users/programs`, {
          key: 'id',
          value: res.data.id
        }).subscribe((res2: {
            status: number;
            message: string;
            data: { user_programs: UserProgram[] };
          }) => {
            resolve(res2.data.user_programs[0]);
        });

      }, err => {
        this.responder.error(err,
          'Error Creating User Program',
          'There was an error creating the user program record. Please try again or contact support.');
      });

    });
  }

  /**
   * Enroll a user in a new program
   *
   * @param program Program object
   * @param user_id User's ID number
   * @return promise
   */
  async enrollInProgram(program: Program, user_id: number) {
    return new Promise((resolve, reject) => {
      console.log('enrollInProgram');
      if (!program) {
        reject('No program provided');
        return;
      }

      if (!user_id) {
        reject('No user_id provided');
        return;
      }

      const payload = {
        user_id,
        program_id: program.id,
        start_date: moment().format('YYYY-MM-DD')
      };

      this.http.post(`${Env.api()}/users/programs/enroll`, payload).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Enrolling',
          'There was an error enrolling in this program. Please contact support.');
      });

    });
  }

  /**
   * Update the start date for a user's program
   *
   * @param userCurrentProgram User current program object
   * @param start_date Start date string
   * @returns promise
   */
  async updateProgramStartDate(userCurrentProgram: UserProgram, start_date: string) {
    return new Promise((resolve, reject) => {

      if (!userCurrentProgram) {
        reject('No userCurrentProgram provided');
        return;
      }

      if (!start_date) {
        reject('No start_date provided');
        return;
      }

      const payload = {
        current: 1,
        program_id: userCurrentProgram.program_id,
        start_date: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
        user_id: userCurrentProgram.user_id
      };

      console.log('payload', payload);

      this.http.post(`${Env.api()}/users/programs/update/${userCurrentProgram.id}`, payload).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Updating Start Date',
          'There was an error updating the program start date. Please contact support.');
      });

    });
  }

}
