/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController, ToastController, IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// Services
import { UserWorkoutService } from 'src/app/_services/user-workout.service';
import { UserExerciseService } from 'src/app/_services/user-exercise.service';
import { UserSessionService } from 'src/app/_services/user-session.service';

// Pages
import { TooltipPage } from '../tooltip/tooltip.page';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import Program from 'src/app/_interfaces/Program.interface';
import { UserWorkout, UserWorkoutExercise, UserWorkoutRecord } from 'src/app/_interfaces/UserWorkout.interface';
import { UserSession } from 'src/app/_interfaces/UserSession.interface';
import { ExerciseSub } from 'src/app/_interfaces/ExerciseSubs.interface';
import Utils from 'src/app/utils';

@Component({
  selector: 'app-exercise-edit',
  templateUrl: './exercise-edit.page.html',
  styleUrls: ['./exercise-edit.page.scss'],
})
export class ExerciseEditPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;

  @Input() userProfile: UserProfile;
  @Input() userSession: UserSession;
  @Input() workout: UserWorkout;
  @Input() exerciseID: number;
  @Input() superset: boolean;
  @Input() setN: number;
  @Input() editOneSetOnly: boolean;
  @Input() userWorkoutExercise: any;
  @Input() typeEdit: string;

  userWorkouts: UserWorkoutRecord[];
  exercisesList: any = [];
  exercises: any = [];
  previousWorkouts: UserWorkoutRecord[];

  form: FormGroup;
  isSubmitted = false;

  currentSet: UserWorkoutRecord;
  currentSetNumber: number;

  upNext: UserWorkoutExercise;

  updated: boolean;

  previousSetString: string;
  previousSet: UserWorkoutRecord;

  unit_of_measurement: string;

  user_workout_exercise_id: number;

  workout_exercises: UserWorkoutExercise[];

  exerciseSubSelection: ExerciseSub;

  exerciseSub: any;

  formEdit: FormGroup = this.fb.group({
    reps: '',
    weight: '',
    weight_kg: '',
    time: '',
    notes: '',
  });

  constructor(
    public modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private userWorkoutService: UserWorkoutService,
    private userExerciseService: UserExerciseService,
    private toastCtrl: ToastController,
    private userSessionService: UserSessionService,
    public popoverController: PopoverController
  ) {

    this.form = this.fb.group({});

    this.form?.valueChanges.subscribe((data) => {
       console.log('input data', data);
    });

    this.userSessionService.userSession.subscribe(data => {
      if (data) {
        this.userSession = data;
        // console.log('UserSession after:', data);
      }
    });
    console.log('UserSession after:', this.userSession);

   }

  ngOnInit() {

    // Assign unit of measurement, default to imperial
    this.unit_of_measurement = (this.userProfile.unit_of_measurement) ? this.userProfile.unit_of_measurement : 'imperial';

    if(!this.userWorkoutExercise) {

      this.userSession.user_session_workouts.forEach((ssn) => {

        ssn.workout_exercises.forEach(workout => {
          // console.log('Workout looping', workout);
          this.exercisesList.push({
            id: workout.id,
            exercise_id: workout.exercise_id,
            exercise_name: workout.exercise_name,
            exercise_substituted: workout.exercise_substituted,
            type: workout.exercise_type_id,
            notes: workout.notes,
            reps: workout.reps,
            user_id: workout.user_id,
            user_session_workout_id: workout.user_session_workout_id,
            weight: workout.weight,
            time: workout.time,
            position: workout.position,
            workout_to_exercise_id: workout.workout_to_exercise_id,
            set: ssn.set_n,
            workout_id: ssn.workout_id
          });

        });

      });

      console.log('This workout exercises', this.exercisesList);
      console.log('Workout input', this.workout);
      console.log('Superset input', this.superset);
      console.log('ExerciseID', this.exerciseID);

      // Prepare form controls
      if (this.exercisesList?.length) {

        if (!this.superset) {

          // If not superset, filter by specific exercise and prepare video
          this.exercises = this.exercisesList.filter(ex => ex.exercise_id === this.exerciseID).filter(ex => ex.set === this.setN);

        } else {

          // If superset, filter by specific workout and prepare videos
          this.exercises = this.exercisesList.filter(ex => ex.workout_id === this.workout.id).filter(ex => ex.set === this.setN);

        }

        this.exercises.forEach((ex) => {
          this.addExerciseFormControls(ex);
          setTimeout(() => {
            this.patchExerciseFormControlsWithExistingValues(ex);
          }, 450);
        });

        this.currentSetNumber = this.setN;

      }

    }

  }

  /**
   * Based on the exercise type, prepare the relevant form controls.
   * Form controls are named with an exercise ID to write the appropriate
   * database records.
   *
   * @param exercise Exercise object
   */
   addExerciseFormControls(exercise: any) {
      switch (exercise.type) {
      case 14: // Weight
        this.form.addControl(`reps_${exercise.id}`, new FormControl(''));
        if(this.unit_of_measurement === 'metric') {
          this.form.addControl(`weight_kg_${exercise.id}`, new FormControl(''));
        } else {
          this.form.addControl(`weight_${exercise.id}`, new FormControl(''));
        }
        break;
      case 15: // Body weight
        this.form.addControl(`reps_${exercise.id}`, new FormControl(''));
        break;
      case 16: // Cardio
        this.form.addControl(`time_${exercise.id}`, new FormControl(''));
        if(this.unit_of_measurement === 'metric') {
          this.form.addControl(`weight_kg_${exercise.id}`, new FormControl(''));
        } else {
          this.form.addControl(`weight_${exercise.id}`, new FormControl(''));
        }
        break;
      }
      this.form.addControl(`notes_${exercise.id}`, new FormControl(''));
      this.form.addControl(`position_${exercise.id}`, new FormControl(''));

  }

  /**
   * After completing a set, patch the previous set data
   *
   * @param exercise Exercise object
   */
   patchExerciseFormControlsWithExistingValues(exercise) {

      switch (exercise.type) {
        case 14: // Weight
          if (exercise.reps) {
            this.form.patchValue({ [`reps_${exercise.id}`]: exercise.reps }, {emitEvent: false});
          }
          if (exercise.weight) {
            if(this.unit_of_measurement === 'metric') {
              this.form.patchValue({ [`weight_kg_${exercise.id}`]: Utils.toKilograms(exercise.weight, false).toFixed(1) }, {emitEvent: false});
            } else {
              this.form.patchValue({ [`weight_${exercise.id}`]: exercise.weight }, {emitEvent: false});
            }


          }
          break;
        case 15: // Body weight
          if (exercise.reps) {
            this.form.patchValue({
              [`reps_${exercise.id}`]: exercise.reps,
            }, {emitEvent: false});
          }
          break;
        case 16: // Cardio
          if (exercise.reps) {
            this.form.patchValue({
              [`time_${exercise.id}`]: exercise.reps,
            }, {emitEvent: false});
          }
          if (exercise.weight) {
            if(this.unit_of_measurement === 'metric') {
              this.form.patchValue({
                [`weight_kg_${exercise.id}`]: Utils.toKilograms(exercise.weight, false).toFixed(1)
              }, {emitEvent: false});
            } else {
              this.form.patchValue({
                [`weight_${exercise.id}`]: exercise.weight
              }, {emitEvent: false});
            }
          }
          break;
      }
      if (exercise.notes) {
        this.form.patchValue({
          [`notes_${exercise.id}`]: exercise.notes
        }, {emitEvent: false});
      }
      if (exercise.position) {
        this.form.patchValue({
          [`position_${exercise.id}`]: exercise.position
        });
      }


  }

  ionViewDidEnter() {

    if(!this.userWorkoutExercise) {
      // Fetch previous workout data
      if (this.userProfile?.id && this.workout?.id) {
        this.userWorkoutService.previousWorkout({
          user_id: this.userProfile.id,
          workout_id: this.workout.id
        }).then(previousWorkouts => {
          this.previousWorkouts = previousWorkouts;
        });
      }
    } else {
      console.log(this.userWorkoutExercise);
      if(this.userWorkoutExercise.reps) {
        this.formEdit.patchValue({ reps: this.userWorkoutExercise.reps });
      }
      if(this.userWorkoutExercise?.weight) {
        if(this.unit_of_measurement === 'metric') {
          this.formEdit.patchValue({ weight_kg: Utils.toKilograms(this.userWorkoutExercise.weight, false).toFixed(1) });
        } else {
          this.formEdit.patchValue({ weight: this.userWorkoutExercise.weight });
        }
      }
      if(this.userWorkoutExercise?.notes) {
        this.formEdit.patchValue({ notes: this.userWorkoutExercise.notes });
      }
    }

  }


  /**
   * Update Exercise Data
   *
   * @param event Event data
   */
  updateUserExercise(event) {
    // console.log(event);
  }

  /**
   * Edit exercise record. Records are created when completing the set.
   *
   * @param exercise Exercise object
   * @returns promise
   */
   async updateExerciseToWorkout(exercise: UserWorkoutExercise) {
    return new Promise((resolve) => {

      console.log('updateExerciseToWorkout ', exercise);

      const payload = {
        user_id: this.userProfile.id,
        user_session_workout_id: 0,
        workout_to_exercise_id: 0,
        exercise_id: 0,
        position: 0,
        weight: 0,
        reps: 0,
        time: 0,
        notes: '',
        exercise_substituted: (exercise?.sub) ? 1 : 0
      };

      console.log('Payload ', payload);

      // Loop through fom controls
      for (const [key, value] of Object.entries(this.form.value)) {

        // Extract key and exercise id respectively, then assign value to payload
        const keySplit = key.split('_');

        console.log(keySplit,key,value);

        if(this.unit_of_measurement === 'metric' && key === `weight_kg_${exercise.id}`) {
          if (Number(keySplit[2]) === exercise.id) {
            payload[keySplit[0]] = Utils.toPounds(Number(value),false);
          }
        } else {
          if (Number(keySplit[1]) === exercise.id) {
            payload[keySplit[0]] = value;
          }
        }


      }

      // Assign final values for payload
      payload.user_session_workout_id = exercise.user_session_workout_id;
      payload.workout_to_exercise_id = exercise.workout_to_exercise_id;
      payload.exercise_id = exercise.exercise_id;
      payload.position = exercise.position;

      // console.log('Ex', exercise);

      console.log('payload updateExerciseToWorkout', payload);

      // console.log('Exercises', this.exercises);

      // console.log(payload.workout_to_exercise_id);

      // Send payload via service to the API
      this.userExerciseService.editExerciseToWorkout(payload,exercise.id);

      resolve(true);
    });
  }

  /**
   * For each exercise, save the input data
   *
   * @returns promise
   */
   async saveExerciseData() {
    return new Promise((resolve) => {

      const totalExercises = this.exercises.length;
      let i = 1;

      // Foreach exercise, save the input data
      this.exercises.forEach(e => {
        this.updateExerciseToWorkout(e).then((resolved) => {
          if (resolved && i < totalExercises) {
            i++;
          }
          if (resolved && i === totalExercises) {
            resolve(true);
          }
        });
      });

    });
  }

  /**
   * Scroll to top
   */
   scrollToTop() {
    this.content.scrollToTop(750);
  }

  /**
   * Complete set, and increment to the next
   */
  saveSet() {

    this.saveExerciseData().then(resolved => {

      // If we have saved all exercise data, proceed to completing the set
      if (resolved) {

        const nextSetNumber = this.currentSetNumber + 1;

        // If we have completed all sets, show toast, close modal
        if (nextSetNumber > this.workout.sets || this.editOneSetOnly) {

          this.updated = true;
          this.setUpdatedToast();
          setTimeout(() => {
            this.modalCtrl.dismiss();
          }, 1000);

        } else {

          // If we haven't completed all sets, rerun logic to continue
          this.setUpdatedToast();

          if (!this.superset) {

            // If not superset, filter by specific exercise and prepare video
            this.exercises = this.exercisesList.filter(ex => ex.exercise_id === this.exerciseID).filter(ex => ex.set === nextSetNumber);

          } else {

            // If superset, filter by specific workout and prepare videos
            this.exercises = this.exercisesList.filter(ex => ex.workout_id === this.workout.id).filter(ex => ex.set === nextSetNumber);

          }

          this.exercises.forEach((ex) => {
            this.addExerciseFormControls(ex);
            setTimeout(() => {
              this.patchExerciseFormControlsWithExistingValues(ex);
            }, 450);
          });

          this.currentSetNumber = nextSetNumber;

        }

        this.scrollToTop();
        this.form.reset();


      }

    });

  }

  /**
   * Get method for form control array
   */
   get errorControl() {
    return this.form.controls;
  }

  /**
   * Increment or Decrement field value
   *
   * @param key Form control key
   * @param value Value to increment by
   */
  //  updateFieldNumber(key, value) {
  //   const newValue = Number(this.form.controls[key].value) + Number(value);
  //   if (newValue < 0) { return; }
  //   this.form.controls[key].patchValue(newValue);
  // }


  /**
   * Check if this current set is updated
   */
   checkUpdated(close?: boolean) {
    if (this.currentSetNumber > this.workout.sets || !this.currentSet) {
      this.updated = true;
      if (close) {
        setTimeout(() => {
          this.modalCtrl.dismiss();
        }, 850);
      }
    }
    if (this.currentSetNumber <= this.workout.sets) {
      this.updated = false;
    }
  }

  /**
   * Set Updated Toast
   */
   async setUpdatedToast() {
    const toast = await this.toastCtrl.create({
      message: 'Set Updated!',
      duration: 2000,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  toKg(pound: any) {

    const pound_input = pound;

    let kg: any;

    kg = Number(Utils.toKilograms(pound_input, false)).toFixed(1);

    return kg;

  }

  /**
   * Edit exercise record. Records are created when completing the set.
   *
   * @param exercise Exercise object
   * @returns promise
   */
   async updateUserExerciseToWorkout() {
    return new Promise((resolve) => {

      const payload = {
        user_id: this.userProfile.id,
        user_session_workout_id: this.userWorkoutExercise.userSessionWorkoutId,
        workout_to_exercise_id: this.userWorkoutExercise.workoutToExerciseId,
        exercise_id: this.userWorkoutExercise.exerciseId,
        position: this.userWorkoutExercise.position,
        weight: this.formEdit.value.weight,
        reps: this.formEdit.value.reps,
        time: this.userWorkoutExercise.time,
        notes: this.formEdit.value.notes,
        exercise_substituted: this.userWorkoutExercise.exerciseSubstituted
      };

      if(this.unit_of_measurement === 'metric') {
        payload.weight = Utils.toPounds(Number(this.formEdit.value.weight_kg),false);
      }

      console.log('payload updateUserExerciseToWorkout', payload);

      // Send payload via service to the API
      this.userExerciseService.editExerciseToWorkout(payload,this.userWorkoutExercise.id);

      resolve(true);
    });
  }

  updateSet() {

    this.updateUserExerciseToWorkout().then(resolved => {

      // If we have saved all exercise data, proceed to completing the set
      if (resolved) {

        this.setUpdatedToast();
        setTimeout(() => {
          this.modalCtrl.dismiss();
        }, 1000);

      }

    });

  }

}
