import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { AuthGuard } from './app/_guards/auth.guard';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    jwt: any;

    constructor(private storage: Storage, private auth: AuthGuard) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.auth.jwt) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.jwt}`
                }
            });
            return next.handle(request);
        } else {
            request = request.clone();
            return next.handle(request);
        }

    }

}
