/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Interfaces
import Program, { FindProgramByGoalAndSkillResponse, ProgramCreateResponse, ProgramGoalPayload, ProgramList, ProgramSingle, ProgramSkillPayload } from '../_interfaces/Program.interface';
import Session from '../_interfaces/Session.interface';

// Services
import { ResponderService } from './responder.service';

// Env
import Env from './../env';

// Utils
import Utils from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  /**
   * Fetch by ID
   *
   * @param program_id Program's ID
   * @returns promise
   */
  async fetchByID(program_id: number) {
    return new Promise<Program>((resolve, reject) => {

      if (!program_id) {
        reject('No program_id provided');
      }
      this.http.get(`${Env.api()}/programs/${program_id}`).subscribe((response: ProgramSingle) => {

        this.filterAndSort_SessionsWorkoutsAndExercises(response.data.sessions).then(result => {
          response.data.sessions = result;
        });

        resolve(response.data);
      }, err => {
        this.responder.error(err,
          'Error Fetching Program',
          'There was an error fetching program. Please try again or contact support.');
      });


    });
  }

  /**
   * Fetch List
   *
   * @returns promise
   */
  async fetchList(page?: number, size?: number) {
    return new Promise<Program[]>((resolve, reject) => {

      // console.log('page',page);
      // console.log('size',size);

      let url;

      if(page && size) {
        url = `${Env.api()}/programs?page=${page}&size=${size}`;
      } else if (size && !page) {
        url = `${Env.api()}/programs?size=${size}`;
      } else if (page && !size) {
        url = `${Env.api()}/programs?page=${page}`;
      } else {
        url = `${Env.api()}/programs`;
      }

      // console.log(url);

      this.http.get(url).subscribe((response: ProgramList) => {
        if (response.data.programs) {

          // Filter Deleted Programs
          response.data.programs = Utils.filterDeleted(response.data.programs);

          response.data.programs.forEach(program => {

            this.filterAndSort_SessionsWorkoutsAndExercises(program.sessions).then(result => {
              program.sessions = result;
            });

          });

          resolve(response.data.programs);
        }
      }, err => {
        this.responder.error(err,
          'Error Fetching Program List',
          'There was an error fetching the program list. Please try again or contact support.');
      });


    });
  }

  /**
   * Fetch Whole List, including soft-delete ones
   *
   * @returns promise
   */
   async fetchWholeList() {
    return new Promise<Program[]>((resolve, reject) => {

      this.http.get(`${Env.api()}/programs?show_archived=true`).subscribe((response: ProgramList) => {
        if (response.data.programs) {

          // Filter Deleted Programs
          response.data.programs.forEach(program => {

            this.filterAndSort_SessionsWorkoutsAndExercises(program.sessions).then(result => {
              program.sessions = result;
            });

          });

          resolve(response.data.programs);
        }
      }, err => {
        this.responder.error(err,
          'Error Fetching Program List',
          'There was an error fetching the program list. Please try again or contact support.');
      });


    });
  }

  /**
   * Create
   *
   * @param program Program form values
   * @returns promise
   */
  async create(program: Program, goals?: number[], skills?: number[]) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/create`, program).subscribe((res: ProgramCreateResponse) => {
        if (res.data.id) {
          this.processGoalsAndSkills(res.data.id, goals, skills).then(() => {
            resolve(true);
          });
        }
      }, err => {
        this.responder.error(err,
          'Error Creating Program',
          'There was an error creating the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Update
   *
   * @param programID ID of Program to Update
   * @param program Program form values
   * @returns promise
   */
  async update(programID: number, program: Program, goals?: number[], skills?: number[]) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/update/${programID}`, program).subscribe(() => {
        this.processGoalsAndSkills(programID, goals, skills).then(() => {
          resolve(true);
        });
      }, err => {
        this.responder.error(err,
          'Error Updating Program',
          'There was an error updating the program. Please try again or contact support.');
      });

    });
  }


  /**
   * Process Goals and Skills for a Program
   *
   * @param programID Program ID
   * @param goals Goal ID Array
   * @param skills Skill ID Array
   */
  async processGoalsAndSkills(programID: number, goals: number[], skills: number[]) {

      let goalLoopCount = 0;
      const goalPayload = [];

      let skillLoopCount = 0;
      const skillPayload = [];

      // For each goal, save record
      if (goals?.length) {
        goals.forEach((g, i) => {
          goalPayload.push({ program_id: programID, goal_id: g });
          goalLoopCount++;
          if (goals.length === goalLoopCount) {
            this.addProgramGoals(programID, goalPayload);
          }
        });
      }

      // For reach skill, save record
      if (skills?.length) {
        skills.forEach((s, i) => {
          skillPayload.push({ program_id: programID, skill_id: s });
          skillLoopCount++;
          if (skills.length === skillLoopCount) {
            this.addProgramSkills(programID, skillPayload);
          }
        });
      }

  }

  /**
   * Soft Delete
   *
   * @param program Program object
   * @returns promise
   */
  async archive(program: Program) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/archive/${program.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Archiving Program',
          'There was an error archiving the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Restored Program
   *
   * @param program Program object
   * @returns promise
   */
  async unarchive(program: Program) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/unarchive/${program.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Unarchiving Program',
          'There was an error unarchiving the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Soft Delete
   *
   * @param program Program object
   * @returns promise
   */
  async delete(program: Program) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/delete/${program.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Deleting Program',
          'There was an error deleting the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Restored Program
   *
   * @param program Program object
   * @returns promise
   */
   async restore(program: Program) {
    return new Promise((resolve, reject) => {

      if (!program) {
        reject('No program provided');
      }

      this.http.post(`${Env.api()}/programs/restore/${program.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Restoring Program',
          'There was an error restoring the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Filter Deleted Items from Sessions, Workouts, and Exercises
   * While also sorting then by specific key values
   *
   * @param Session Array
   * @returns promise
   */
  async filterAndSort_SessionsWorkoutsAndExercises(sessions: Session[]) {
    return new Promise<Session[]>((resolve) => {

        if (!sessions) {
          console.warn('No sessions to filter and sort');
        }

        // Filter deleted sessions
        sessions = Utils.filterDeleted(sessions);

        // Loop through sessions
        sessions.forEach(session => {

            // For sorting, we'll add weeks from and weeks to
            session.order_sum = Number(session.weeks_from) + Number(session.weeks_to);

            // Filter Deleted Workouts
            session.workouts = Utils.filterDeleted(session.workouts);

            // Sort Workouts by Position ASC
            session.workouts = session.workouts.sort(Utils.fieldSorter(['position']));

            // Loop through workouts
            session.workouts.forEach(workout => {

              // Filter Deleted Exercises
              workout.exercises = Utils.filterDeleted(workout.exercises);

              // Sort Exercises by Position ASC
              workout.exercises = workout.exercises.sort(Utils.fieldSorter(['position']));

            });

        });

        // Sort Sessions by Order Sum, Day and Name ASC
        sessions = sessions.sort(Utils.fieldSorter(['order_sum', 'day', 'name']));

        resolve(sessions);

    });
  }

  /**
   * Add Program Goals
   *
   * @param programAndGoal Program ID and Goal ID payload
   * @returns promise
   */
  async addProgramGoals(programID: number, programAndGoal: ProgramGoalPayload[]) {
    return new Promise((resolve, reject) => {

      if (!programAndGoal) {
        reject('No program and goal payload provided');
      }

      this.http.post(`${Env.api()}/programs/goals/add/${programID}`, programAndGoal).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Adding Goals to Program',
          'There was an error adding goals to the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Add Program Skills
   *
   * @param programAndSkill Program ID and Skill ID payload
   * @returns promise
   */
   async addProgramSkills(programID: number, programAndSkill: ProgramSkillPayload[]) {
    return new Promise((resolve, reject) => {

      if (!programAndSkill) {
        reject('No program and skill payload provided');
      }

      this.http.post(`${Env.api()}/programs/skills/add/${programID}`, programAndSkill).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Adding Skills to Program',
          'There was an error adding skills to the program. Please try again or contact support.');
      });

    });
  }

  /**
   * Find Program by Goal and Skill
   *
   * @param goal_id Goal ID number
   * @param skill_id Skill ID number
   * @returns promise
   */
  async findProgramByGoalAndSkill(goal_id: number, skill_id: number) {
    return new Promise<Program[]>((resolve, reject) => {

      if (!goal_id) {
        reject('No goal_id provided');
        return;
      }
      if (!skill_id) {
        reject('No skill_id provided');
        return;
      }

      const payload = { goal_id, skill_id };

      this.http.post(`${Env.api()}/programs`, payload).subscribe((res: FindProgramByGoalAndSkillResponse) => {

        const filterDeleted: Program[] = Utils.filterDeleted(res.data.programs);
        filterDeleted.forEach(program => {
          this.filterAndSort_SessionsWorkoutsAndExercises(program.sessions).then(result => {
            program.sessions = result;
          });
        });

        resolve(filterDeleted);
      }, err => {
        this.responder.error(err,
          'Error Finding Recommended Program',
          'There was an error finding recommended programs. Please try again or contact support.');
      });

    });
  }

}
