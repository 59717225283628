// Angular
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Observable } from 'rxjs';

// Ionic
import { NavParams, ModalController, LoadingController, AlertController } from '@ionic/angular';

// Services
import { UserService } from '../../_services/user.service';
import { ProgressService } from 'src/app/_services/progress.service';

// Interfaces
import { UserProfile } from '../../_interfaces/UserData.interface';
import { ProgressImage } from 'src/app/_interfaces/Uploads.interface';

// Utils
import { ImageCroppedEvent } from 'ngx-image-cropper';
import heic2any from 'heic2any';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {

  userProfile: UserProfile;

  @ViewChild('slider', { read: ElementRef, static: false }) slider: ElementRef;

  img: any;
  cropMode = false;
  base64Image = null;
  croppedImage = null;
  canvasRotation = 0;
  fileType = '';
  compressedImage: any;
  imageLoad: any;

  sliderOpts = {
    zoom: {
      maxRatio: 3,
    },
  };

  public photoDate: string;

  constructor(
    private user: UserService,
    private navParams: NavParams,
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private progressService: ProgressService
  ) {
    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnInit() {
    this.img = this.navParams.get('img');
    this.cropMode = this.navParams.get('cropMode');
    this.fileType = this.navParams.get('fileType');
    this.imageToBase64();
    // console.log('image', this.img);
    console.log(this.userProfile?.timezone);
    this.photoDate = moment.tz(this.userProfile?.timezone).format('YYYY-MM-DD');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    if (this.imageLoad) {
      this.imageLoad.dismiss();
    }
  }

  showLoader() {
    this.loadingCtrl
      .create({ keyboardClose: true, message: 'Uploading Image...' })
      .then((loadingEl) => {
        if (!this.imageLoad) {
          this.imageLoad = loadingEl;
          this.imageLoad.present();
        }
      });
  }

  imageToBase64() {
    if (this.fileType === 'heic') {
      this.showLoader();
      this.heicConverter(this.img).subscribe((blob) => {
        // convert to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.base64Image = reader.result;
        };
      });
    } else {
      this.convertFileToDataURLviaFileReader(this.img).subscribe((base64) => {
        this.base64Image = base64;
      });
    }
  }

  convertFileToDataURLviaFileReader(url: string) {
    return new Observable((observer) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onload = () => {
        const reader: FileReader = new FileReader();
        reader.onloadend = () => {
          observer.next(reader.result);
          observer.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  heicConverter(url: string): Observable<Blob> {
    return new Observable((observer) => {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.5,
          })
        )
        .then((conversionResult) => {
          observer.next(conversionResult as Blob);
          observer.complete();
        });
    });
  }

  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  save() {
    this.showLoader();
    // console.log(this.photoDate);
    this.modalController.dismiss({
      dismissvalue: this.croppedImage,
      photoDate: this.photoDate
    });
  }

  delete(img: ProgressImage) {

      // Are you sure?
      this.alertCtrl.create({
        header: `Are you sure you want to delete this photo?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              // ...
            }
          },
          {
            text: 'Delete',
            handler: () => {
              console.log('delete image', img);
              this.progressService.deleteProgressImage(img).then(() => {
                this.alertCtrl.create({
                  header: `Image Deleted!`,
                  buttons: [
                    {
                      text: 'Dismiss',
                      role: 'cancel',
                      handler: () => {
                        console.log('Dismissed');
                        setTimeout(() => {
                          this.modalController.dismiss();
                        }, 450);
                      }
                    }
                  ]}).then(alertEl => alertEl.present());
            });
          }}
        ]}).then(alertEl => alertEl.present());

  }

  close() {
    this.modalController.dismiss({
      dismissvalue: null,
      photoDate: null
    });
  }

}
