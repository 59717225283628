/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController, ToastController, IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NativeAudio } from '@capgo/native-audio';

// Services
import { UserWorkoutService } from 'src/app/_services/user-workout.service';
import { UserExerciseService } from 'src/app/_services/user-exercise.service';
import { UserSessionService } from 'src/app/_services/user-session.service';
import { TimerService } from 'src/app/_services/timer.service';

// Pages
import { TooltipPage } from '../tooltip/tooltip.page';
import { TimerPage } from '../timer/timer.page';
import { ExerciseEditPage } from '../exercise-edit/exercise-edit.page';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import Program from 'src/app/_interfaces/Program.interface';
import { UserWorkout, UserWorkoutRecord, UserExerciseRecordHistory } from 'src/app/_interfaces/UserWorkout.interface';
import UserWorkoutExercise, { UserExercise } from 'src/app/_interfaces/UserExercise.interface';
import { UserSession } from 'src/app/_interfaces/UserSession.interface';
import { ExerciseDetail } from 'src/app/_interfaces/ExerciseDetail.interface';
import Utils from 'src/app/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-exercise-begin',
  templateUrl: './exercise-begin.page.html',
  styleUrls: ['./exercise-begin.page.scss'],
})
export class ExerciseBeginPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;

  @Input() userProfile: UserProfile;
  @Input() userCurrentProgramDetails: Program;
  @Input() userSession: UserSession;
  @Input() workout: UserWorkout;
  @Input() exerciseID: number;
  @Input() superset: boolean;

  userWorkouts: UserWorkoutRecord[];
  exercises: UserWorkoutExercise[];
  previousWorkouts: UserExerciseRecordHistory[];

  form: FormGroup;
  isSubmitted = false;

  currentSet: UserWorkoutRecord;
  currentSetNumber: number;

  upNext: UserWorkoutExercise;

  completed: boolean;

  previousSetString: string;
  previousSet: UserWorkoutRecord;

  restTimeLimit = 0;
  closingTimer: any;
  openRestTimer: boolean;
  running: boolean = false;
  timerMinimize: boolean = false;
  soundOn: boolean = true;
  loading: boolean = false;
  timeout: any;

  unit_of_measurement: string;

  todaySessionData: UserWorkoutRecord[];
  sessionSummary: ExerciseDetail[] = [];

  historyWorkouts: UserExerciseRecordHistory[];
  previousSummary: UserExerciseRecordHistory[] = [];

  duplicateExercisesCount: number;

  constructor(
    public modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private userWorkoutService: UserWorkoutService,
    private userExerciseService: UserExerciseService,
    private toastCtrl: ToastController,
    private userSessionService: UserSessionService,
    public popoverController: PopoverController,
    public timer: TimerService,
    ) {

    // this.setRingtone();

    this.form = this.fb.group({});

    this.form.valueChanges.subscribe((data) => {
       console.log('input data', data);
    });

    this.userSessionService.userSession.subscribe(data => {
      if (data) {
        this.userSession = data;
        this.todaySessionData = data.user_session_workouts;
        // console.log('User Session: ',this.userSession);
        // console.log('Today Session Data: ', this.todaySessionData);
      }
    });


    if(this.timer.getCountdown()) {
      this.closingTimer = this.timer.getCountdown();
    } else {
      this.timer?.sharedTimer?.subscribe((timer: any) => {
        this.timer.setCountdown(timer);
        this.closingTimer = timer;
      });
    }

  }

  ngOnInit() {

    // Assign unit of measurement, default to imperial
    this.unit_of_measurement = (this.userProfile.unit_of_measurement) ? this.userProfile.unit_of_measurement : 'imperial';

    // Start clean session summary
    // this.sessionSummary = [];

    if (this.userProfile?.id && this.exerciseID && !this.superset) {
      this.userWorkoutService.previousWorkouthHistory(this.userProfile?.id,this.exerciseID).then(previousWorkouts => {
        // console.log(previousWorkouts);
        this.previousWorkouts = previousWorkouts;
        this.historyWorkouts = previousWorkouts;
        // console.log('History: ', this.historyWorkouts);
        this.preparePreviousSessionSummary();
      });
    }

    if (!this.superset) {

      // If not superset, filter by specific exercise and prepare video
      this.exercises = this.workout.exercises.filter(ex => ex.exercise_id === this.exerciseID);
      // console.log(this.exercises);

    } else {

      // If superset, filter by specific workout and prepare videos
      this.exercises = this.workout.exercises.filter(ex => ex.workout_id === this.workout.id);
      // console.log(this.exercises);

        // Check if we have duplicate exercise, then populate the count
        const checkduplicate = [...this.exercises.reduce( (mp, o) => {
          if (!mp.has(o.exercise_id)) mp.set(o.exercise_id, { ...o, count: 0 });
          mp.get(o.exercise_id).count++;
          return mp;
          }, new Map).values()];

          // console.log(checkduplicate);

        if(checkduplicate.length === 1) {

          this.duplicateExercisesCount = checkduplicate[0].count;

        }

    }

    // Prepare form controls and videos
    if (this.exercises?.length) {

      this.exercises.forEach((ex) => {
        this.addExerciseFormControls(ex);
        this.fetchVimeoVideo(ex.exercise.video_link).then(url => {
            ex.exercise.video_link = url;
        });

        if (this.superset) {

          this.userWorkoutService.previousWorkouthHistory(this.userProfile?.id,ex.exercise_id).then(previousWorkouts => {
            // console.log(previousWorkouts);
            this.previousWorkouts = previousWorkouts;
            this.historyWorkouts = previousWorkouts;
            // console.log('History: ', this.historyWorkouts);
            // console.log(ex);
            this.preparePreviousSessionSummary(ex.id);
          });

        }

      });

    }

    this.timer.sharedTimer.subscribe((timer: any) => {
      // console.log('subscribe time:',timer);
      if(timer?.timeLeft === 0 && this.timerMinimize === true &&  this.soundOn) {
        this.running = false;
        this.playSingle(); // play the sound only when finish not on close
        this.timerMinimize = false;
      }

    });

  }

  /**
   * Based on the exercise type, prepare the relevant form controls.
   * Form controls are named with an exercise ID to write the appropriate
   * database records.
   *
   * @param exercise Exercise object
   */
  addExerciseFormControls(exercise: UserWorkoutExercise) {

      switch (exercise.exercise.type) {
        case 14: // Weight
          this.form.addControl(`reps_${exercise.id}`, new FormControl(''));
          if(this.unit_of_measurement === 'metric') {
            this.form.addControl(`weight_kg_${exercise.id}`, new FormControl(''));
          } else {
            this.form.addControl(`weight_${exercise.id}`, new FormControl(''));
          }
          break;
        case 15: // Body weight
          this.form.addControl(`reps_${exercise.id}`, new FormControl(''));
          break;
        case 16: // Cardio
          this.form.addControl(`time_${exercise.id}`, new FormControl(''));
          if(this.unit_of_measurement === 'metric') {
            this.form.addControl(`weight_kg_${exercise.id}`, new FormControl(''));
          } else {
            this.form.addControl(`weight_${exercise.id}`, new FormControl(''));
          }
          break;
        }
        this.form.addControl(`notes_${exercise.id}`, new FormControl(''));
        this.form.addControl(`position_${exercise.id}`, new FormControl(''));
        this.form.patchValue({ [`position_${exercise.id}`]: exercise.position });

  }

  /**
   * After completing a set, patch the previous set data
   *
   * @param exercise Exercise object
   */
  patchExerciseFormControlsWithPreviousValues(exercise, orderSuperset?: number) {
    if (this.previousSummary?.length) {

        if(this.duplicateExercisesCount) {
          switch (exercise.exercise.type) {
            case 14: // Weight
              if (this.patchPreviousValue(exercise, 'reps', orderSuperset)) {
                this.form.patchValue({ [`reps_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps', orderSuperset) });
              }
              if (this.patchPreviousValue(exercise, 'weight', orderSuperset)) {
                if(this.unit_of_measurement === 'metric') {
                  this.form.patchValue({ [`weight_kg_${exercise.id}`]: Utils.toKilograms(this.patchPreviousValue(exercise, 'weight', orderSuperset), false).toFixed(1) });
                } else {
                  this.form.patchValue({ [`weight_${exercise.id}`]: this.patchPreviousValue(exercise, 'weight', orderSuperset) });
                }


              }
              break;
            case 15: // Body weight
              if (this.patchPreviousValue(exercise, 'reps'), orderSuperset) {
                this.form.patchValue({
                  [`reps_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps', orderSuperset),
                });
              }
              break;
            case 16: // Cardio
              if (this.patchPreviousValue(exercise, 'reps', orderSuperset)) {
                this.form.patchValue({
                  [`time_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps', orderSuperset),
                });
              }
              if (this.patchPreviousValue(exercise, 'weight', orderSuperset)) {
                if(this.unit_of_measurement === 'metric') {
                  this.form.patchValue({
                    [`weight_kg_${exercise.id}`]: Utils.toKilograms(this.patchPreviousValue(exercise, 'weight', orderSuperset), false).toFixed(1)
                  });
                } else {
                  this.form.patchValue({
                    [`weight_${exercise.id}`]: this.patchPreviousValue(exercise, 'weight', orderSuperset)
                  });
                }
              }
              break;
          }
          if (this.patchPreviousValue(exercise, 'notes', orderSuperset)) {
            this.form.patchValue({
              [`notes_${exercise.id}`]: this.patchPreviousValue(exercise, 'notes', orderSuperset)
            });
          }
        } else {
          switch (exercise.exercise.type) {
            case 14: // Weight
              if (this.patchPreviousValue(exercise, 'reps')) {
                this.form.patchValue({ [`reps_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps') });
              }
              if (this.patchPreviousValue(exercise, 'weight')) {
                if(this.unit_of_measurement === 'metric') {
                  this.form.patchValue({ [`weight_kg_${exercise.id}`]: Utils.toKilograms(this.patchPreviousValue(exercise, 'weight'), false).toFixed(1) });
                } else {
                  this.form.patchValue({ [`weight_${exercise.id}`]: this.patchPreviousValue(exercise, 'weight') });
                }


              }
              break;
            case 15: // Body weight
              if (this.patchPreviousValue(exercise, 'reps')) {
                this.form.patchValue({
                  [`reps_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps'),
                });
              }
              break;
            case 16: // Cardio
              if (this.patchPreviousValue(exercise, 'reps')) {
                this.form.patchValue({
                  [`time_${exercise.id}`]: this.patchPreviousValue(exercise, 'reps'),
                });
              }
              if (this.patchPreviousValue(exercise, 'weight')) {
                if(this.unit_of_measurement === 'metric') {
                  this.form.patchValue({
                    [`weight_kg_${exercise.id}`]: Utils.toKilograms(this.patchPreviousValue(exercise, 'weight'), false).toFixed(1)
                  });
                } else {
                  this.form.patchValue({
                    [`weight_${exercise.id}`]: this.patchPreviousValue(exercise, 'weight')
                  });
                }
              }
              break;
          }
          if (this.patchPreviousValue(exercise, 'notes')) {
            this.form.patchValue({
              [`notes_${exercise.id}`]: this.patchPreviousValue(exercise, 'notes')
            });
          }
        }

    }
  }

  ionViewDidEnter(refresh?: boolean) {
    this.findUserWorkout(null);

    this.userSessionService.findUserSessionByID(this.userSession.id).then((res: UserSession) => {
      this.prepareTodaySessionSummary(true);
    });

  }

  /**
   * Find the user's workout progress. If no records are found, create the first one.
   */
  async findUserWorkout(nextSetNumber?: any) {
    return new Promise((resolve) => {

      this.userWorkoutService.findUserWorkout(this.userProfile.id, this.userSession.id, this.workout.id, this.exerciseID, nextSetNumber,1,3).then((res: UserWorkoutRecord[]) => {

        // If workout record exists, determine set
        if (res && res.length) {

          // We determine the current set by filtering ones that do not yet have a date and time completed
          this.userWorkouts = res;
          this.currentSet = this.userWorkouts.filter((workout) => workout.datetime_completed === null)[0];
          this.restTimeLimit = this.workout.time;

          // If we have incompleted sets
          if (this.currentSet) {
            this.currentSetNumber = this.currentSet.set_n;
            this.findPreviousSet(this.currentSet);
          }

          // Check if this current set is completed
          this.checkCompleted((nextSetNumber) ? true : false);

          resolve(true);

          setTimeout(() => {
            this.exercises.forEach((ex, index) => {
              if(this.duplicateExercisesCount) {
                this.patchExerciseFormControlsWithPreviousValues(ex,index);
              } else {
                this.patchExerciseFormControlsWithPreviousValues(ex);
              }
            });
          }, 450);

        // If no workout record exists, create one, then rerun findUserWorkout
        // to retrieve record and prepare view.
        } else {

          const setNumber = (nextSetNumber) ? nextSetNumber : 1;

          this.userWorkoutService.createUserWorkout(this.userProfile.id, this.userSession.id, this.workout.id, setNumber).then(() => {
            this.findUserWorkout();
          });

        }

      });
    });
  }

  /**
   * After completing a set, we'll filter and fetch the previous set's information
   */
  findPreviousSet(currentSet: UserWorkoutRecord) {

    if (this.currentSet.set_n > 1) {

      const previous_set_n = this.currentSet.set_n - 1;
      const user_session_workouts = this.userSession.user_session_workouts.filter(
        usw => usw.user_program_session_id === this.currentSet.user_program_session_id && usw.workout.id === this.currentSet.workout.id
      );

      this.previousSet = user_session_workouts.filter(usw => usw.set_n === previous_set_n)[0];

      this.exercises.forEach(ex => {

        // Find previous set
        const previousSet = this.previousSet.workout_exercises.filter(we => we.workout_to_exercise_id === ex.id)[0];
        ex.previous = previousSet;

      });

    }


  }

  /**
   * Find the next exercise in the list
   * TODO: Finish this up
   *
   * @param exercise Current exercise
   */
  async findNextExercise(exercise: UserWorkoutExercise) {
    const currentExerciseIndex = this.workout.exercises.findIndex((x) => x.id === exercise.id);
    const nextExercise = this.workout.exercises[currentExerciseIndex + 1];
    if (nextExercise && nextExercise.id) {
      this.upNext = nextExercise;
    }
  }

  /**
   * Update Exercise Data
   *
   * @param event Event data
   */
  updateUserExercise(event) {
    // console.log(event);
  }

  /**
   * Create exercise record. Records are created when completing the set.
   *
   * @param exercise Exercise object
   * @returns promise
   */
  async createExerciseToWorkout(exercise: UserWorkoutExercise) {
    return new Promise((resolve) => {

      // console.log('createExerciseToWorkout', exercise);

      const payload = {
        user_id: this.userProfile.id,
        user_session_workout_id: 0,
        workout_to_exercise_id: 0,
        exercise_id: 0,
        position: 0,
        weight: 0,
        reps: 0,
        time: 0,
        notes: '',
        last_modified: '',
        exercise_substituted: (exercise?.sub) ? 1 : 0
      };

      // console.log('Payload ', payload);

      // Loop through fom controls
      for (const [key, value] of Object.entries(this.form.value)) {

        // Extract key and exercise id respectively, then assign value to payload
        const keySplit = key.split('_');

        // console.log(keySplit,key,value);

        if(this.unit_of_measurement === 'metric' && key === `weight_kg_${exercise.id}`) {
          if (Number(keySplit[2]) === exercise.id) {
            payload[keySplit[0]] = Utils.toPounds(Number(value),false);
          }
        } else {
          if (Number(keySplit[1]) === exercise.id) {
            payload[keySplit[0]] = value;
          }
        }

      }

      // Assign final values for payload
      payload.user_session_workout_id = this.currentSet.id;
      payload.workout_to_exercise_id = exercise.id;
      payload.exercise_id = exercise.exercise.id;
      payload.position = exercise.position;
      payload.last_modified = moment().format('YYYY-MM-DD hh:mm:ss');
      // console.log('payload createExerciseToWorkout', payload);

      // Send payload via service to the API
      this.userExerciseService.createExerciseToWorkout(payload);

      resolve(true);
    });
  }

  /**
   * For each exercise, save the input data
   *
   * @returns promise
   */
  async saveExerciseData() {
    return new Promise((resolve) => {

      const totalExercises = this.exercises.length;
      let i = 1;

      // Foreach exercise, save the input data
      this.exercises.forEach(e => {
        this.createExerciseToWorkout(e).then((resolved) => {
          if (resolved && i < totalExercises) {
            i++;
          }
          if (resolved && i === totalExercises) {
            resolve(true);
          }
        });
      });

    });
  }

  /**
   * Scroll to top
   */
  scrollToTop() {
    this.content.scrollToTop(750);
  }

  /**
   * Complete set, and increment to the next
   */
  completeSet() {
    this.loading = true;

    this.saveExerciseData().then(resolved => {

      // If we have saved all exercise data, proceed to completing the set
      if (resolved) {

        this.userWorkoutService.completeUserWorkoutSet(this.userProfile.id, this.currentSet, this.currentSet.user_program_session_id, this.currentSet.workout_id, this.currentSet.set_n).then((res) => {

          const nextSetNumber = this.currentSetNumber + 1;

          // If we have completed all sets, show toast, close modal
          if (nextSetNumber > this.workout.sets) {

            this.completed = true;
            this.setCompletedToast();
            setTimeout(() => {
              this.modalCtrl.dismiss();
            }, 1000);

          } else {

            this.userSessionService.findUserSessionByID(this.userSession.id);

            // If we haven't completed all sets, rerun logic to continue
            this.findUserWorkout(nextSetNumber).then(() => {

              this.setCompletedToast();

              this.exercises.forEach((ex) => {
                this.addExerciseFormControls(ex);
                this.fetchVimeoVideo(ex.exercise.video_link).then(url => {
                    ex.exercise.video_link = url;
                });
              });

            });

          }

          this.scrollToTop();
          this.form.reset();
        }).finally(() => this.loading = false);

      }

    });

  }

  /**
   * Get method for form control array
   */
   get errorControl() {
    return this.form.controls;
  }

  /**
   * Vimeo URL
   *
   * @param video_link Form Vimeo Link
   */
   fetchVimeoVideo(video_link: any) {
     return new Promise((resolve) => {
      // console.log('video_link', video_link);
      if (video_link?.changingThisBreaksApplicationSecurity) {
        resolve(this.sanitizer.bypassSecurityTrustResourceUrl(video_link?.changingThisBreaksApplicationSecurity));
      }
      const videoData = video_link.split('/');
      if (videoData[3] && videoData[4]) {
        const account = videoData[3];
        const video = videoData[4];
        const url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${account}?h=${video}&badge=0&autopause=0&player_id=0&byline=0&loop=1&title=false&playsinline=1&portrait=0&sharing=0&app_id=58479`);
        resolve(url);
      } else {
        resolve(false);
      }
     });
  }

  /**
   * Increment or Decrement field value
   *
   * @param key Form control key
   * @param value Value to increment by
   */
  // updateFieldNumber(key, value) {
  //   const newValue = Number(this.form.controls[key].value) + Number(value);
  //   if (newValue < 0) { return; }
  //   this.form.controls[key].patchValue(newValue);
  // }

  /**
   * Check if this current set is completed
   */
  checkCompleted(close?: boolean) {
    if (this.currentSetNumber > this.workout.sets || !this.currentSet) {
      this.completed = true;
      if (close) {
        setTimeout(() => {
          this.modalCtrl.dismiss();
        }, 850);
      }
    }
    if (this.currentSetNumber <= this.workout.sets) {
      this.completed = false;
      this.prepareTodaySessionSummary(true);
    }
  }

  /**
   * Set Completed Toast
   */
   async setCompletedToast() {
    const toast = await this.toastCtrl.create({
      message: 'Set Completed! Excellent work!',
      duration: 2000,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Open Timer
   */
  async openTimer(autoStart: boolean = false, restTimeLimit: number = this.restTimeLimit) {
    // console.log('this.restTimeLimit', this.restTimeLimit);

    this.openRestTimer = true;

    const modal = await this.modalCtrl.create({
      component: TimerPage,
      cssClass: 'anyman-modal dark half timer',
      componentProps: {
        userProfile: this.userProfile,
        userCurrentProgramDetails: this.userCurrentProgramDetails,
        userSession: this.userSession,
        mode: 'timer',
        limit: restTimeLimit,
        autoStart,
        closingTimer: this.closingTimer
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.closingTimer = dataReturned.data;
        // this.timer.nextTimer(dataReturned.data);
        this.running = this.closingTimer?.running;
        this.openRestTimer = false;
        this.timerMinimize = true;
        // console.log(this.closingTimer);
        this.countDown(false);
        //alert('Modal Sent Data :'+ dataReturned);
      } else {
        this.countDown(true);
        this.timerMinimize = false;
        this.running = false;
      }
    });

    return await modal.present();
  }

  /**
   * Present Tooltip Text
   *
   * @param event Click event used to keep the tooltip relative to element clicked
   * @param tooltip String to display as tooltip
   */
   async tooltip(event, tooltip: string) {
    const popover = await this.popoverController.create({
      component: TooltipPage,
      cssClass: 'amf-tooltip',
      translucent: false,
      event,
      mode: 'md',
      componentProps: {
        tooltip
      }
    });
    await popover.present();
  }

  patchPreviousValue(exercise: UserWorkoutExercise, field: string, orderSuperset?: number) {
    let previousValue: any = null;
    if (this.previousSummary?.length) {

      const previousWorkoutExercise = this.previousSummary?.filter(pwe => pwe?.exerciseId === exercise?.exercise_id && pwe?.set_n === this.currentSetNumber);

      // console.log(previousWorkoutExercise);

      if (previousWorkoutExercise?.length) {

        if(this.duplicateExercisesCount) {
          switch (field) {
            case 'reps':
              previousValue = previousWorkoutExercise[orderSuperset]?.reps;
              break;
            case 'weight':
              previousValue = previousWorkoutExercise[orderSuperset]?.weight;
              break;
            case 'time':
              previousValue = previousWorkoutExercise[orderSuperset]?.time;
              break;
            case 'notes':
              previousValue = previousWorkoutExercise[orderSuperset]?.notes;
              break;
          }
        } else {
          switch (field) {
            case 'reps':
              previousValue = previousWorkoutExercise[0]?.reps;
              break;
            case 'weight':
              previousValue = previousWorkoutExercise[0]?.weight;
              break;
            case 'time':
              previousValue = previousWorkoutExercise[0]?.time;
              break;
            case 'notes':
              previousValue = previousWorkoutExercise[0]?.notes;
              break;
          }
        }


      }

      // console.log('previousWorkoutExercise', previousWorkoutExercise);

    }
    return previousValue;
  }

  /**
   * Previous Set Tooltip Text
   *
   * @param event Click event used to keep the tooltip relative to element clicked
   * @param tooltip String to display as tooltip
   */
    async previousTooltip(event, exercise: UserWorkoutExercise, field: string, orderSuperset?: number) {

      const previousWorkoutExercise = this.previousSummary?.filter(pwe => pwe?.exerciseId === exercise?.exercise_id && pwe?.set_n === this.currentSetNumber);

      if (!previousWorkoutExercise.length) {
        return;
      }

      let tooltip;

      if(orderSuperset) {
        switch (field) {
          case 'reps':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you completed ${previousWorkoutExercise[orderSuperset].reps} reps.`;
            break;
          case 'weight':
            if(this.unit_of_measurement === 'metric') {
              tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you moved ${Utils.toKilograms(previousWorkoutExercise[orderSuperset].weight, false).toFixed(1)} kg.`;
            } else {
              tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you moved ${previousWorkoutExercise[orderSuperset].weight} lbs.`;
            }
            break;
          case 'time':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you completed the set in ${previousWorkoutExercise[orderSuperset].time} seconds.`;
            break;
          case 'notes':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you left the note: "${previousWorkoutExercise[orderSuperset].notes}"`;
            break;
        }
      } else {
        switch (field) {
          case 'reps':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you completed ${previousWorkoutExercise[0].reps} reps.`;
            break;
          case 'weight':
            if(this.unit_of_measurement === 'metric') {
              tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you moved ${Utils.toKilograms(previousWorkoutExercise[0].weight, false).toFixed(1)} kg.`;
            } else {
              tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you moved ${previousWorkoutExercise[0].weight} lbs.`;
            }
            break;
          case 'time':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you completed the set in ${previousWorkoutExercise[0].time} seconds.`;
            break;
          case 'notes':
            tooltip = `Last time you did this exercise at set number ${this.currentSetNumber}, you left the note: "${previousWorkoutExercise[0].notes}"`;
            break;
        }
      }



      const popover = await this.popoverController.create({
        component: TooltipPage,
        cssClass: 'amf-tooltip',
        translucent: false,
        event,
        mode: 'md',
        componentProps: {
          tooltip
        }
      });
      await popover.present();

    }

  toKg (pound: any) {

    let pound_input = pound;

    let kg: any;

    kg = Number(Utils.toKilograms(pound_input, false)).toFixed(1);

    return kg;

  }

  /**
   * Build today summary based on the user session data available
   *
   * @returns promise
   */
   async prepareTodaySessionSummary(refresh?: boolean) {

    return new Promise((resolve) => {

      if(refresh) {
        this.sessionSummary = [];
      }

      this.todaySessionData.forEach(workout => {
        const exists = this.sessionSummary.filter(ssn => ssn.id === workout.workout.id);
        // console.log('Wrk', workout);
        // console.log('Wrk.Ex', workout.workout_exercises);
        if (!exists.length) {
          const superset = (workout?.workout?.exercises.length > 1) ? true : false;
          this.sessionSummary.push({
            id: workout.workout.id,
            name: workout.workout.name,
            sets: [],
            exercises: [],
            workout_data: workout?.workout,
            exercise_id: workout?.workout_exercises[0]?.exercise_id,
            superset
          });
        }
      });

      this.sessionSummary.forEach(ssn => {
        this.todaySessionData.forEach(workout => {
          // console.log('Workout: ',workout);
            if (workout.workout_id === ssn.id) {
              workout.workout_exercises = workout.workout_exercises.sort(Utils.fieldSorter(['position']));
              workout.workout_exercises.forEach((wx, index) => {
                if (!ssn.superset) {
                  ssn.sets.push({
                    set: workout.set_n,
                    weight: wx.weight,
                    reps: wx.reps,
                    time: wx.time,
                    notes: wx.notes,
                    name: wx.exercise_name,
                    sub: wx.exercise_substituted
                  });
                } else {
                  ssn.exercises.push({
                    exerciseName: wx.exercise_name,
                    exercise_id: wx.exercise_id,
                    set: workout.set_n,
                    weight: wx.weight,
                    reps: wx.reps,
                    time: wx.time,
                    notes: wx.notes,
                    sub: wx.exercise_substituted,
                    orderSuperset: index+1
                  });
                }
              });
            }
          });
      });



      if (!this.superset) {

        // If not superset, filter by specific exercise
        this.sessionSummary = this.sessionSummary.filter((ssn) => ssn.exercise_id === this.exerciseID);

      } else {

        // console.log('Before filtering', this.sessionSummary);

        this.sessionSummary = this.sessionSummary.filter((ssn) => ssn.id === this.workout.id);

        if(this.sessionSummary[0]?.exercises?.length === 0) {
          this.sessionSummary = [];
        }

      }

      // console.log('ex ID:',this.exerciseID)

      // console.log('sessionSummary', this.sessionSummary);
      // console.log('Today sessionData', this.todaySessionData);

      resolve(true);

    });

  }

  /**
   * Edit Exercise Modal
   *
   * @returns modal instance
   */
   async editWorkout(workout?: UserWorkout, exerciseID?: number, setN?: number, editOneSetOnly?: boolean) {

    // console.log('editWorkout workout', workout);
    // console.log('editWorkout exerciseID', exerciseID);

    const modal = await this.modalCtrl.create({
      component: ExerciseEditPage,
      cssClass: 'anyman-modal dark half',
      componentProps: {
        userProfile: this.userProfile,
        userSession: this.userSession,
        workout,
        exerciseID,
        setN,
        editOneSetOnly,
        superset: (workout.exercises.length > 1) ? true : false
      }
    });

    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        this.ionViewDidEnter(true);
      });
    });

  }

  /**
   * Build previous summary based on the user session data available
   *
   * @returns promise
   */
   async preparePreviousSessionSummary(ex?: number) {

    return new Promise((resolve) => {

      // if(this.superset) {
      //   this.historyWorkouts = this.historyWorkouts.filter(pws => pws.workoutToExerciseId === ex);
      // }

      const sets = this.workout?.sets;
      const setsArray = Array.from({length: sets}, (_, index) => index + 1);

      if(sets > 0) {

        if(this.duplicateExercisesCount) {

          const exerciseArray = Array.from({length: this.duplicateExercisesCount}, (_, index) => index + 1);

          exerciseArray.forEach(ex => {

            setsArray.forEach(set_n => {

              // Get set using exercise index (ex. first two of set_n 1)
              const set = this.historyWorkouts?.filter(ex => ex.set_n === set_n)[ex];

              // console.log(setsGroup);

              // Populate previousSummary array with only sets between 1 and workout.sets
              if(set && set.set_n >= set.set_n && set.set_n <= sets) {
                set.orderSuperset = ex;
                this.previousSummary.push(set);
              }

            });

          });

          // Remove duplicates
          this.previousSummary = this.previousSummary.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i);

        } else {

          setsArray.forEach(set_n => {
            let set = this.historyWorkouts?.find(ex => ex.set_n === set_n);
            if(set && set.set_n >= set.set_n && set.set_n <= sets) {
              this.previousSummary.push(set);
            }
          });

        }

      }

      // console.log('Previous Summary', this.previousSummary);

      resolve(true);

    });

  }

  countDown(refresh:boolean) {
    if(refresh) {
      clearInterval(this.timeout);
      const closingData = {
        timeLeft: 0,
        running: false
      }
      this.timer.setCountdown(closingData);
    } else {
      if(this.closingTimer) {
        // console.log('this.restTimer:', this.closingTimer);

        if(this.closingTimer?.running) {

          this.timeout = setInterval(() => {
            if (this.closingTimer?.timeLeft > 0) {
              this.closingTimer.timeLeft -= 1;
              const closingData = {
                timeLeft: this.closingTimer.timeLeft,
                running: true
              }
              this.closingTimer = closingData;
              // console.log(this.closingTimer);
            } else {
              clearInterval(this.timeout);
              const closingData = {
                timeLeft: 0,
                running: false
              };
              this.timer.nextTimer(closingData);
            }
          }, 1000);

          // console.log('this.timeLeft:', this.timeout);

        }
      }
    }

  }


  // Play the audio track
  playSingle() {
    NativeAudio.play({
      assetId: 'toastSound',
      time: 0
    });
  }

}
