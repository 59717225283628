/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Interfaces
import { UserWorkoutExercise } from '../_interfaces/UserWorkout.interface';
import { WorkoutProgress } from '../_interfaces/Progress.interface';

// Services
import { ResponderService } from './responder.service';

// Env
import Env from './../env';
import { ProgressImage } from '../_interfaces/Uploads.interface';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  progressImagePath = Env.uploads() + '/progress';

  constructor(
    private http: HttpClient,
    private responder: ResponderService,
    private userSessionService: UserSessionService
  ) { }

  /**
   * Fetch Total Completed Exercises
   *
   * @returns promise
   */
  async totalCompletedExercises(user_id: number) {
    return new Promise<WorkoutProgress>((resolve, reject) => {

      this.http.get(`${Env.api()}/users/workouts/exercises/find/${user_id}`).subscribe((res: {
        status: number;
        message: string;
        data: { user_workout_exercises: UserWorkoutExercise[] };
       }) => {
        if (res && res.data && res.data.user_workout_exercises) {
          this.userSessionService.findUserSessionsByUserID(user_id).then(ssns => {
            const sessions_completed = ssns.filter(ssn => ssn.completed !== null && ssn.completed !== '')?.length;
            const response = {
              totalSessions: (sessions_completed > 0) ? (sessions_completed) : 0,
              totalExercises: res.data.user_workout_exercises.length,
              totalReps: res.data.user_workout_exercises.reduce((total, obj) => obj.reps + total, 0),
              totalWeight: res.data.user_workout_exercises.reduce((total, obj) => obj.weight + total, 0)
            };
            resolve(response);
          });
        }
      }, err => {
        this.responder.error(err,
          'Error Fetching Total Completed Exercises',
          'There was an error fetching total completed exercises. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch Progress Image
   *
   * @param user_id User's ID
   * @returns promise
   */
  async fetchProgressImage(user_id: number) {
    return new Promise<ProgressImage[]>((resolve, reject) => {
      this.http.get(`${Env.api()}/users/progress_images/${user_id}`).subscribe((res: {
        status: number;
        message: string;
        data: { progress_images: ProgressImage[] };
       }) => {
        if (res && res.data && res.data.progress_images) {
          res.data.progress_images.forEach(pimage => {
            pimage.isToday = false;
            pimage.full_path = this.progressImagePath + '/' + pimage.file_name;
          });
          resolve(res.data.progress_images);
        }
      }, err => {
        this.responder.error(err,
          'Error Fetching Progress Images',
          'There was an error fetching progress images. Please try again or contact support.');
      });
    });
  }

  /**
   * Delete Progress Image
   *
   * @param image Progress Image Object
   * @returns promise
   */
  async deleteProgressImage(image: ProgressImage) {
    return new Promise((resolve, reject) => {
      this.http.get(`${Env.api()}/users/delete/progress_image/${image.id}`).subscribe((res: any) => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Fetching Progress Images',
          'There was an error fetching progress images. Please try again or contact support.');
      });
    });
  }

}
