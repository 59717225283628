// Angular
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

// Services
import { UserService } from 'src/app/_services/user.service';
import { WoocommerceService } from 'src/app/_services/woocommerce.service';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import { ModalController } from '@ionic/angular';

// Env
import Env from './../../env';


const PROD_LIFETIME = '18';
const PROD_YEARLY = '17';
const PROD_MONTHLY = '13';

@Component({
  selector: 'app-renew',
  templateUrl: './renew.page.html',
  styleUrls: ['./renew.page.scss'],
})
export class RenewPage implements OnInit {

  userProfile: UserProfile;

  platformData: any;
  ios: any;

  // Focus
  hasFocus: any;
  hasFocusTimeout: any;

  products: IAPProduct[] = [];

  activationCompleted: boolean;
  refreshed: boolean;

  isLifetimeActive: boolean;

  constructor(
    public user: UserService,
    public platform: Platform,
    public deviceService: DeviceDetectorService,
    private store: InAppPurchase2,
    private ref: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private woocommerce: WoocommerceService,
  ) {

    this.activationCompleted = false;
    this.refreshed = false;
    this.isLifetimeActive = false;

    this.platformData = JSON.stringify(this.deviceService.getDeviceInfo());

    const platformData = this.platform.platforms();
    this.ios = (platformData.filter(p => p === 'ios').length > 0) ? true : false;

    platform.ready().then(() => {

      if(this.ios) {

        // this.store.verbosity = this.store.DEBUG;
        // this.registerProducts();
        this.setupListeners();

        // Get the real product information
        this.store?.ready(() => {

          console.log('this.products before: ',this.store?.products);

          if(this.store?.products?.find(p => p.id === '18' && p.owned)) {
            this.isLifetimeActive = true;
            this.products = this.store?.products?.filter(p => p.id === '18');
          } else {
            this.isLifetimeActive = false;
            this.products = this.store?.products?.filter(p => p.id !== 'com.anymanfitness.app');
          }

          console.log('this.isLifetimeActive: ',this.isLifetimeActive);
          console.log('this.products: ',this.products);
          this.ref.detectChanges();

        });



      }

     });

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;

      // If the user has `app_access` set to `1`, we'll close the modal
      if (this.userProfile?.id && this.userProfile.app_access === 1) {
        if(!this.ios) {
          this.modalCtrl.dismiss();
        } else {
          this.activationCompleted = true;
        }
      }

    });

  }

  async ngOnInit() {

    // Check for application focus
    this.checkFocus();

    // Application focus event listeners
    window.addEventListener('focus', () => {
        this.checkFocus();
    });
    window.addEventListener('blur', () => {
        this.checkFocus();
    });
    window.addEventListener('pageshow', () => {
        this.checkFocus();
    }, false);
    window.addEventListener('pagehide', () => {
        this.checkFocus();
    }, false);

  }

  /**
   * If we have focus of the app, check for updates
   */
  checkFocus() {
    clearTimeout(this.hasFocusTimeout);
    this.hasFocusTimeout = setTimeout(() => {
        this.hasFocus = document.hasFocus();
        if (this.hasFocus === true) {

          // If this screen has focus, request a profile update
          // We make this additional request on focus if the user has returned from an eCommerce purchase
          // App access evaluation logic is in the observable subscription in the constructor
          this.user.fetchProfile(this.userProfile.id);

        }
    }, 250);
  }

  myAccount() {
    window.open(`${Env.eCommerce()}/my-account`, '_blank');
  }


  purchase(product: IAPProduct) {
    this.store?.order(product).then(p => {
      // Purchase in progress!
    }, e => {
      this.presentAlert('Failed', `Failed to purchase: ${e}`);
    });
  }

  // To comply with AppStore rules
  restore() {
    this.refreshed = true;
    this.store?.refresh();
  }

  async presentAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

  setupListeners() {
    this.store?.when('product')
    .approved((p: IAPProduct) => {
      if(p.id === PROD_LIFETIME) {
        if (this.userProfile) {
          this.user.updateAppAccess(this.userProfile, 1).then(() => {
            console.log('Update App Access Enables!');
          });
          this.user.updateUser(this.userProfile, 'subscription_type', PROD_LIFETIME).then(() => {
            console.log('Update Subscription Type!');
            this.user.fetchProfile(this.userProfile.id);
          });
        }
      } else {
        if (this.userProfile && p.id !== 'com.anymanfitness.app') {
          this.user.updateAppAccess(this.userProfile, 1).then(() => {
            console.log('Update App Access Enables!');
          });
          this.user.updateUser(this.userProfile, 'subscription_type', p.id).then(() => {
            console.log('Update Subscription Type!');
            this.user.fetchProfile(this.userProfile.id);
          });
        }
      }
      p.verify();
      if(p.id !== 'com.anymanfitness.app' && !this.refreshed && !this.isLifetimeActive) {
        const order = {
          user_id : this.userProfile.wp_user_id,
          product_id: parseInt(p.id)
        }
        this.woocommerce.createOrder(order);
      }
    })
    .verified((p: IAPProduct) => p.finish());

    // Specific query for one ID
    this.store?.when(PROD_LIFETIME).owned((p: IAPProduct) => {
      if (this.userProfile) {
        this.user.updateAppAccess(this.userProfile, 1).then(() => {
          console.log('Update App Access Enables!');
          this.user.updateUser(this.userProfile, 'subscription_type', PROD_LIFETIME).then(() => {
            console.log('Update Subscription Type!');
          });
        });
      }
    });

    this.store.when('subcription').updated((product: IAPProduct) => {
      if (product.owned) {
        this.user.updateAppAccess(this.userProfile, 1).then(() => {
          console.log('Update App Access Enables!');
          this.user.fetchProfile(this.userProfile.id);
        });
      }
    });

  }

  dismissPage() {
    this.modalCtrl.dismiss();
  }

}
