/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';

// Ionic
import { AlertController, ModalController } from '@ionic/angular';

// Services
import { UserProgramsService } from 'src/app/_services/user-programs.service';
import { ProgramsService } from 'src/app/_services/programs.service';
import { UserProgram } from 'src/app/_interfaces/UserPrograms.interface';

// Components
import { SessionOverviewPage } from '../session-overview/session-overview.page';

// Interfaces
import Program from 'src/app/_interfaces/Program.interface';
import { UserProfile } from 'src/app/_interfaces/UserData.interface';


// Utils
import * as moment from 'moment';
import Session from 'src/app/_interfaces/Session.interface';
import { UserService } from 'src/app/_services/user.service';

// Env
import Env from 'src/app/env';


@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.page.html',
  styleUrls: ['./program-details.page.scss'],
})
export class ProgramDetailsPage implements OnInit {

  @Input() userProfile: UserProfile;
  @Input() program: Program;
  @Input() userCurrentProgram: UserProgram;

  form: FormGroup;

  isEnrolled = false;

  external: boolean = false;

  platformData: any;
  ios: any;

  constructor(
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private user: UserService,
    private userProgramService: UserProgramsService,
    private programsService: ProgramsService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public platform: Platform,
    public deviceService: DeviceDetectorService,
  ) {
    this.form = this.fb.group({
      start_date: ['', [Validators.required]],
    });

    this.platformData = JSON.stringify(this.deviceService.getDeviceInfo());

    const platformData = this.platform.platforms();
    this.ios = (platformData.filter(p => p === 'ios').length > 0) ? true : false;

  }

  ngOnInit() {

    if(!this.program) {

      this.user.userProfile.subscribe((userProfile: UserProfile) => {
        this.userProfile = userProfile;
      });

      let program_id = this.route.snapshot.paramMap.get('id');

      if(program_id) {

        this.external = true;

        // Fetch User Current Program
        this.fetchUserCurrentProgram(Number(this.userProfile.id));

        // Fetch Program
        this.fetchProgram(Number(program_id));

      }


    }

    if (this.program?.id === this.userCurrentProgram?.program_id && !this.external) {
      this.isEnrolled = true;
      this.form.patchValue({
        start_date: moment(this.userCurrentProgram?.start_date).format('YYYY-MM-DD')
      });
    }
  }

  /**
   * Enroll user in program
   *
   * @param program Program object
   */
  enroll(program: Program) {
    // Are you sure?
    this.alertCtrl.create({
      header: `Enroll in the ${program.name}?`,
      message: `Please confirm that you would like to enroll in the ${program.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Enroll',
          handler: () => {
            this.userProgramService.enrollInProgram(program, this.userProfile.id).then((response) => {
              this.userCurrentProgram.program_id = program.id;
              this.userCurrentProgram.start_date = moment().format('YYYY-MM-DD');
              this.form.patchValue({
                start_date: this.userCurrentProgram?.start_date
              });
              this.isEnrolled = true;
              this.alertCtrl.create({
                header: `All Set! 🎉`,
                message: `You've been successfully in the ${program.name}.`,
                buttons: [
                  {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                      // ...
                    }
                  }
                ]}).then(alertEl => alertEl.present());
            });
          }}
        ]}).then(alertEl => alertEl.present());
  }

  /**
   * Begin Primary Program
   *
   * @param program Program object
   * @param session Session object
   */
  async beginPrimary(program: Program, session: Session) {
    // Create Non Enrolled User to Program Record
    this.userProgramService.createUserProgram(this.userProfile.id, program.id).then(nonEnrolledUserProgram => {
      this.openProgramModal(nonEnrolledUserProgram, program, session);
    });
  }

  /**
   * Begin Ancillary Program
   *
   * @param program Program object
   * @param session Session object
   */
  async beginAncillary(program: Program, session: Session) {
    // Create Non Enrolled User to Program Record
    this.userProgramService.createUserProgram(this.userProfile.id, program.id).then(nonEnrolledUserProgram => {
      this.openProgramModal(nonEnrolledUserProgram, program, session);
    });
  }

  /**
   * Open Program Modal
   *
   * @param nonEnrolledUserProgram User Program Object
   * @param program Program Object
   * @param session Session Object
   * @returns modal instance
   */
   async openProgramModal(nonEnrolledUserProgram: UserProgram, program: Program, session: Session) {
    const modal = await this.modalCtrl.create({
      component: SessionOverviewPage,
      cssClass: 'anyman-modal dark',
      componentProps: {
        userProfile: this.userProfile,
        userCurrentProgram: nonEnrolledUserProgram,
        userCurrentProgramDetails: program,
        todaysSession: session
      }
    });
    return await modal.present();
  }

  /**
   * Confirm change of start date
   *
   * @param event Event data
   */
  confirmStartDateChange(event) {
    console.log('confirmStartDateChange', event);

    // Are you sure?
    this.alertCtrl.create({
      header: `Change your start date?`,
      message: `Please confirm that you would like to change your start date for the ${this.program?.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.form.patchValue({
              start_date: moment(this.userCurrentProgram?.start_date).format('YYYY-MM-DD')
            });
          }
        },
        {
          text: 'Change Date',
          handler: () => {

            const date = moment(this.form.value.start_date).format('YYYY-MM-DD');

            this.userProgramService.updateProgramStartDate(this.userCurrentProgram, date).then((response) => {
              this.alertCtrl.create({
                header: `Start date updated!`,
                message: `You've updated the start date for the ${this.program?.name}.`,
                buttons: [
                  {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                      // ...
                    }
                  }
                ]}).then(alertEl => alertEl.present());
            });

          }}
        ]}).then(alertEl => alertEl.present());

  }

  /**
   * Adjust User's Program Start Date
   *
   * @param program Program object
   */
  adjustStartDate() {
    console.log('adjustStartDate', this.userProfile, this.program, this.userCurrentProgram);

  }

  /**
   * Fetch Program from a link or notification data
   *
   * @param program_id Program's ID
   */
   async fetchProgram(program_id: number) {
    return new Promise((resolve) => {

      this.programsService.fetchByID(program_id).then((program) => {

        this.program = program;

        resolve(true);

      });

    });
  }

  /**
   * Fetch User Current Program
   *
   * @param user_id Program's ID
   */
   async fetchUserCurrentProgram(user_id: number) {
    return new Promise((resolve) => {

      this.userProgramService.fetchUserCurrentProgram(user_id, 1, 0).then((userProgram) => {

        console.log(userProgram);

        this.userCurrentProgram = userProgram;

        resolve(true);

      });

    });
  }

  redirectTo() {
    this.router.navigateByUrl('/app/train');
  }

  subscribe() {
    window.open(`${Env.eCommerce()}/shop/`, '_blank');
  }

}
