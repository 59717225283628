/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Interfaces
import UserWorkoutList, { UserPreviousWorkoutPayload, UserSessionWorkoutResponse, UserSessionWorkouts, UserWorkoutRecord, UserSessionExerciseHistoryResponse, UserExerciseRecordHistory, UserExerciseRecordHistoryStats } from '../_interfaces/UserWorkout.interface';

// Services
import { ResponderService } from './responder.service';

// Environment
import Env from './../env';

// Utils
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserWorkoutService {

  private workoutSource = new BehaviorSubject<any>('');
  workout = this.workoutSource.asObservable();

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  /**
   * Find user workouts by session id
   *
   * @param session_id Session's ID
   * @returns promise
   */
  async finderUserWorkoutsBySession(session_id: number) {
    return new Promise((resolve, reject) => {

      if (!session_id) {
        reject('No session_id provided');
      }

      this.http.get(`${Env.api()}/users/workouts/find`).subscribe((res: UserWorkoutList) => {
        if (res.data && res.data.user_session_workouts) {
          console.log('user workouts?', res.data.user_session_workouts, session_id);
            const filter = res.data.user_session_workouts.filter(
              (user_session_workout: UserSessionWorkouts) =>
                (user_session_workout.user_program_session_id === session_id)
            );
          resolve(filter);
        } else {
          resolve(false);
        }
      }, err => {
        this.responder.error(err,
          'Error Finding User Workouts',
          'There was an error finding user workouts. Please try again or contact support.');
      });

    });
  }

  /**
   * Find User Workouts
   *
   * @param user_id User's ID
   * @param user_program_session_id User Program to Session ID
   * @param workout_id Workout ID
   * @param set_n Set Number
   */
  async findUserWorkout(user_id: number, session_id: number, workout_id: number, exercise_id: number, set_n?: number, page?: number, size?: number) {
    return new Promise<UserWorkoutRecord[] | boolean>((resolve, reject) => {

      console.log('findUserWorkoutPayload params', user_id, session_id, workout_id, set_n);

      if (!session_id) {
        reject('No session_id provided');
      }

      if (!workout_id) {
        reject('No workout_id provided');
      }

      let url;

      if(page && size) {
        url = `${Env.api()}/users/workouts/find_by_user_and_workout?page=${page}&size=${size}&orderBy=id&orderByType=desc`;
      } else if (size && !page) {
        url = `${Env.api()}/users/workouts/find_by_user_and_workout?size=${size}&orderBy=id&orderByType=desc`;
      } else if (page && !size) {
        url = `${Env.api()}/users/workouts/find_by_user_and_workout?page=${page}&orderBy=id&orderByType=desc`;
      } else {
        url = `${Env.api()}/users/workouts/find_by_user_and_workout`;
      }

      this.http.post(url, {
        user_id, workout_id
      }).subscribe((res: UserSessionWorkoutResponse) => {
        if (res.data && res.data.user_session_workouts) {
          let filter;
          if (!set_n) {
            filter = res.data.user_session_workouts.filter(
              (user_session_workout: UserSessionWorkouts) =>
                (user_session_workout.user_program_session_id === session_id) &&
                (user_session_workout.workout_id === workout_id)
            );
          } else {
            filter = res.data.user_session_workouts.filter(
              (user_session_workout: UserSessionWorkouts) =>
                (user_session_workout.user_program_session_id === session_id) &&
                (user_session_workout.workout_id === workout_id) &&
                (user_session_workout.set_n === set_n)
            );
          }
          resolve(filter);
        } else {
          resolve(false);
        }

      }, err => {
        this.responder.error(err,
          'Error Finding User Workouts',
          'There was an error finding user workouts. Please try again or contact support.');
      });

    });
  }

  /**
   * Create User Workout
   *
   * @param user_program_session_id User's Program Session ID
   * @param workout_id Workout ID
   * @param set_n Set number
   * @returns promise
   */
  async createUserWorkout(user_id: number, user_program_session_id: number, workout_id: number, set_n: number) {
    return new Promise((resolve, reject) => {

      if (!user_id) {
        reject('No user_id provided');
      }

      if (!user_program_session_id) {
        reject('No user_program_session_id provided');
      }

      if (!workout_id) {
        reject('No workout_id provided');
      }

      if (!set_n) {
        reject('No set_n provided');
      }

      const payload = {
        user_program_session_id,
        workout_id,
        set_n,
        user_id,
      };

      this.http.post(`${Env.api()}/users/workouts/create`, payload).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Creating User Workout',
          'There was an error creating the user workout. Please try again or contact support.');
      });

    });
  }

  /**
   * Update User's Workout
   *
   * @param workout Workout Object
   * @param user_program_session_id User Program Session ID
   * @param workout_id Workout ID
   * @param set_n Set number
   * @returns promise
   */
  async completeUserWorkoutSet(user_id: number, workout: UserWorkoutRecord, user_program_session_id: number, workout_id: number, set_n: number) {
    return new Promise((resolve, reject) => {

      if (!user_program_session_id) {
        reject('No user_program_session_id provided');
      }

      if (!workout_id) {
        reject('No workout_id provided');
      }

      if (!set_n) {
        reject('No set_n provided');
      }

      const payload = {
        user_program_session_id,
        workout_id,
        set_n,
        datetime_completed: moment().format('YYYY-MM-DD HH:mm:ss'),
        user_id
      };

      this.http.post(`${Env.api()}/users/workouts/update/${workout.id}`, payload).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Updating User Workout',
          'There was an error updating the user workout. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch User's Previous Workout Sessions
   *
   * @param payload User ID and Workout ID
   * @returns Workout Record Array
   */
  async previousWorkout(payload: UserPreviousWorkoutPayload) {
    return new Promise<UserWorkoutRecord[]>((resolve, reject) => {

      if (!payload) {
        reject('No payload provided');
      }

      this.http.post(`${Env.api()}/users/workouts/find_by_user_and_workout`, payload).subscribe((res: UserSessionWorkoutResponse) => {
        resolve(res?.data?.user_session_workouts);
      }, err => {
        this.responder.error(err,
          'Error Finding Previous Workout Data',
          'There was an error finding previous workout data. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch User's Previous Workout History Sessions using only user ID and Exercise ID
   *
   * @param payload User ID and Workout ID
   * @returns Workout Record Array
   */
   async previousWorkouthHistory(user_id: number, exercise_id: number, all?: boolean) {
    return new Promise<UserExerciseRecordHistory[]>((resolve, reject) => {

      if (!user_id) {
        reject('No user_id provided');
      }

      if (!exercise_id) {
        reject('No exercise_id provided');
      }

      this.http.get(`${Env.api()}/users/workouts/exercises/history/user/${user_id}/exercise/${exercise_id}`).subscribe((res: UserSessionExerciseHistoryResponse) => {
        if(all) {
          resolve(res.data);
        } else {
          resolve(res.data.slice(0, 50));
        }
      }, err => {
        this.responder.error(err,
          'Error Finding Previous Workout Data',
          'There was an error finding previous workout data. Please try again or contact support.');
      });

    });

  }

  /**
   * Fetch User's Previous Workout History Sessions using only user ID and Exercise ID
   *
   * @param payload User ID and Workout ID
   * @returns Workout Record Array
   */
   async previousWorkouthHistoryStats(user_id: number, exercise_id: number) {
    return new Promise<UserExerciseRecordHistoryStats>((resolve, reject) => {

      if (!user_id) {
        reject('No user_id provided');
      }

      if (!exercise_id) {
        reject('No exercise_id provided');
      }

      this.http.get(`${Env.api()}/users/workouts/exercises/history/user/${user_id}/exercise/${exercise_id}`).subscribe((res: UserSessionExerciseHistoryResponse) => {
        if(res && res.data) {
          // console.log(res.data);
          // const maxRepsSession = ;
          const response = {
            totalVolume: res.data.reduce((total, obj) => obj.weight + total, 0),
            totalReps: res.data.reduce((total, obj) => obj.reps + total, 0),
            totalTime: res.data.length,
            maxWeightLifter: Math.max(...res.data.map(o => o.weight)),
            maxVolumeSingleSet: Math.max(...res.data.map(o => o.reps)),
            maxRepsSingleSet: Math.max(...res.data.map(o => o.reps)),
          };
          resolve(response);
        }
      }, err => {
        this.responder.error(err,
          'Error Finding Previous Workout Data',
          'There was an error finding previous workout data. Please try again or contact support.');
      });

    });

  }

}
