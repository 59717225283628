import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'todayfilterloop',
    pure: false
})
export class TodayFilterLoopPipe implements PipeTransform {
    transform(items: any, filter: Object, orderSuperset?: number): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if(orderSuperset) {
          return items.filter(item => item.exercise_id === filter && item.orderSuperset === orderSuperset);
        } else {
          return items.filter(item => item.exercise_id === filter);
        }
    }
}
