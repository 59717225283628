// Angular
import { Component, Input, OnInit, ChangeDetectionStrategy, ViewChild  } from '@angular/core';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { NativeAudio } from '@capgo/native-audio';
import { DeviceDetectorService } from 'ngx-device-detector';

// Ionic
import { ModalController, Platform } from '@ionic/angular';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

// Service
import { TimerService } from 'src/app/_services/timer.service';


@Component({
  selector: 'app-timer',
  templateUrl: './timer.page.html',
  styleUrls: ['./timer.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerPage implements OnInit {

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  config: CountdownConfig = {
    formatDate: ({ date }) => `${date / 1000}`,
    leftTime: 50,
    notify: 0 };

  @Input() mode: 'timer' | 'percentage';
  @Input() limit = 0;
  @Input() autoStart: boolean;
  @Input() closingTimer: any;

  percentage = 0;
  restTimeLeft = this.limit;
  newTimeLeft: number;

  pause: boolean;
  reset: boolean;
  running: boolean;
  complete: boolean;

  // Stroke width
  @Input() strokeWidth = 8;
  // Progress stroke width
  @Input() progressStrokeWidth = 20;
  // How long you want the animation to take, in ms
  @Input() animationDuration = 1000;

  // Size of the enclosing square
  sqSize = 200;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  radius = (this.sqSize - this.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  viewBox = `0 0 ${this.sqSize} ${this.sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  dashArray = this.radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  dashOffset = this.dashArray - this.dashArray * this.percentage / 100;

  // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
  frameDuration = 1000 / 60;
  // Use that to calculate how many frames we need to complete the animation
  totalFrames = Math.round( this.animationDuration / this.frameDuration );

  platformData: any;
  ios: any;
  android: any;
  desktop: any;
  pwa: any;
  mobile: any;
  assetPath: string;

  constructor(
    public modalCtrl: ModalController,
    public timer: TimerService,
    public backgroundMode: BackgroundMode,
    public platform: Platform,
    public deviceService: DeviceDetectorService,
  ) {
    this.mode = 'percentage';
    this.frameDuration = 1000 / 45;

    this.platformData = JSON.stringify(this.deviceService.getDeviceInfo());

    const platformData = this.platform.platforms();
    this.ios = (platformData.filter(p => p === 'ios').length > 0) ? true : false;
    this.android = (platformData.filter(p => p === 'android').length > 0) ? true : false;
    this.desktop = (platformData.filter(p => p === 'desktop').length > 0) ? true : false;
    this.pwa = (platformData.filter(p => p === 'pwa').length > 0) ? true : false;
    this.mobile = (platformData.filter(p => p === 'mobileweb').length > 0) ? true : false;

    this.setRingtone();

  }

  async ngOnInit() {
    this.percentage = 0;
    this.running = false;
    if(this.closingTimer?.running === true) {
      this.config = { ...this.config, leftTime: this.closingTimer?.timeLeft };
    } else {
      this.config = { ...this.config, leftTime: this.limit };
    }
  }

  handleEvent(event){
    // console.log(event);
    const restTime = event.left/1000;

    this.restTimeLeft = restTime;
    // console.log(restTime);

    if(restTime > 0 ){
      this.percentage = parseFloat((restTime / this.limit).toFixed(2)) * 100;
      this.calcProgress();

      const closingData = {
        timeLeft: this.restTimeLeft,
        running: true
      };

      this.timer.nextTimer(closingData);

    }

    if(event?.action === 'done' && event.left === 0) {
      this.timerComplete();
    }

  }

  ionViewWillLeave() {
    // this.stopTimer();
  }

  ionViewDidEnter() {
    // console.log(this.closingTimer);
    if (this.autoStart) {
      this.startTimer();
    }
  }

  /**
   * Start Timer
   */
  startTimer() {

    this.running = true;
    this.complete = false;

    if(this.closingTimer?.running === true) {
      this.config = { ...this.config, leftTime: this.closingTimer?.timeLeft };
    }

    if(this.reset) {
      this.countdown.restart();
    }

  }

  /**
   * Resume Timer
   */
   resumeTimer() {
    this.countdown.resume();
    this.pause = false;
    this.running = true;
    this.complete = false;
  }

  /**
   * Stop Timer
   */
  stopTimer() {
    this.countdown.pause();
    this.running = false;
    this.complete = false;
    this.pause = true;
    this.reset = false;
  }

  /**
   * Reset Timer
   */
  resetTimer() {
    this.reset = true;
    this.running = false;
    this.complete = false;
    this.countdown.restart();
    this.countdown.stop();
    this.percentage = 0;
    const closingData = {
      timeLeft: 0,
      running: false
    };
    this.timer.nextTimer(closingData);
  }

  /**
   * Timer Complete
   */
  timerComplete() {
    this.playSingle();
    this.stopTimer();
    this.complete = true;
    this.dismiss();
  }

  dismiss() {
    this.resetTimer();
    this.modalCtrl.dismiss();
  }

  minimizeTimer() {
    const closingData = {
      timeLeft: this.restTimeLeft,
      running: true
    };
    this.timer.setCountdown(closingData);
    this.modalCtrl.dismiss(closingData);
  }

  /**
   * Calculate progress for line offeset
   */
   calcProgress() {
    this.dashOffset = this.dashArray + this.dashArray * this.percentage / 100;
  }

  /**
   * Rotates element
   *
   * @returns CSS string
   */
  circleTransform() {
    return `rotate(-90 ${this.sqSize / 2} ${this.sqSize / 2})`;
  }

  /**
   * Define stroke width for background
   *
   * @returns style attribute
   */
  circleBackgroundStroke() {
    return `stroke-width: ${this.strokeWidth}px;`;
  }

  /**
   * Progress line
   *
   * @returns style attribute
   */
  progress() {
    // eslint-disable-next-line max-len
    return `transition: all 0.75s linear;  stroke-dasharray: ${this.dashArray}; stroke-dashoffset: ${this.dashOffset}; stroke-width: ${this.progressStrokeWidth}px; transform: scale(0.92) rotate(-90deg); transform-origin: center; stroke-linecap:butt;`;
  }

  // Preload the audio track
  setRingtone() {

    if(this.ios && !this.mobile || this.android && !this.mobile) {

      this.assetPath = 'public/assets/sounds/toast_sound.mp3';
      NativeAudio.configure({focus: false});

    } else {

      this.assetPath = 'toast_sound.mp3';

    }

    NativeAudio.preload({
        assetId: 'toastSound',
        assetPath: this.assetPath,
        audioChannelNum: 2,
        isUrl: false
    }).then(() => console.log('loaded'))
    .catch(e => console.error(e));
    NativeAudio.setVolume({
      assetId: 'toastSound',
      volume: 1.0,
    });
  }

  // Play the audio track
  playSingle() {
    NativeAudio.play({
        assetId: 'toastSound',
        time: 0
    });
  }

  // Add 30 seconds to the timer
  addTime() {
    this.percentage = 0;
    this.countdown.stop();
    this.reset = true;
    this.running = false;
    this.complete = false;
    const restTime = this.limit;
    this.newTimeLeft = restTime + 30;
    this.limit = this.newTimeLeft;
    this.config = { ...this.config, leftTime: this.newTimeLeft };
    // console.log(this.restTimeLeft);
    this.countdown.restart();
    this.running = true;
  }

  // Add 30 seconds to the timer
  removeTime() {
    this.percentage = 0;
    this.countdown.stop();
    this.reset = true;
    this.running = false;
    this.complete = false;
    const restTime = this.limit;
    this.newTimeLeft = restTime - 30;
    this.limit = this.newTimeLeft;
    this.config = { ...this.config, leftTime: this.newTimeLeft };
    // console.log(this.restTimeLeft);
    this.countdown.restart();
    this.running = true;
  }

}
