/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Interfaces
import { UserProfile } from '../_interfaces/UserData.interface';

// Services
import { ResponderService } from './responder.service';

import Env from '../env';
import { Timezones } from '../_interfaces/TimeZones';

@Injectable({
  providedIn: 'root'
})
export class TimezoneListService {

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

   /**
   * Get Timezone List
   *
   */
    async getTimezonesList() {
      return new Promise<Timezones[]>((resolve) => {

        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/get_timezoneslist/`).subscribe((res: Timezones[]) => {
            resolve(res);
        });

      });
    }

  /**
   * Save New Timezone on wordpress and app
   *
   * @param wp_user number
   * @param timezone string
   * @returns promise
   */
     async updateTimezone(wp_user: number, timezone: string) {
      return new Promise((resolve) => {

        const payload = {
        };

        this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/update_timezone/?user_id=${wp_user}&timezone=${timezone}`, payload).subscribe(() => {
          resolve(true);
        }, err => {
          this.responder.error(err,
            'Error Saving Timezone in wordpress',
            'There was an error saving a set of time zone. Please try again or contact support.');
        });

        this.http.post(`${Env.api()}/users/update/wordpress/${wp_user}`, {
          key: 'timezone',
          value: timezone
        }).subscribe(res => {
          resolve(true);
        }, err => {
          this.responder.error(err,
            'Error Saving Timezone in app',
            'There was an error saving a set of time zone. Please try again or contact support.');
        });

      });
    }


}
