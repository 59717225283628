/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Ionic
import { AlertController } from '@ionic/angular';

// Interfaces
import { WordPressForumCategories, WordpressForumPost, CommentFormData, PostFormData, VoteData, VotesData, CommentVotesData } from '../_interfaces/WordPressPosts.interface';

// Env
import Env from '../env';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  auth_token: string = Env.wordpressAccessToken();

  headerDict: any;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController
  ) {
    this.headerDict = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.auth_token
    }

   }

   /**
   * Get Forum Rules
   *
   * @returns promise
   */
    async getForumRules() {
      return new Promise<WordpressForumPost[]>((resolve) => {

        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog_rules/`).subscribe((res: WordpressForumPost[]) => {
            resolve(res);
        }, error => {
          console.warn('No content');
        });

      });
    }

  /**
   * Get All Categories
   *
   * @param page Page number
   * @returns promise
   */
   async getAllCategories() {
    return new Promise<WordPressForumCategories[]>((resolve) => {

      this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog_cat/`).subscribe((res: WordPressForumCategories[]) => {
          resolve(res);
      });

    });
  }

  /**
   * Get All Forum Posts
   *
   * @param page Page number
   * @returns promise
   */
   async getAllPosts(page?: number, category_id?: any) {
    return new Promise<WordpressForumPost[]>((resolve) => {

      if(!page && !category_id) {
        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog`).subscribe((res: WordpressForumPost[]) => {
          resolve(res);
        }, error => {
          console.warn('No more post');
        });
      } else if (page && !category_id) {
        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog?&page=${page}`).subscribe((res: WordpressForumPost[]) => {
          resolve(res);
        }, error => {
          console.warn('No more post');
        });
      } else {
        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog?category_id=${category_id}`).subscribe((res: WordpressForumPost[]) => {
            resolve(res);
        }, error => {
          console.warn('No more post');
        });
      }

    });
  }

  /**
   * Get Single Forum Posts
   *
   * @param page Page number
   * @returns promise
   */
   async getThread(post_id: any) {
    return new Promise<WordpressForumPost[]>((resolve) => {

      if (post_id) {
          this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/get_thread?&post_id=${post_id}`).subscribe((res: WordpressForumPost[]) => {
            resolve(res);
          }, error => {
            console.warn('No post found');
          });
      }

    });
  }

  /**
   * Search All Forum Posts
   *
   * @param page Page number
   * @returns promise
   */
   async searchAllPosts(page = 1, search?: string, category_id?: number) {
    return new Promise<WordpressForumPost[]>((resolve) => {
      if (search) {
        this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/aog_search?search_string=${search}&page=${page}&category_id=${category_id}`).subscribe((res: WordpressForumPost[]) => {
          resolve(res);
        }, error => {
          console.warn('No more post');
        });
      }
    });
  }

  /**
   * Get All Forum Comments
   *
   * @param page Page number
   * @returns promise
   */
   async getCommentsCount(post_id: any, search?: string) {
    return new Promise<WordpressForumPost[]>((resolve) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!search) {
        if (!post_id) {
          console.warn('No post ID');
        } else {
          this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments?post=${post_id}&per_page=100`,requestOptions).subscribe((res: WordpressForumPost[]) => {
              resolve(res);
          }, error => {
            console.warn('No more comments');
          });
        }
      } else {
        search = encodeURIComponent(search.toLowerCase());
        this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments?post=${post_id}&search=${search}&per_page=100`,requestOptions).subscribe((res: WordpressForumPost[]) => {
          resolve(res);
        }, error => {
          console.warn('No comments');
        });
      }
    });
  }

  /**
   * Get All Parent Forum Comments
   *
   * @param page Page number
   * @returns promise
   */
   async getAllParentComments(page = 1, post_id: any, search?: string) {
    return new Promise<WordpressForumPost[]>((resolve) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!search) {
        if (!post_id) {
          console.warn('No post ID');
        } else {
          this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments?post=${post_id}&page=${page}&parent=0&per_page=20`,requestOptions).subscribe((res: WordpressForumPost[]) => {
              resolve(res);
          }, error => {
            console.warn('No more comments');
          });
        }
      } else {
        search = encodeURIComponent(search.toLowerCase());
        this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments?post=${post_id}&search=${search}&page=${page}`,requestOptions).subscribe((res: WordpressForumPost[]) => {
          resolve(res);
        }, error => {
          console.warn('No comments');
        });
      }
    });
  }

  /**
   * Get All Forum Comments
   *
   * @param page Page number
   * @returns promise
   */
   async getAllChildComments(post_id: any, parent_id: any) {
    return new Promise<WordpressForumPost[]>((resolve) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!post_id) {
        console.warn('No post ID');
      } else {
        this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments?post=${post_id}&parent=${parent_id}&per_page=100`,requestOptions).subscribe((res: WordpressForumPost[]) => {
            resolve(res);
        }, error => {
          console.warn('No more comments');
        });
      }
    });
  }

  /**
   * Create post
   *
   * @param post Comment form values
   * @returns promise
   */
   async createPost(post: PostFormData) {
    return new Promise((resolve, reject) => {

      const postjson = JSON.stringify(post);

      // console.log(headerDict);

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!post) {
        reject('No post provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/aog_create`, postjson, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
        console.log(err);
          this.alertCtrl.create({
            header: 'Error Creating Post',
            message: 'There was an error creating the post. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Delete post
   *
   * @param post Post form values
   * @returns promise
   */
   async deletePost(post: any) {
    return new Promise((resolve, reject) => {

      const postjson = JSON.stringify(post);

      // console.log(headerDict);

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!post) {
        reject('No post provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/aog_delete`, postjson, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
        console.log(err);
          this.alertCtrl.create({
            header: 'Error Deleting Post',
            message: 'There was an error deleting the post. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Get votes
   *
   * @param id Comment_id
   * @returns promise
   */
   async getVotes(post_id: number) {
    return new Promise<VotesData[]>((resolve, reject) => {

      if (!post_id) {
        reject('No comment post_id provided');
      }

      this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/get_votes?post_id=${post_id}`).subscribe((res: VotesData[]) => {
        resolve(res);
      }, err => {
          this.alertCtrl.create({
            header: 'Error from fetch votes',
            message: 'There was an error fetching the votes. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Give Vote
   *
   * @param vote
   * @returns promise
   */
   async giveVote(vote: VoteData) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      const votejson = JSON.stringify(vote);

      if (!vote) {
        reject('No vote provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/vote`, votejson, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error Voting',
            message: 'There was an error give the fist bump. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Remove Vote
   *
   * @param vote
   * @returns promise
   */
   async removeVote(vote: VoteData) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      const votejson = JSON.stringify(vote);

      if (!vote) {
        reject('No vote provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/remove_vote`, votejson, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error Undo Vote',
            message: 'There was an error removing the fist bump. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Create comment
   *
   * @param comment Comment form values
   * @returns promise
   */
   async create(comment: CommentFormData) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!comment) {
        reject('No comment provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/aog_create_comment`, comment, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error Creating Comment',
            message: 'There was an error creating the comment. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Update comment
   *
   * @param comment Comment form values
   * @returns promise
   */
   async updateComment(comment: CommentVotesData) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!comment) {
        reject('No comment provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/aog_update_comment`, comment, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error Updating Comment',
            message: 'There was an error updating the comment. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Get comment
   *
   * @param id Comment_id
   * @returns promise
   */
   async get(id: number) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!id) {
        reject('No comment id provided');
      }

      this.http.get(`${Env.eCommerce()}/wp-json/wp/v2/comments/${id}`, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error from fetch comment',
            message: 'There was an error fetching the comment. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Edit comment
   *
   * @param comment Comment form values
   * @returns promise
   */
   async edit(id: number, comment: CommentFormData) {
    return new Promise((resolve, reject) => {

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!comment) {
        reject('No comment provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/wp/v2/comments/${id}`, comment, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error Update Comment',
            message: 'There was an error editing the comment. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Delete comment
   *
   * @param id Comment_id
   * @returns promise
   */
   async delete(id: number) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!id) {
        reject('No comment id provided');
      }

      const payload = {
        comment_id: id
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/aog_delete_comment/`, payload).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error from delete comment',
            message: 'There was an error deleting the comment. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Upload Image
   *
   * @param post_id
   * @param user_id
   * @returns promise
   */
   async uploadImage(post_id: number, user_id: number, image: File) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!image) {

        reject('No image provided');

      } else {


        let formData : FormData = new FormData();

        console.log(image);

        formData.append('file', image);

        this.http.post<any>(`${Env.eCommerce()}/wp-json/amf/v1/upload_image?post_id=${post_id}&user_id=${user_id}`, formData, requestOptions).subscribe((res) => {
          resolve(res);
        }, err => {
            this.alertCtrl.create({
              header: 'Error Upload Image',
              message: 'There was an error while uploading image. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
        });

      }

    });
  }

  /**
   * Upload Image
   *
   * @param post_id
   * @param user_id
   * @returns promise
   */
   async uploadFeaturedImage(user_id: number, image: File) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!image) {

        reject('No image provided');

      } else {


        let formData : FormData = new FormData();

        console.log(image);

        formData.append('file', image);

        this.http.post<any>(`${Env.eCommerce()}/wp-json/amf/v1/upload_featured_image?user_id=${user_id}`, formData, requestOptions).subscribe((res) => {
          resolve(res);
        }, err => {
            this.alertCtrl.create({
              header: 'Error Upload Image',
              message: 'There was an error while uploading image. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
        });

      }

    });
  }

  /**
   * Save how to display their name in the forum
   *
   * @param user_id
   * @returns promise
   */
   async getNickname(user_id: number) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!user_id) {
        reject('No user id provided');
      }

      this.http.get(`${Env.eCommerce()}/wp-json/amf/v1/get_nickname?&user_id=${user_id}`).subscribe((res: string) => {
        resolve(res);
      }, err => {
          // this.alertCtrl.create({
          //   header: 'Error from get nickname',
          //   message: 'There was an error while getting nickname. Please try again or contact support.',
          //   buttons: [{
          //     text: 'Dismiss'
          //   }],
          //   backdropDismiss: false
          // }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Save how to display their name in the forum
   *
   * @param user_id
   * @param type
   * @returns promise
   */
   async updateNickname(user_id: number, type: string) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!user_id) {
        reject('No user id provided');
      }

      const payload = {
        user_id: user_id,
        type: type
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/set_nickname/`, payload).subscribe(() => {
        resolve(true);
      }, err => {
          this.alertCtrl.create({
            header: 'Error from set nickname',
            message: 'There was an error while setting nickname. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Upload Profile Image in WP
   *
   * @param user_id User Profile WP id
   * @param image File
   * @returns promise
   */
   async uploadProfileWP(user_id: number, image: File) {
    return new Promise((resolve, reject) => {

      this.headerDict = {
        'Authorization': 'Bearer ' + this.auth_token
      }

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!image) {

        reject('No image provided');

      } else {


        let formData : FormData = new FormData();

        console.log(image);

        formData.append('file', image);

        this.http.post<any>(`${Env.eCommerce()}/wp-json/amf/v1/set_profile_image?user_id=${user_id}`, formData, requestOptions).subscribe((res) => {
          resolve(res);
        }, err => {
            this.alertCtrl.create({
              header: 'Error Upload Profile Image on WP',
              message: 'There was an error while uploading image. Please try again or contact support.',
              buttons: [{
                text: 'Dismiss'
              }],
              backdropDismiss: false
            }).then(alertEl => alertEl.present());
        });

      }

    });
  }

}
