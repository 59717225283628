import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToMinutesPipe } from './seconds-to-minutes.pipe';
import { MomentDatePipe } from './moment-date.pipe';
import { DecodeURIPipe } from './decode-uri.pipe';
import { SubscriptionPipe } from './subscription.pipe';
import { PreviousFilterLoopPipe } from './previousfilterloop.pipe';
import { TodayFilterLoopPipe } from './todayfilterloop.pipe';
import { TimePipe } from './time.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CommentsfilterPipe } from './commentsfilter.pipe';
import { IncludesPipe } from './includes.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TimeNowPipe } from './time-now.pipe';

@NgModule({
  declarations: [
    SecondsToMinutesPipe,
    MomentDatePipe,
    DecodeURIPipe,
    SubscriptionPipe,
    PreviousFilterLoopPipe,
    TodayFilterLoopPipe,
    TimePipe,
    SafeHtmlPipe,
    CommentsfilterPipe,
    IncludesPipe,
    SafeUrlPipe,
    TimeNowPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SecondsToMinutesPipe,
    MomentDatePipe,
    DecodeURIPipe,
    SubscriptionPipe,
    PreviousFilterLoopPipe,
    TodayFilterLoopPipe,
    TimePipe,
    SafeHtmlPipe,
    CommentsfilterPipe,
    IncludesPipe,
    SafeUrlPipe,
    TimeNowPipe
  ]
})
export class PipesModule { }
