import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuUserComponent } from './menu-user.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/_pipes/pipes.module';



@NgModule({
  declarations: [MenuUserComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule
  ],
  exports: [MenuUserComponent]
})
export class MenuUserModule { }
