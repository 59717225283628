/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { ApplicationRef, Component, OnInit, NgZone } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SwUpdate } from '@angular/service-worker';

// Ionic
import { MenuController, ToastController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NativeAudio } from '@capgo/native-audio';

// Security
import { AuthGuard } from './_guards/auth.guard';

// Utils
import { concat, fromEvent, interval, merge, Observable, of } from 'rxjs';
import { filter, first, mapTo } from 'rxjs/operators';
import * as moment from 'moment';

// Version
import * as gitData from './../git-version.json';

// Services
import { UserService } from './_services/user.service';
import { ForumService } from './_services/forum.service';
import { TimezoneListService } from './_services/timezone-list.service';
import { MenuOverlayService } from './_services/menu-overlay.service';
import { TimerService } from './_services/timer.service';
import { NotificationsService } from './_services/notifications.service';

// Interfaces
import { UserProfile } from './_interfaces/UserData.interface';
import { Timezones } from './_interfaces/TimeZones';

// Env
import Env from './env';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  // Focus
  hasFocus: any;
  hasFocusTimeout: any;

  // Updates
  updateAvailable: any;

  // Git
  git: any;

  userProfile: UserProfile;

  basicInfo: FormGroup;

  unit_of_measurement: string;

  public online: Observable<boolean> = undefined;
  connectionStatus: boolean;

  toast: any;

  // Timezones (https://github.com/dmfilipenko/timezones.json)
  timezonesList: Timezones[] = [];
  timezoneFetch: string;
  timezoneSelected: Timezones;

  nicknameType: string;

  timeLeft: any;
  timeout: any;
  runningTimeLeft: any;
  sendLocalnotification: boolean = true;

  constructor(
    public menu: MenuController,
    private fb: FormBuilder,
    private storage: Storage,
    public auth: AuthGuard,
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    public router: Router,
    private zone: NgZone,
    private toastController: ToastController,
    private user: UserService,
    private network: Network,
    public platform: Platform,
    private timezoneListService: TimezoneListService,
    private forumService: ForumService,
    private menuOverlay: MenuOverlayService,
    public backgroundMode: BackgroundMode,
    private timer: TimerService,
    private notifications: NotificationsService,
    private alertCtrl: AlertController,
  ) {

    this.git = gitData;

    // Basic Info
    this.basicInfo = this.fb.group({
      unit_of_measurement: ['']
    });

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;

      // Assign unit of measurement, default to imperial
      this.unit_of_measurement = (this.userProfile?.unit_of_measurement) ? this.userProfile?.unit_of_measurement : 'imperial';


      this.fetchTimezoneList().then(() => {

        this.timezoneSelected = this.timezonesList.filter( tz => tz.name === this.userProfile?.timezone )[0];

        // console.log('User Timezone Selected : ', this.timezoneSelected);

      });

      if(this.userProfile?.wp_user_id) {
        this.fetchNicknameType();
      }

      // console.log('User timezone', this.timezoneSelected);



      // // Set Measurement
      // this.basicInfo.patchValue({
      //   unit_of_measurement: this.unit_of_measurement
      // }, {emitEvent: false});

    });

    this.initializeApp();

  }

  async ngOnInit() {
    this.afAscii();
    this.storage.create().then(() => {
      this.auth.canContinue();
      // Save Page Viewed
      this.router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.storage.remove('userPath');
        this.storage.set('userPath', window.location.pathname);
      });
    });

    // Check for application focus
    this.checkFocus();

    // Application focus event listeners
    window.addEventListener('focus', () => {
        this.checkFocus();
    });
    window.addEventListener('blur', () => {
        this.checkFocus();
    });
    window.addEventListener('pageshow', () => {
        this.checkFocus();
    }, false);
    window.addEventListener('pagehide', () => {
        this.checkFocus();
    }, false);

    this.checkForUpdates();

    // Prepare offline toast
    this.offline();

    // Check internet
    this.checkConnection().subscribe(isOnline => {
      this.connectionStatus = isOnline;

      // Checking internet connection
      if (this.connectionStatus === true) {
        console.log('Internet is working.');
        if (this.toast) {
          this.toast.dismiss();
        }
      } else {
        console.log('Internet is slow or not working.');
        this.offline().then(() => {
          this.toast.present();
        });
      }

    });

    // this.backgroundMode.enable();

    // Check if bgMode is active
    if(this.backgroundMode.isActive) {
      console.log('Active');
    }

    this.backgroundMode.on('activate').subscribe(s => {
      console.log('backgroundMode activate');

      this.backgroundMode.disableWebViewOptimizations();
      this.backgroundMode.disableBatteryOptimizations();

      // TO DO FIND SOLUTION TO NOT STOP AFTER FEW SECONDS

      // this.timeLeft = this.timer.getCountdown();

      // if(this.timeLeft) {
      //   console.log('this.timeLeft:', this.timeLeft);

      //   const running = this.timeLeft.running;

      //   if(running) {

      //     let timeLeft = this.timeLeft.timeLeft;

      //     this.timeout = setInterval(() => {
      //       if (timeLeft > 0) {
      //         timeLeft -= 1;
      //         this.runningTimeLeft = timeLeft;
      //         console.log(timeLeft);
      //       } else if (timeLeft === 0 && this.sendLocalnotification) {
      //         // Schedule a single notification
      //         // this.sendLocalNotification('Hey, times up!','Let’s get back at it!');
      //         // Play sound
      //         this.playSingle();
      //         const closingData = {
      //           timeLeft: 0,
      //           running: false
      //         };
      //         this.timer.setCountdown(closingData);
      //         this.sendLocalnotification = false;
      //       } else {
      //         this.runningTimeLeft = 0;
      //         clearInterval(this.timeout);
      //       }
      //     }, 1000);

      //     // console.log('this.timeLeft:', this.timeout);

      //   }
      // }

    });

    this.backgroundMode.on('deactivate').subscribe(s => {
      console.log('backgroundMode deactivate');

      // if(this.runningTimeLeft > 0) {
      //   console.log('this.runningTimeLeft:', this.runningTimeLeft);

      //   const closingData = {
      //     timeLeft: this.runningTimeLeft,
      //     running: true
      //   };
      //   this.timer.setCountdown(closingData);
      //   this.sendLocalnotification = true;

      // } else {

      //   const closingData = {
      //     timeLeft: 0,
      //     running: false
      //   };
      //   this.timer.setCountdown(closingData);
      //   this.sendLocalnotification = true;

      // }

    });

  }

  checkConnection() {

    if (!window || !navigator || !('onLine' in navigator)) {
      return;
    }

    this.online = Observable.create(observer => {
      observer.next(true);
    }).pipe(mapTo(true));

    if (this.platform.is('cordova')) {

      // Cordova platform
      this.online = merge(
        this.network.onConnect().pipe(mapTo(true)),
        this.network.onDisconnect().pipe(mapTo(false))
      );

    } else {

      // Browser platform
      this.online = merge( of (navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );

    }

    return this.online;
  }

  /**
   * Check for app updates via Service Worker
   */
  public checkForUpdates() {

    // If the service worker is enabled
    if (this.updates.isEnabled) {

        // console.log('Service worker is enabled');

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const updateInterval$ = interval(60 * 1000);
        const updateIntervalOnceAppIsStable$ = concat(appIsStable$, updateInterval$);

        // Check for updates interval subscription
        updateIntervalOnceAppIsStable$.subscribe(isStable => {
            console.log ('Checking for updates', isStable);
            this.updates.checkForUpdate();
        });

        // Subscribe and listen for the update check results
        this.updates.available.subscribe(updateData => {
            console.log('Updates are available!', updateData);
            this.updatesAvailable();
        });

    } else {

        console.log('Service worker is not enabled');

    }

  }

  /**
   * If we have focus of the app, check for updates
   */
  checkFocus() {
    clearTimeout(this.hasFocusTimeout);
    this.hasFocusTimeout = setTimeout(() => {
        this.hasFocus = document.hasFocus();
        if (this.hasFocus === true) {
            // console.log('Focus');
            if (this.updates.isEnabled) {
                // console.log('Checking for update');
                this.updates.checkForUpdate();
            }
            this.backgroundMode.disable();
        } else {
            // console.log('No focus');
            this.backgroundMode.setEnabled(true);
        }
    }, 250);
  }

  /**
   * Display updates available toast message
   */
  async updatesAvailable() {
    if (!this.updateAvailable) {
        this.updateAvailable = true;
        const toast = await this.toastController.create({
            mode: 'ios',
            cssClass: 'update-available',
            color: 'tertiary',
            header: 'App Update Available!',
            message: 'Please be sure to update your app for the latest features and fixes.',
            position: 'bottom',
            buttons: [
                {
                side: 'start',
                icon: 'refresh',
                text: '\xa0Update',
                cssClass: 'buttonz',
                  handler: () => {
                      this.updates.activateUpdate().then(() => window.location.reload());
                  }
                }
            ]
            });
        toast.present();
    }
  }

  /**
   * Offline Toast Notification
   *
   * @returns promise
   */
  async offline() {
    this.toast = await this.toastController.create({
      mode: 'ios',
      cssClass: 'network-unavailable',
      color: 'warning',
      header: 'Network Offline',
      message: 'You are currently offline. Please connect to a network to continue using the application.',
      position: 'top',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    return this.toast;
  }

  /**
   * Console Brand Logging
   */
  afAscii() {
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%@@');
    console.log('@@@@@@@@@@@@@@@@@@@#.-%@@@@@@@@@@@@@@@@=                 =@@');
    console.log('@@@@@@@@@@@@@@@@@@=    +@@@@@@@@@@@@@%.                .#@@@');
    console.log('@@@@@@@@@@@@@@@@#.      .*@@@@@@@@@@=                 =@@@@@');
    console.log('@@@@@@@@@@@@@@@=          :%@@@@@@#.                :%@@@@@@');
    console.log('@@@@@@@@@@@@@%.             =@@@@=          @@@@@@@@@@@@@@@@');
    console.log('@@@@@@@@@@@@+                -@#.           ++++++@@@@@@@@@@');
    console.log('@@@@@@@@@@#.         -.     +%-                 -%@@@@@@@@@@');
    console.log('@@@@@@@@@+          #@@=  .%*                  *@@@@@@@@@@@@');
    console.log('@@@@@@@%:         -%@@@@%+@-               -@@@@@@@@@@@@@@@@');
    console.log('@@@@@@+          :++++++*@-               .#@@@@@@@@@@@@@@@@');
    console.log('@@@@%:                   .#*.     =*     =@@@@@@@@@@@@@@@@@@');
    console.log('@@@*                       -%+ .+@@#   .#@@@@@@@@@@@@@@@@@@@');
    console.log('@@=..........................*@@@@@%  =@@@@@@@@@@@@@@@@@@@@@');
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%.#@@@@@@@@@@@@@@@@@@@@@@');
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
  }

  /**
   * Select Unit of Measurement
   *
   * @param event Radio Select Event
   */
   unitOfMeasurementSelect(event) {
    this.basicInfo.patchValue({
      unit_of_measurement: event.target.value
    });


    // Update Unit of Measurement
    this.user.updateUnitMeasurement(this.userProfile, event.target.value).then(() => {
      // console.log('Profile updated');
      this.user.fetchProfile(this.userProfile.id);
      // console.log(this.userProfile);
    });

  }

  /**
   * Ask For Support Link
   */
  askforSupport() {
    window.open(`${Env.marketing()}/support/?full_name=${this.userProfile?.first_name} ${this.userProfile?.last_name}&phone=${this.userProfile?.phone}&email=${this.userProfile?.email}`, '_blank');
  }

  /**
   * Ask For Delete Link
   */
   askforDelete() {
    window.open(`${Env.marketing()}/delete-account/?full_name=${this.userProfile?.first_name} ${this.userProfile?.last_name}&email=${this.userProfile?.email}`, '_blank');
  }

  askforReset() {
    // Are you sure?
    this.alertCtrl.create({
      header: 'Reset All Data?',
      message: 'Are you sure you want to delete all your progress?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Delete',
          handler: () => {
            this.resetData();
          }}
        ]}).then(alertEl => alertEl.present());
  }

  resetData() {
    this.user.resetUserData(this.userProfile).then((deleted: boolean) => {
      if (deleted) {
        this.deletedToast();
      }
    });
  }

  /**
   * Update Confirmation
   */
   async deletedToast() {
    const toast = await this.toastController.create({
      message: 'All data deleted!',
      duration: 2000,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Privacy Link
   */
   privacy() {
    window.open(`${Env.marketing()}/anyman-fitness-privacy-policy/`, '_blank');
  }

   /**
   * Privacy Link
   */
    terms() {
      window.open(`${Env.marketing()}/terms-of-use-eula/`, '_blank');
    }

  /**
   * Fetch List of Timezone List
   */
   async fetchTimezoneList() {
    return new Promise((resolve) => {
      this.timezoneListService.getTimezonesList().then((timezones) => {
        this.timezonesList = timezones;
        resolve(true);

      });
    });
  }

  timezoneChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    // console.log('timezone:', event.value.name);

    // console.log('user wp:', this.userProfile?.wp_user_id);

    this.timezoneListService.updateTimezone(this.userProfile?.wp_user_id, event.value.name).then(() => {
      this.addedToast();
      this.user.fetchProfile(this.userProfile.id);
    });

  }

  /**
   * Save Profile
   */
   async addedToast() {
    const toast = await this.toastController.create({
      message: 'Profile Updated',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Fetch Nickname type
   */
   async fetchNicknameType() {
    return new Promise((resolve) => {
      this.forumService.getNickname(this.userProfile?.wp_user_id).then((type) => {

        if(type) {
          this.nicknameType = type.toString();

          resolve(true);
        }

      });

    });
  }

  /**
   * Set Display name on change
   *
   * @param value Category object
   */
   changeNameType(event) {
    this.nicknameType = event.detail.value;
    this.forumService.updateNickname(this.userProfile.wp_user_id,this.nicknameType);
  }

  compareWithFn(o1, o2) {
    return o1 === o2;
  }

  openNotifications() {
    this.menuOverlay.updateOverlayOpen(false);
    this.menu.enable(true, 'notificationsOffcanvas');
    this.menu.open('notificationsOffcanvas');
  }

  closeNotifications() {
    this.menuOverlay.updateOverlayOpen(false);
    this.menu.close('notificationsOffcanvas');
  }

  // async sendLocalNotification(title: string, body: string) {
  //   //get input from the ion-datetime and convert it to ISO Format using date-fns

  //   // Creating Random id
  //   const randomId = Math.floor(Math.random() * 10000) + 1;
  //   await this.notifications.showLocalNotification ( randomId,title, body  ).then(r => {
  //     console.log('Notification Sent', r);
  //   });
  // }

  // Play the audio track
  playSingle() {
    NativeAudio.play({
      assetId: 'toastSound',
      time: 0
    });
  }

  // To open the app from browser
  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            // const slug = event.url.split(".app").pop();
            // if (slug) {
            //     this.router.navigateByUrl(slug);
            // }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    });
}

}
