/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Angular
import { Component, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthGuard } from 'src/app/_guards/auth.guard';
import { timeout } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

// Ionic
import { LoadingController, MenuController, ModalController, Platform } from '@ionic/angular';

// Services
import { UserService } from 'src/app/_services/user.service';
import { UploadService } from 'src/app/_services/upload.service';

// Pages
import { OnboardingPage } from 'src/app/member/onboarding/onboarding.page';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { MacrosPage } from 'src/app/member/macros/macros.page';
import { RenewPage } from 'src/app/member/renew/renew.page';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import { CompressedImageObject, ImageReponsePayload } from '../../_interfaces/UserImage.interface';

// Utils
import * as moment from 'moment-timezone';
import Compressor from 'compressorjs';

// Env
import Env from './../../env';
import { MeasurementsService } from 'src/app/_services/measurements.service';
import { ForumService } from 'src/app/_services/forum.service';

const PROD_LIFETIME = '18';
const PROD_YEARLY = '17';
const PROD_MONTHLY = '13';

@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent implements OnInit {

  userProfile: UserProfile;

  platformData: any;
  ios: any;

  products: IAPProduct[] = [];

  productOwned: boolean;

  croppedImage$ = new Subject<CompressedImageObject>();
  imageSubscription: Subscription;

  profileImagePath = Env.uploads() + '/profile';
  profileImage: string;

  constructor(
    public menu: MenuController,
    private auth: AuthGuard,
    private user: UserService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private uploadService: UploadService,
    private measurementsService: MeasurementsService,
    private forumService: ForumService,
    public platform: Platform,
    public deviceService: DeviceDetectorService,
    private store: InAppPurchase2,
    private ref: ChangeDetectorRef,
  ) {

    this.productOwned = false;

    this.platformData = JSON.stringify(this.deviceService.getDeviceInfo());

    const platformData = this.platform.platforms();
    this.ios = (platformData.filter(p => p === 'ios').length > 0) ? true : false;

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
      if (this.userProfile?.profile_image.file_name) {
        this.profileImage = `${this.profileImagePath}/${this.userProfile?.profile_image.file_name}`;
      }
    });

    // Subscribe to cropped image data and send it to the API
    this.imageSubscription = this.croppedImage$.subscribe((data) => {
      const formData = new FormData();
      formData.append('profile_image', data.compressed, 'image.jpg');
      this.uploadService.uploadProfile(this.userProfile, formData).subscribe(
          (res) => {
            console.log('response from croppedImage$', data);
            this.user.fetchProfile(this.userProfile.id).then(() => {
              this.loadingCtrl.dismiss();
            });
          },
          (err) => {
            console.log('upload error', err);
            // this.onUploadError(err, data.type)
          }
        );
      const imageReady = this.blobToFile(data.compressed, 'image.jpg');
      this.forumService.uploadProfileWP(this.userProfile.wp_user_id, imageReady);
    });

    platform.ready().then(() => {

      if(this.ios) {

        // this.store.verbosity = this.store.DEBUG;
        // this.registerProducts();

        // Get the real product information
        this.store?.ready(() => {

          console.log(this.store?.products);

          if(this.store?.products?.find(p => p.owned)) {
            this.productOwned = true;
          } else {
            this.productOwned = false;
          }

          this.ref.detectChanges();

        });



      }

     });

  }

  ngOnInit() {}

  /**
   * Onboarding
   *
   * @param firstTime Boolean for first time onboarding
   */
  async openOnboarding(firstTime: boolean = false) {
    this.modalCtrl.create({
      component: OnboardingPage,
      cssClass: 'anyman-modal dark half add-macros',
      componentProps: {
        userProfile: this.userProfile,
        firstTime
      },
      backdropDismiss: false
    }).then(summary => {
      summary.present().then(() => {
        summary.onDidDismiss().then(() => {
          this.user.fetchProfile(this.userProfile.id);
        });
      });
    });
  }

  /**
   * Open Renew to manage ios subscription
   *
   */
  async manageSubscription(){
    const modal = await this.modalCtrl.create({
      component: RenewPage,
      cssClass: 'anyman-modal dark renew',
      componentProps: {
        userProfile: this.userProfile,
      },
      backdropDismiss: false
    });
    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        this.user.fetchProfile(this.userProfile.id);
      });
    });
  }

  /**
   * Open Macros
   *
   */
   async openMacros() {
    this.modalCtrl.create({
      component: MacrosPage,
      cssClass: 'anyman-modal dark half onboarding',
      componentProps: {
        userProfile: this.userProfile
      },
      backdropDismiss: false
    }).then(summary => {
      summary.present().then(() => {
        summary.onDidDismiss().then(() => {
          this.user.fetchProfile(this.userProfile.id);
        });
      });
    });
  }

  myAccount() {
    window.open(`${Env.eCommerce()}/my-account`, '_blank');
  }

  forgotPassword() {
    window.open(`${Env.eCommerce()}/my-account/lost-password/`, '_blank');
  }

  askforSupport() {
    const age = moment.tz(this.userProfile?.timezone).diff(moment(this.userProfile.dob, 'YYYYMMDD'), 'years');
    const address = `${Env.marketing()}/ask-a-coach/?full_name=${this.userProfile?.first_name} ${this.userProfile?.last_name}&age=${age}&gender=${this.userProfile?.gender}&height=${this.userProfile?.height}&weight=0&email=${this.userProfile?.email}`;
    window.open(address, '_blank', );
  }

  /**
   * Refresh profile data
   *
   * @param event Refresh pulldown event
   */
  doRefresh(event) {
    this.user.fetchProfile(this.auth.userData.sub.user_id).then(() => {
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    });
  }

  /**
   * Upload Image Input
   *
   * @param $event Click Event
   */
  uploadImageInput($event) {
    document.getElementById('profile-photo').click();
  }

  /**
   * Input change event for photo uploads
   *
   * @param event Event data
   * @param formControl Form control name
   */
   fileChangeEvent(event: any, formControl: string): void {
    if (
      !event ||
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      return;
    }
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);
    const image = new Image();
    image.src = URL.createObjectURL(event.target.files[0]);
    this.openCropper(image.src, formControl, ext);
  }

  /**
   * Open image cropper
   */
  async openCropper(img: any, cropperRole: string, fileType: string) {
    const modal = await this.modalCtrl.create({
      component: ImageModalPage,
      cssClass: 'anyman-modal dark cropper-modal',
      componentProps: {
        img,
        cropMode: true,
        fileType,
      },
    });
    modal.onDidDismiss().then((croppedImage) => {
      const base64img = croppedImage.data.dismissvalue;
      const convert = this.convertBase64ToBlob(base64img);
      const compressed$ = this.compressImage(convert);
      compressed$.subscribe((compressedBlob) => {
        const data = new CompressedImageObject(cropperRole, compressedBlob);
        this.croppedImage$.next(data);
      });
    });
    return await modal.present();
  }

  /**
   * Convert Base 64 encoded image to blob
   *
   * @param base64Image Base 64 string
   */
  private convertBase64ToBlob(base64Image: any) {
      // Split into two parts
      const parts = base64Image.split(';base64,');

      // Hold the content type
      const imageType = parts[0].split(':')[1];

      // Decode Base64 string
      const decodedData = window.atob(parts[1]);

      // Create UNIT8ARRAY of size same as row data length
      const uInt8Array = new Uint8Array(decodedData.length);

      // Insert all character code into uInt8Array
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      // Return BLOB image after conversion
      return new Blob([uInt8Array], { type: imageType });
    }

  /**
   * Compress image
   */
   private compressImage(file: Blob): Observable<Blob> {
    const emitter = new EventEmitter<Blob>();
    const compressor = new Compressor(file, {
      quality: 0.70,
      maxWidth: 500,
      maxHeight: 500,
      success(blobResult) {
        emitter.emit(blobResult);
      },
      error(err) {
        console.log(err.message);
      },
    });
    return emitter.asObservable();
  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

}
