// Angular
import { Injectable } from '@angular/core';

// Ionic
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ResponderService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  error(err: any, errHeader: string, errMessage: string) {
    switch (err?.status) {
      case 422:
        this.alertCtrl.create({
          header: 'Fields Required',
          message: 'Please complete all required fields.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
        break;
      default:
        this.alertCtrl.create({
          header: errHeader,
          message: errMessage,
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
        break;
    }
  }

}
