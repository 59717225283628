// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Ionic
import { AlertController } from '@ionic/angular';

// Interfaces
import { Goals, GoalsResponse } from '../_interfaces/Goals.interface';

// Env
import Env from './../env';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController
  ) { }

  /**
   * Fetch Goals
   *
   * @returns promise
   */
   async fetchGoals() {
    return new Promise<Goals[]>((resolve) => {
      this.http.get(`${Env.api()}/goals/find`).subscribe((response: GoalsResponse) => {
        resolve(response.data.goals);
      }, err => {
        this.alertCtrl.create({
          header: 'Error Fetching Goals',
          message: 'There was an error fetching goals. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });
    });
  }

}
