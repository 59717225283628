import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes'
})
export class SecondsToMinutesPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value)  {
      value = Number(value) / 60;
      return value.toFixed(2);
    } else {
      return null;
    }
  }

}
