import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any, format: any = 'LT', timezone?: any): any {
    if (value && value !== undefined) {
      const testDateUtc = value;
      if (timezone) {
        if(moment().isSame(testDateUtc)) {
          return moment(testDateUtc).startOf('hour').tz(timezone).format(format);
        } else {
          return moment(testDateUtc).startOf('day').tz(timezone).format(format);
        }
      } else {
        if(moment(testDateUtc).isSame()) {
          return moment(testDateUtc).local().format(format);
        } else {
          return moment(testDateUtc).local().format(format);
        }
      }

    }
  }

}
