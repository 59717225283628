/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

// Interfaces
import { OrderData } from '../_interfaces/WordPressPosts.interface';

// Environment
import Env from './../env';

@Injectable({
  providedIn: 'root'
})
export class WoocommerceService {

  auth_token: string = Env.wordpressAccessToken();

  headerDict: any;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController
  ) {

    this.headerDict = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.auth_token
    }

  }

  /**
   * Create order
   *
   * @param order Comment form values
   * @returns promise
   */
   async createOrder(order: OrderData) {
    return new Promise((resolve, reject) => {

      const postjson = JSON.stringify(order);

      // console.log(headerDict);

      const requestOptions = { headers: new HttpHeaders(this.headerDict) };

      if (!order) {
        reject('No order provided');
      }

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v2/add_order`, postjson, requestOptions).subscribe(() => {
        resolve(true);
      }, err => {
        console.log(err);
          this.alertCtrl.create({
            header: 'Error Add Order',
            message: 'There was an error adding the order. Please try again or contact support.',
            buttons: [{
              text: 'Dismiss'
            }],
            backdropDismiss: false
          }).then(alertEl => alertEl.present());
      });

    });
  }

}
