/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

// Ionic
import { Storage } from '@ionic/storage';

// Services
import { ExerciseSubService } from 'src/app/_services/exercise-sub.service';
import { ExerciseService } from 'src/app/_services/exercise.service';

// Interfaces
import Exercise from 'src/app/_interfaces/Exercise.interface';
import { UserExercise } from 'src/app/_interfaces/UserExercise.interface';
import { ExerciseSub } from 'src/app/_interfaces/ExerciseSubs.interface';
import { UserService } from 'src/app/_services/user.service';
import { UserProfile } from 'src/app/_interfaces/UserData.interface';

@Component({
  selector: 'app-exercise-sub',
  templateUrl: './exercise-sub.page.html',
  styleUrls: ['./exercise-sub.page.scss'],
})
export class ExerciseSubPage {

  exercises: Exercise[];

  exercise: UserExercise;
  exerciseSubs: ExerciseSub[];

  searchString: string;

  userId: number;

  constructor(
    public user: UserService,
    private exerciseSubService: ExerciseSubService,
    private exerciseService: ExerciseService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
  ) {
    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userId = userProfile.id;
    });
  }

  ionViewDidEnter() {
    this.exerciseSubService.findByPrimaryExercise(this.exercise).then(res => {

      this.exerciseSubs = res;

      console.log('exercise-sub', res);

    });
  }


  async confirmSub(sub: ExerciseSub) {

    const substitute = sub;

    // Are you sure?
    this.alertCtrl.create({
      header: 'Confirm Substitution',
      message: 'Are you sure you would like to substitute this exercise for another?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Yes',
          handler: () => {

            // Send sub for specific exercise through service observable
            this.exerciseSubService.selectedSub(substitute);

            setTimeout(() => {
              this.modalCtrl.dismiss();
            }, 500);

          }}
        ]}).then(alertEl => alertEl.present());

  }

  async confirmPermSub(substitute: ExerciseSub) {

    const payload = {
      user_id: this.userId,
      exercise_id: this.exercise.id,
      substitute_id: substitute.sub_exercise
    };

    // Are you sure?
    this.alertCtrl.create({
      header: 'Confirm Permanent Substitution',
      message: 'Are you sure you would like to substitute permanently this exercise for another?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Yes',
          handler: () => {

            // Send sub for specific exercise through service observable
            this.exerciseSubService.create(payload).then((permId: number) => {
              this.exerciseSubService.selectedPermSub(substitute,permId);
            });

            setTimeout(() => {
              this.modalCtrl.dismiss();
            }, 500);

          }}
        ]}).then(alertEl => alertEl.present());
  }

  /**
   * Search Posts
   */
   search(e) {

    const search = e.target.value;
    this.exerciseService.filterList(search).then((exercises: Exercise[]) => {

      this.exerciseSubs = [];

      this.searchString = search;
      console.log('filterList exercises', exercises);
      this.exercises = exercises;

      this.exercises.forEach(ex => {

        let id = 10000 + ex.id;

        this.exerciseSubs.push({
          exercise_sub_detail: ex,
          id: id,
          note: null,
          primary_exercise: this.exercise.id,
          sub_exercise: ex.id,
        });

      });

      console.log('new exerciseSubs: ', this.exerciseSubs);


    });
  }

}
