import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(value: string, user_id: number): any {

    const userId = user_id + ',';

    if(value?.includes(userId)) {
      return true;
    } else {
      return false;
    }

  }

}
