/* eslint-disable @typescript-eslint/naming-convention */
export class CompressedImageObject {
	constructor(
		public type: string,
		public compressed: Blob,
		public checkin_id?: any) {}
}

export class UserImageReponsePayload {
	image_id: string;
	image_path: string;
	upload: string;
}
export class ImageReponsePayload {
  user: UserImageReponsePayload;
  error?: string;
}
