/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
// Angular
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { KeepAwake } from '@capacitor-community/keep-awake';

// Services
import { UserSessionService } from 'src/app/_services/user-session.service';
import { ExerciseSubService } from 'src/app/_services/exercise-sub.service';
import { ExerciseService } from 'src/app/_services/exercise.service';
import { TimerService } from 'src/app/_services/timer.service';

// Pages
import { ExerciseBeginPage } from '../exercise-begin/exercise-begin.page';
import { ExerciseEditPage } from '../exercise-edit/exercise-edit.page';
import { ExerciseSummaryPage } from '../exercise-summary/exercise-summary.page';
import { ExerciseSubPage } from '../exercise-sub/exercise-sub.page';

// Interfaces
import Program from 'src/app/_interfaces/Program.interface';
import Session from 'src/app/_interfaces/Session.interface';
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import { NewSessionResponse, UserSession } from 'src/app/_interfaces/UserSession.interface';
import { UserWorkout, UserWorkoutRecord } from 'src/app/_interfaces/UserWorkout.interface';
import { UserExercise } from 'src/app/_interfaces/UserExercise.interface';
import { ExercisePermSub, ExerciseSub } from 'src/app/_interfaces/ExerciseSubs.interface';
import Exercise from 'src/app/_interfaces/Exercise.interface';
import { UserProgram } from 'src/app/_interfaces/UserPrograms.interface';
import { SessionCompletePage } from '../session-complete/session-complete.page';

export interface Substitutes {
  original_exercise: any;
  replaced_with: any;
}


@Component({
  selector: 'app-session-overview',
  templateUrl: './session-overview.page.html',
  styleUrls: ['./session-overview.page.scss'],
})
export class SessionOverviewPage implements OnInit {

  @Input() userProfile: UserProfile;
  @Input() userCurrentProgram: UserProgram;
  @Input() userCurrentProgramDetails: Program;
  @Input() todaysSession: Session;
  @Input() resumeSession: UserSession;

  userSession: UserSession;
  userWorkouts: UserWorkoutRecord[];
  userWorkoutsLoaded: boolean;

  exerciseSubSelection: ExerciseSub;
  exerciseSub: any;

  completedSets: any = 0;

  substitutes: Substitutes[] = [];

  getTimer: any;

  constructor(
    public modalCtrl: ModalController,
    private userSessionService: UserSessionService,
    private alertCtrl: AlertController,
    private exerciseService: ExerciseService,
    private exerciseSubService: ExerciseSubService,
    public timer: TimerService
  ) {

    this.userWorkoutsLoaded = false;

    this.userSessionService.userSession.subscribe(data => {
      if (data) {
        this.userSession = data;

        // Search for workouts to determine progress
        this.finderUserWorkoutsBySession().then(resolved => {
          if (resolved) {
            this.userWorkoutsLoaded = true;
            this.findPermSub();
          }
        });

      }
    });

    // Listen for substitutes
    this.exerciseSubService.exerciseSub.subscribe(sub => {
      this.exerciseSubSelection = sub;
      if (sub.sub_exercise) {
        this.exerciseService.fetchByID(sub.sub_exercise).then(ex => {
          this.exerciseSub = ex;
          this.todaysSession.workouts.forEach(wo => {
            wo.exercises.map(x => {
              if (x.exercise_id === this.exerciseSubSelection.primary_exercise) {
                const original = x;
                const replacement = this.exerciseSub;
                this.substitutes.push({
                  original_exercise: JSON.parse(JSON.stringify(original)),
                  replaced_with: JSON.parse(JSON.stringify(replacement))
                });
                x.exercise_id = replacement.id;
                x.exercise = replacement;
                x.sub = true;
                x.notes = sub.note;
              }
            });
          });
          this.findPermSub();
        });
      }
    });

  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    if(!this.resumeSession) {
      this.begin();
    } else {
      this.resume();
    }
    this.keepAwake();
  }

  dismiss() {
    // this.getTimer = this.timer.getCountdown();
    // this.timer.nextTimer(this.getTimer);
    // console.log(this.getTimer);
    this.allowSleep();
    this.modalCtrl.dismiss();
  }

  async begin() {

    console.log('userCurrentProgram', this.userCurrentProgram);
    console.log('userCurrentProgramDetails', this.userCurrentProgramDetails);
    console.log('todaysSession', this.todaysSession);

    // Create a new session
    this.userSessionService.createUserSession(this.userProfile.id, this.userCurrentProgram.id, this.todaysSession.id).then((newSession: NewSessionResponse) => {
      this.userSessionService.findUserSessionByID(newSession.data.id);
    });

  }

  async resume() {

    console.log('userCurrentProgram', this.userCurrentProgram);
    console.log('userCurrentProgramDetails', this.userCurrentProgramDetails);
    console.log('todaysSession', this.todaysSession);
    console.log('resumeSession', this.resumeSession);

    // Resume latest incomplete session
    this.userSessionService.findUserSessionByID(this.resumeSession.id).then((newSession: NewSessionResponse) => {
      console.log(newSession);
      this.findPermSub();
      // this.userSessionService.findUserSessionByID(newSession.data.id);
    });

  }

  /**
   * Check if session is available
   */
  public async findUserSession() {
    this.userSessionService.findUserSessionByID(this.userSession.id);
  }

  /**
   * Find user workouts by today's session ID
   */
  async finderUserWorkoutsBySession() {
    return new Promise((resolve) => {

      this.userWorkouts = this.userSession.user_session_workouts;

      this.findCompletedSets().then(resolved => {
        if (resolved) {
          resolve(true);
        }
      });

    });
  }

  /**
   * Determine if exercise has been completed by sets completed
   */
  async findCompletedSets() {
    return new Promise((resolve) => {
      this.todaysSession?.workouts?.forEach(workout => {
        const setsToComplete = workout.sets;
        workout.exercises.forEach(exercise => {
          const completedSets = this.userWorkouts.filter(x => (x.workout_id === workout.id) && (x.datetime_completed !== null)).length;
          this.completedSets += completedSets;
          // console.log('completedSets', completedSets);
          if (completedSets >= setsToComplete) {
            exercise.completed = true;
          }
        });
      });
      resolve(true);
    });
  }

  /**
   * Confirm Complete session
   */
  completeSessionConfirm() {

    // If the session has not yet started
    if (!this.userSession.started) {

      // Are you sure?
      this.alertCtrl.create({
        header: 'Finish an incomplete session?',
        message: 'We’re sure you\'re aware of this, but your session is still incomplete. Finish session anyway?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              // ...
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.completeSession();
            }}
          ]}).then(alertEl => alertEl.present());

    } else {

      // Are you sure?
      this.alertCtrl.create({
        header: 'Finish this session?',
        message: 'Looks like you\'re all set! Please confirm that you would like to complete this session.',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              // ...
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.completeSession();
            }}
          ]}).then(alertEl => alertEl.present());

    }

  }

  /**
   * Start the user session
   */
  async startSession() {
    if (this.userSession.started) {
      console.warn('Session already started', this.userSession.started);
      return;
    }
    this.userSessionService.startSession(this.userSession).then((started: boolean) => {
      if (started) {
        console.log('user session started!');
      }
    });
  }

  /**
   * Exercise Modal
   *
   * @returns modal instance
   */
  async beginWorkout(workout?: UserWorkout, exerciseID?: number) {

    console.log('beginWorkout exerciseID', exerciseID);
    // console.log('UserSession: ', this.userSession);

    this.startSession();

    if (!workout && !exerciseID) {
      workout = this.todaysSession.workouts[0];
      exerciseID = workout.exercises[0].exercise_id;
    }
    const modal = await this.modalCtrl.create({
      component: ExerciseBeginPage,
      cssClass: 'anyman-modal dark half',
      componentProps: {
        userProfile: this.userProfile,
        userCurrentProgramDetails: this.userCurrentProgramDetails,
        userSession: this.userSession,
        workout,
        exerciseID,
        superset: (workout.exercises.length > 1) ? true : false
      }
    });

    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        // this.getTimer = this.timer.getCountdown();
        // console.log(this.getTimer);
        this.findUserSession();
      });
    });

  }

  /**
   * Edit Exercise Modal
   *
   * @returns modal instance
   */
   async editWorkout(workout?: UserWorkout, exerciseID?: number, setN?: number) {

    console.log('editWorkout exerciseID', exerciseID);

    this.startSession();

    if (!workout && !exerciseID) {
      workout = this.todaysSession.workouts[0];
      exerciseID = workout.exercises[0].exercise_id;
    }

    const modal = await this.modalCtrl.create({
      component: ExerciseEditPage,
      cssClass: 'anyman-modal dark half',
      componentProps: {
        userProfile: this.userProfile,
        userSession: this.userSession,
        workout,
        exerciseID,
        setN,
        superset: (workout.exercises.length > 1) ? true : false
      }
    });

    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        this.findUserSession();
      });
    });

  }

  /**
   * Exercise Modal: Next Workout
   *
   * @returns modal instance
   */
  async nextWorkout() {

    let workout;
    let exerciseID;

    this.todaysSession.workouts.forEach(wo => {
      wo.exercises.forEach(ex => {
        if (!ex.completed && !workout && !exerciseID) {
          workout = wo;
          exerciseID = ex.exercise_id;
        }
      });
    });

    const modal = await this.modalCtrl.create({
      component: ExerciseBeginPage,
      cssClass: 'anyman-modal dark half',
      componentProps: {
        userProfile: this.userProfile,
        userCurrentProgramDetails: this.userCurrentProgramDetails,
        userSession: this.userSession,
        workout,
        exerciseID,
        superset: (workout.exercises.length > 1) ? true : false
      }
    });

    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        // this.getTimer = this.timer.getCountdown();
        // console.log(this.getTimer);
        this.findUserSession();
      });
    });

  }

  /**
   * Complete the user session
   */
   async completeSession() {
    this.userSessionService.completeSession(this.userSession).then((completed: boolean) => {
      if (completed) {
        this.findUserSession().then(() => {
          this.modalCtrl.create({
            component: ExerciseSummaryPage,
            cssClass: 'anyman-modal dark half',
            componentProps: {
              userProfile: this.userProfile,
              userCurrentProgramDetails: this.userCurrentProgramDetails,
              userSession: this.userSession
            }
          }).then(summary => {
            summary.present();
            summary.onDidDismiss().then(() => {

              setTimeout(() => {

                this.modalCtrl.dismiss();
                // Show success modal

                this.modalCtrl.create({
                  component: SessionCompletePage,
                  cssClass: 'anyman-modal dark timer',
                }).then(sessionCompleteModal => {
                  sessionCompleteModal.present();
                });

              }, 150);


            });
          });
        });
      }
    });
  }

  /**
   * Confirm Substitute
   *
   * @param event Click event
   * @param exercise Exercise Object
   */
  async confirmSub(event, exercise: UserExercise) {

    // Are you sure?
    this.alertCtrl.create({
      header: 'Confirm Substitution',
      message: 'Are you sure you would like to substitute this exercise for another?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Yes',
          handler: () => {

            this.getSubs(exercise);

          }}
        ]}).then(alertEl => alertEl.present());

  }

  /**
   * Session Begin
   *
   * @returns modal instance
   */
  async getSubs(exercise: UserExercise) {
    const modal = await this.modalCtrl.create({
      component: ExerciseSubPage,
      cssClass: 'anyman-modal dark half subs',
      componentProps: {
        userProfile: this.userProfile,
        exercise,
      }
    });
    return await modal.present().then(() => {
      modal.onDidDismiss().then(() => {
        this.findPermSub();
      });
    });
  }

  /**
   * Revert Sub to Original
   *
   * @param event Click event
   * @param exercise Exercise Object
   */
  async revertSub(event, exercise: UserExercise) {

    // Are you sure?
    this.alertCtrl.create({
      header: 'Revert Substitution',
      message: 'Are you sure you would like to revert the substitute to the original exercise?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.revertSubToOriginal(exercise);
          }}
        ]}).then(alertEl => alertEl.present());

  }

  /**
   * Revert Sub to Original
   *
   * @param exercise Exercise Object
   */
  async revertSubToOriginal(exercise: UserExercise) {
    this.todaysSession.workouts.forEach(wo => {
      wo.exercises.map(x => {
        if (x.exercise_id === exercise.id) {
          this.substitutes.forEach((s, i) => {
            if (s.replaced_with.id === exercise.id) {
              x.exercise_id = s.original_exercise.exercise.id;
              x.exercise = s.original_exercise.exercise;
              x.sub = false;
              x.notes = s.original_exercise.notes;
              delete this.substitutes[i];
            }
          });
        }
      });
    });
  }

  async findPermSub() {
    this.exerciseSubService.findPermSubs(this.userProfile.id).then((permSubs: ExercisePermSub[]) => {
      // console.log(permSubs);
      if(permSubs?.length > 0) {
        const arrayPermsubsIDs = [];
        permSubs.forEach(sub => {
          this.todaysSession.workouts.forEach(wo => {
            const exs = wo.exercises;
            exs.forEach(ex => {
              if(ex.exercise_id === sub.exercise_id) {
                arrayPermsubsIDs.push(sub);
              }
            });
          });
        });
        // console.log(arrayPermsubsIDs);
        if(arrayPermsubsIDs.length > 0) {
          arrayPermsubsIDs.forEach(sub => {
            this.exerciseService.fetchByID(sub.substitute_id).then(ex => {
              this.exerciseSub = ex;
              console.log('found perm subs');
              this.todaysSession.workouts.forEach(wo => {
                wo.exercises.map(x => {
                  if (x.exercise_id === sub.exercise_id) {
                    const original = x;
                    const replacement = this.exerciseSub;
                    this.substitutes.push({
                      original_exercise: JSON.parse(JSON.stringify(original)),
                      replaced_with: JSON.parse(JSON.stringify(replacement))
                    });
                    x.exercise_id = replacement.id;
                    x.exercise = replacement;
                    x.sub = true;
                    x.permSub = true;
                    x.permId = sub.id;
                    x.notes = sub.note;
                  }
                });
              });
            });
          });
        }
      }
    });
  }

  async revertPermSub(event, exercise: UserExercise, permId: number) {
    // Are you sure?
    this.alertCtrl.create({
      header: 'Revert Permanent Substitution',
      message: 'Are you sure you would like to revert the permanent substitute to the original exercise?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            // ...
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.revertPermSubToOriginal(exercise,permId);
          }}
        ]}).then(alertEl => alertEl.present());
  }

  /**
   * Revert Sub to Original
   *
   * @param exercise Exercise Object
   */
  async revertPermSubToOriginal(exercise: UserExercise, permId: number) {
    this.exerciseSubService.delete(permId).then((data) => {
      if(data) {
        this.todaysSession.workouts.forEach(wo => {
          wo.exercises.map(x => {
            console.log(x);
            if (x.exercise_id === exercise.id) {
              this.substitutes.forEach((s, i) => {
                console.log(s);
                if (s.replaced_with.id === exercise.id) {
                  x.exercise_id = s.original_exercise.exercise.id;
                  x.exercise = s.original_exercise.exercise;
                  x.sub = false;
                  x.permSub = false;
                  x.permId = null;
                  x.notes = s.original_exercise.notes;
                  delete this.substitutes[i];
                }
              });
            }
          });
        });
      }
    });
  }

  // Keep Phone Awake
  public async keepAwake(): Promise<void> {
    await KeepAwake.keepAwake();
  }

  // Allow Phone to Sleep
  public async allowSleep(): Promise<void> {
    await KeepAwake.allowSleep();
  }

}
