/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import Program from 'src/app/_interfaces/Program.interface';
import { UserSession } from 'src/app/_interfaces/UserSession.interface';
import { UserWorkout, UserWorkoutRecord } from 'src/app/_interfaces/UserWorkout.interface';
import { ExerciseDetail, SessionLength } from 'src/app/_interfaces/ExerciseDetail.interface';

// Pages
import { ExerciseEditPage } from '../exercise-edit/exercise-edit.page';

// Services
import { UserSessionService } from 'src/app/_services/user-session.service';

// Utils
import Utils from './../../utils';
import * as moment from 'moment';

@Component({
  selector: 'app-exercise-summary',
  templateUrl: './exercise-summary.page.html',
  styleUrls: ['./exercise-summary.page.scss'],
})
export class ExerciseSummaryPage implements OnInit {

  @Input() userProfile: UserProfile;
  @Input() userCurrentProgramDetails: Program;

  userSession: UserSession;

  sessionData: UserWorkoutRecord[];
  sessionLength: SessionLength;
  sessionSummary: ExerciseDetail[] = [];
  userCurrentFavoriteSessions: UserSession[];
  unit_of_measurement: string;

  isFavorite: number;
  session_id: number;
  session_name: string;
  user_to_program_id: number;

  constructor(
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private userSessionService: UserSessionService,
  ) {
    this.isFavorite = 0;
   }

  ngOnInit() {
    // Assign unit of measurement, default to imperial
    this.unit_of_measurement = (this.userProfile.unit_of_measurement) ? this.userProfile.unit_of_measurement : 'imperial';
  }

  ionViewDidEnter(refresh?: boolean) {
    this.userSessionService.findUserSessionByID(this.userSession.id).then((res: UserSession) => {
      this.userSession = res;
      this.userSession.completed = res.completed;
      this.sessionData = res.user_session_workouts;
      this.session_id = res.session_id;
      this.session_name = res.session_name;
      this.user_to_program_id = res.user_to_program_id;
      this.fetchUserFavoriteSession();
      console.log(this.sessionData);
      this.prepareSessionSummary(refresh).then(sessionSummary => {
        this.calculateSessionLength();
      });
    });
  }

  /**
   * Build summary based on the user session data available
   *
   * @returns promise
   */
   async prepareSessionSummary(refresh?: boolean) {

    return new Promise((resolve) => {

      if(refresh) {
        this.sessionSummary = [];
      }

      this.userSession.user_session_workouts.forEach(workout => {
        const exists = this.sessionSummary.filter(ssn => ssn.id === workout.workout.id);
        if (!exists.length) {
          const superset = (workout?.workout?.exercises.length > 1) ? true : false;
          this.sessionSummary.push({
            id: workout.workout.id,
            name: workout.workout.name,
            sets: [],
            exercises: [],
            workout_data: workout?.workout,
            exercise_id: workout?.workout_exercises[0]?.exercise_id,
            superset
          });
        }
      });

      this.sessionSummary.forEach(ssn => {
        this.userSession.user_session_workouts.forEach((workout, index) => {
            if (workout.workout_id === ssn.id) {
              workout.workout_exercises = workout.workout_exercises.sort(Utils.fieldSorter(['position']));
              workout.workout_exercises.forEach(wx => {
                if (!ssn.superset) {
                  ssn.sets.push({
                    set: workout.set_n,
                    weight: wx.weight,
                    reps: wx.reps,
                    time: wx.time,
                    notes: wx.notes,
                    name: wx.exercise_name,
                    sub: wx.exercise_substituted
                  });
                } else {
                  ssn.exercises.push({
                    exerciseName: wx.exercise_name,
                    exercise_id: wx.exercise_id,
                    set: workout.set_n,
                    weight: wx.weight,
                    reps: wx.reps,
                    time: wx.time,
                    notes: wx.notes,
                    sub: wx.exercise_substituted,
                    orderSuperset: index
                  });
                }
              });
            }
          });
      });

      resolve(true);

    });

  }

  /**
   * Calculate session length based on `started` and `completed`
   */
  calculateSessionLength() {

    if (this.userSession.started && this.userSession.completed) {

      const date_distance: SessionLength = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };

      // Calculate distance
      const a = moment(this.userSession.completed);
      const b = (this.userSession.started) ? moment(this.userSession.started) : moment();

      date_distance.days = a.diff(b, 'days');
      date_distance.hours = a.diff(b, 'hours');
      date_distance.minutes = a.diff(b, 'minutes');
      date_distance.seconds = a.diff(b, 'seconds');

      const sec = date_distance.seconds - (date_distance.days * 24 * 60 * 60 + date_distance.hours * 60 * 60);

      date_distance.seconds = (sec > 0) ? sec : 0;
      date_distance.hours = date_distance.hours - (date_distance.days * 24);
      date_distance.minutes = date_distance.minutes - (date_distance.days * 24 * 60 + date_distance.hours * 60);

      this.sessionLength = date_distance;

    } else {
      return null;
    }

  }

  saveAsFavorite() {
    this.isFavorite = 1;
    this.updateSession();
  }

  removeAsFavorite() {
    this.isFavorite = 0;
    this.updateSession();
  }

  saveAndFinish() {
    this.modalCtrl.dismiss();
  }

  fetchUserFavoriteSession() {
    this.userSessionService.findUserFavoriteSessionsByUserID(this.userProfile.id).then(userSessions => {
      const rawFavorite = userSessions.filter(ussn => ussn.favorited = 1);
      // this.userCurrentFavoriteSessions = [...new Map(rawFavorite.map((item) => [item.session_name, item])).values()];
      console.log(rawFavorite);
      console.log(this.userCurrentFavoriteSessions);
      console.log(this.session_name);
      if(rawFavorite) {
        const narrowFavorite = rawFavorite.filter(ussn => ussn.session_name === this.session_name)[0];
        if(narrowFavorite) {
          this.isFavorite = 1;
        }
      }
    });
  }

  /**
   * Deleted the user session
   */
   async updateSession() {
    this.userSessionService.updateFavoriteSession(this.userSession, this.isFavorite).then((updated: boolean) => {
      if (updated) {

        this.alertCtrl.create({
          header: 'Session Updated',
          message: 'This session has been updated',
          buttons: [
            {
              text: 'Dismiss',
              handler: () => {
                this.alertCtrl.dismiss();
              }}
            ]}).then(alertEl => {

            });
      }
    });
  }

  /**
   * Fetch new session data
   */
  fetchSession() {
    this.userSessionService.findUserSessionByID(this.userSession.id).then((res: UserSession) => {
      this.userSession.completed = res.completed;
      this.sessionData = res.user_session_workouts;
      console.log(this.sessionData);
    });
  }

   /**
   * Edit Exercise Modal
   *
   * @returns modal instance
   */
    async editWorkout(workout?: UserWorkout, exerciseID?: number, setN?: number) {

      console.log('editWorkout workout', workout);
      console.log('editWorkout exerciseID', exerciseID);

      const modal = await this.modalCtrl.create({
        component: ExerciseEditPage,
        cssClass: 'anyman-modal dark half',
        componentProps: {
          userProfile: this.userProfile,
          userSession: this.userSession,
          workout,
          exerciseID,
          setN,
          superset: (workout.exercises.length > 1) ? true : false
        }
      });

      return await modal.present().then(() => {
        modal.onDidDismiss().then(() => {
          this.ionViewDidEnter(true);
        });
      });

    }

  toKg (pound: any) {

    let pound_input = pound;

    let kg: any;

    kg = Number(Utils.toKilograms(pound_input, false)).toFixed(1);

    return kg;

  }

}
