// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Interfaces
import { Measurement, MeasurementForm, MeasurementList } from '../_interfaces/Measurements.interface';
import { UserProfile } from '../_interfaces/UserData.interface';

// Services
import { ResponderService } from './responder.service';

// Env
import Env from './../env';

// Utils
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  /**
   * Add
   *
   * @param measurements Measurement form values
   * @returns promise
   */
  async add(measurements: MeasurementForm) {
    return new Promise((resolve, reject) => {

      if (!measurements) {
        reject('No measurements provided');
      }

      console.log(measurements);

      this.http.post(`${Env.api()}/measurements/create`, measurements).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Adding Measurements',
          'There was an error adding measurements. Please try again or contact support.');
      });

    });
  }

  /**
   * Add
   *
   * @param measurements Measurement form values
   * @returns promise
   */
   async update(measurement: Measurement, measurements: MeasurementForm) {
    return new Promise((resolve, reject) => {

      if (!measurements) {
        reject('No measurements provided');
      }

      this.http.post(`${Env.api()}/measurements/update/${measurement.id}`, measurements).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Updating Measurements',
          'There was an error updating measurements. Please try again or contact support.');
      });

    });
  }

  /**
   * Soft Delete
   *
   * @param measurement Measurement object
   * @returns promise
   */
   async delete(measurement: Measurement) {
    return new Promise((resolve, reject) => {

      if (!measurement) {
        reject('No measurement provided');
      }

      this.http.post(`${Env.api()}/measurements/delete/${measurement.id}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Deleting Measurements',
          'There was an error deleting measurements. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch All Measurements
   *
   * @param user User Profile
   * @param date Option Date Filter (date_index)
   * @returns promise
   */
  async fetchAll(user: UserProfile, date?: string) {
    return new Promise<Measurement[]>((resolve, reject) => {

      if (!user) {
        reject('No user provided');
      }

      this.http.get(`${Env.api()}/measurements/user/${user.id}`).subscribe((measurements: MeasurementList) => {
        if (!date) {

          // Sort by Date Index DESC
          measurements.data.measurements.sort((a,b)=> (a.date_index < b.date_index) ? 1 : -1);

          resolve(measurements.data.measurements);
        } else {
          measurements.data.measurements = measurements.data.measurements.filter(measurement => measurement.date_index === date);
          resolve(measurements.data.measurements);
        }
      }, err => {
        this.responder.error(err,
          'Error Fetching Measurements',
          'There was an error fetching measurements. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch a user's latest weight
   *
   * @param user User profile object
   * @returns Promise<number>
   */
  async latestWeight(user: UserProfile) {
    return new Promise<string>((resolve, reject) => {

      this.fetchAll(user).then((measurements: Measurement[]) => {
        if (measurements.length) {

          // Sort: Descending order, newest at the top
          const sortedMeasurements = measurements.sort((ma, mb)=> (ma.date_index < mb.date_index ? 1 : -1));

          // Add those with a weight to an array
          const weights = [];
          sortedMeasurements.forEach(m => {
            if (m.weight) {
              weights.push(m);
            }
          });

          const latest = weights[0];
          const latestWeight = String(latest.weight);

          resolve(latestWeight);

        } else {
          resolve(String(0));
        }

      });
    });
  }

}
