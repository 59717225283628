import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { LocalNotifications } from '@capacitor/local-notifications';

// Interfaces
import { DeviceListData, NotificationsList, Notification, SendNotificationsListData, DeviceProfile, NotificationBody } from '../_interfaces/Notification.interface';

// Environment
import Env from './../env';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // Notification Observable
  private notificationsSource = new BehaviorSubject<Notification[]>(null);
  notificationsList = this.notificationsSource.asObservable();

  private notificationsUnreadedSource = new BehaviorSubject<Notification[]>(null);
  notificationsUnreadedList = this.notificationsUnreadedSource.asObservable();

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController
  ) { }

  /**
   * Fetch Device Token
   *
   * @param user_id User ID number
   * @returns promise
   */
   fetchDevice(user_id: number): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(`${Env.api()}/device/find/${user_id}?user_role=2`).subscribe((response: { message: number; data: DeviceListData }) => {
        const device = response.data.device.filter( d => d.is_active === true )[0];
        if(device) {
          resolve(device);
        }
      });
    });
  }

  /**
   * Fetch Notifications
   *
   * @param user_id User ID number
   * @param page Page number
   * @returns promise
   */
   async getNotifications(user_id: number, page = 1) {
      return new Promise<Notification[]>((resolve) => {

        if(page) {

          this.http.get(`${Env.api()}/notification/find/${user_id}?page=${page}`).subscribe((response: { message: number; data: NotificationsList}) => {
            this.notificationsSource.next(response.data.notification);
            resolve(response.data.notification);
          });

        } else {

          this.http.get(`${Env.api()}/notification/find/${user_id}`).subscribe((response: { message: number; data: NotificationsList}) => {
            this.notificationsSource.next(response.data.notification);
            resolve(response.data.notification);
          });

        }

    });

    }

  /**
   * Fetch UnRead Notifications Count
   *
   * @param user_id User ID number
   * @param all Boolean to show all or just unread
   * @returns promise
   */
   async getNotificationsUnreaded(user_id: number) {
    return new Promise<Notification[]>((resolve) => {
      this.http.get(`${Env.api()}/notification/find/${user_id}?unreadOnly=1`).subscribe((response: { message: number; data: NotificationsList }) => {
        this.notificationsUnreadedSource.next(response.data.notification);
        resolve(response.data.notification);
      });

  });

  }

  /**
   * Set Read Notification
   *
   * @param id Notification ID number
   * @returns promise
   */
   async readNotification(id: number) {
    return new Promise((resolve) => {

      const payload = {
        is_read: true
      }

      this.http.post(`${Env.api()}/notification/update/${id}`,payload).subscribe(() => {
        resolve(true);
      }, err => {
        this.alertCtrl.create({
          header: 'Error notification',
          message: 'There was an error while open set to read notification. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
    });

  });
  }

  /**
   * Set Read Notification
   *
   * @param id Notification ID number
   * @returns promise
   */
   async readAllNotifications(user_id: number) {
    return new Promise((resolve) => {

      const payload = {
        is_read: true
      }

      this.http.post(`${Env.api()}/notification/all_read/${user_id}`,payload).subscribe(() => {
        resolve(true);
      }, err => {
        this.alertCtrl.create({
          header: 'Error notification',
          message: 'There was an error while setting to read all notification. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
    });

  });
  }

  async showLocalNotification(id: number, title: string, text: string) {

    await LocalNotifications.schedule({
      notifications: [
        {
          title:title,
          body: text,
          id:id
        },
      ],
    });
  }

}
