/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// Ionic
import { AlertController } from '@ionic/angular';

// Services
import { ResponderService } from './responder.service';

// Interfaces
import Exercise, { ExerciseFormData, ExerciseList, ExerciseSingle } from '../_interfaces/Exercise.interface';
import BodyPart from '../_interfaces/BodyParts.interface';
import { UserExercise } from '../_interfaces/UserExercise.interface';
import { ExerciseSub, ExercisePermSub, ExercisePermSubFormData } from '../_interfaces/ExerciseSubs.interface';

// Env
import Env from './../env';

@Injectable({
  providedIn: 'root'
})
export class ExerciseSubService {

  private exerciseSubSource = new BehaviorSubject<any>('');
  exerciseSub = this.exerciseSubSource.asObservable();

  private exercisePermSubSource = new BehaviorSubject<any>('');
  exercisePermSub = this.exercisePermSubSource.asObservable();

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private responder: ResponderService
  ) { }

  async findByPrimaryExercise(exercise: UserExercise) {
    return new Promise<ExerciseSub[]>((resolve, reject) => {

      console.log('looking up subs for exercise', exercise);

      this.http.get(`${Env.api()}/exercises/subs/find/${exercise.id}`).subscribe((res: {
        status: number;
        message: string;
        data: { exercise_subs: ExerciseSub[] };
      }) => {
        resolve(res.data.exercise_subs);
      }, err => {
        this.responder.error(err,
          'Error Finding Primary Exercise Subs',
          'There was an error saving a set of macros. Please try again or contact support.');
      });

    });
  }

  /**
   * Selected Sub
   *
   * @param sub Substitute Exercise
   */
  selectedSub(sub: ExerciseSub) {
    this.exerciseSubSource.next(sub);
    console.log('Selected: ',sub);
  }

  /**
   * Selected Perm Sub
   *
   * @param sub Substitute Exercise
   */
  selectedPermSub(sub: ExerciseSub, permId: number) {
    const permSub = {
      sub,
      permId
    };
    this.exercisePermSubSource.next(permSub);
    console.log('Selected Perm: ',permSub);
  }

  /**
   * Find Permanent Subs
   *
   * @param sub Substitute Exercise
   */
  async findPermSubs(user_id?: number) {
    return new Promise<ExercisePermSub[]>((resolve, reject) => {

      console.log('looking up permant subs');

      let url: string;

      if(user_id) {
        url = `${Env.api()}/exercises/permsubs/find/${user_id}`;
      } else {
        url = `${Env.api()}/exercises/permsubs/find`;
      }

      this.http.get(url).subscribe((res: {
        status: number;
        message: string;
        data: { exercise_subs: ExercisePermSub[] };
      }) => {
        resolve(res.data.exercise_subs);
      }, err => {
        this.responder.error(err,
          'Error Finding Permanent Exercise Subs',
          'There was an error. Please try again or contact support.');
      });

    });
  }

  /**
   * Find Permanent Subs
   *
   * @param sub Substitute Exercise
   */
  async findPermSubById(id: number) {
    return new Promise((resolve, reject) => {

      console.log('looking up permant subs');

      const url = `${Env.api()}/exercises/permsubs/${id}`;

      this.http.get(url).subscribe((res: {
        status: number;
        message: string;
        data: ExercisePermSub;
      }) => {
        resolve(res.data);
      }, err => {
        this.responder.error(err,
          'Error Finding Permanent Exercise Sub',
          'There was an error. Please try again or contact support.');
      });

    });
  }

  /**
   * Create
   *
   * @param exercisePerm Exercise form values
   * @returns promise
   */
  async create(exercisePerm: ExercisePermSubFormData) {
    return new Promise((resolve, reject) => {

      if (!exercisePerm) {
        reject('No exercise provided');
      }

      this.http.post(`${Env.api()}/exercises/permsubs/create`, exercisePerm).subscribe((response: ExercisePermSub) => {
        if (response.id) {
          resolve(response.id);
        }
      }, err => {
        this.alertCtrl.create({
          header: 'Error Creating Permanent Sub Exercise',
          message: 'There was an error creating permanent sub exercise. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Update
   *
   * @param permID ID of Exercise to Update
   * @param exercisePerm Exercise form values
   * @returns promise
   */
  async update(exercisePerm: ExercisePermSubFormData) {
    return new Promise((resolve, reject) => {

      if (!exercisePerm) {
        reject('No exercise perm provided');
      }

      this.http.post(`${Env.api()}/exercises/permsubs/update/`, exercisePerm).subscribe((response: ExercisePermSub) => {
        if (response.id) {
          resolve(true);
        }
      }, err => {
        this.alertCtrl.create({
          header: 'Error Updating Permanent Sub Exercise',
          message: 'There was an error updating the permanent sub exercise. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });

    });
  }

  /**
   * Update
   *
   * @param permID ID of Exercise to Update
   * @param exercisePerm Exercise form values
   * @returns promise
   */
  async delete(exercisePermId: number) {
    return new Promise((resolve, reject) => {

      if (!exercisePermId) {
        reject('No exercise perm provided');
      }

      const payload = {};

      this.http.post(`${Env.api()}/exercises/permsubs/delete/${exercisePermId}`,payload).subscribe((response) => {
        if (response) {
          resolve(true);
        }
      }, err => {
        this.alertCtrl.create({
          header: 'Error Deleting Permanent Sub Exercise',
          message: 'There was an error deleting the permanent sub exercise. Please try again or contact support.',
          buttons: [{
            text: 'Dismiss'
          }],
          backdropDismiss: false
        }).then(alertEl => alertEl.present());
      });

    });
  }


}
