/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export default class Env {

	static env() {
		return (environment.production) ? 'production' : 'development';
	}

	static api() {
		let env;

		switch (this.env()) {
			case 'development':
				env = 'https://phpstack-984428-3452239.cloudwaysapps.com';
				break;
			default:
				env = 'https://api.anymanfitness.com';
				break;
		}
		return env;

	}

	static iconography() {
		return 'assets/iconography/';
	}

	static uploads() {
		let env;
		switch (this.env()) {
			case 'development':
				env = 'https://anymanfitness.nyc3.digitaloceanspaces.com';
				break;
			default:
				env = 'https://anymanfitness.nyc3.digitaloceanspaces.com';
				break;
		}
		return env;
	}

	static eCommerce() {
		let env;
		switch (this.env()) {
			case 'development':
				env = 'https://afcommdev.wpengine.com';
				break;
			default:
				env = 'https://shop.anymanfitness.com';
				break;
		}
		return env;
	}

	static marketing() {
		let env;
		switch (this.env()) {
			case 'development':
				env = 'https://anymanfitness.com';
				break;
			default:
				env = 'https://anymanfitness.com';
				break;
		}
		return env;
	}

  /**
   * Vimeo Access Token used for GET requests
   *
   * @returns string
   */
  static vimeoAccessToken() {
    return '1a654d548d0f457ee23a2d6fe5be917f';
  }

  /**
   * Wordpress Access Token used for GET, POST, DELETE, PUT requests
   *
   * @returns string
   */
   static wordpressAccessToken() {
    return '8b31bf87-3c9e-498a-88db-5a2c8c80a765';
  }

}
