import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  public timer = new BehaviorSubject<any>(null);
  sharedTimer = this.timer.asObservable();

  countdown: any;

  constructor() {

  }

  nextTimer(timer: any) {
    this.timer.next(timer);
  }

  setCountdown(countdown) {
    this.countdown = countdown;
  }

  getCountdown() {
    return this.countdown;
  }

}
