/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Ionic
import { Storage } from '@ionic/storage-angular';
import { AlertController, LoadingController } from '@ionic/angular';

// Interfaces
import LoginResponse, { UserData } from '../_interfaces/UserData.interface';

// Services
import { UserService } from '../_services/user.service';

import Env from '../env';
import Utils from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  userAuthenticated = false; // Auth boolean
  jwt: any; // Encoded
  userData: UserData; // Decoded JWT

  constructor(
    public router: Router,
    private storage: Storage,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private http: HttpClient,
    private user: UserService
    ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.userData && this.userData.sub && this.userAuthenticated) {
        // console.log('userData found, and userAuthenticated');
        if (next.data.role) {
          // console.log('Roles found for route');
          if (next.data.role.includes(this.userData.sub.user_role)) {
            // console.log('Role is allowed, okay to proceed...');
            return true;
          } else {
            // console.log('Role not allowed. Logging out...');
            this.logout();
            return false;
          }
        } else {
          console.warn('No Roles Provided for Route');
        }
      } else {
        // console.warn('userData not found, and not authenticated');
        this.router.navigate(['/login'], { replaceUrl: true });
        return false;
      }

    }

  /**
   * Parse the contents of a stored JWT.
   * If we have stored information, determine role and path of redirection.
   */
  async canContinue() {
    return new Promise((resolve) => {
      this.storage.get('jwt').then((jwt: string) => {
        const validJwt = Utils.parseJwt(jwt);
        if (jwt && validJwt) {
          this.jwt = jwt;
          this.userAuthenticated = true;
          this.userData = Utils.parseJwt(jwt);
          this.user.fetchProfile(this.userData.sub.user_id).then(() => {
            this.storage.get('userPath').then(path => {
              if (path) {
                  this.router.navigate([path]);
              } else {
                switch (this.userData.sub.user_role) {
                  case 1:
                    this.router.navigate(['/app/start-admin']);
                    break;
                  case 2:
                    this.router.navigate(['/app/start']);
                    break;
                  case 3:
                    this.router.navigate(['/app/start']);
                  break;
                }
              }
            });
          });
          resolve(this.userData);
        }
      });
    });
  }

  /**
   * Log in
   *
   * @param formValue Login Form Value
   */
   beginLogin(formValue) {
    this.http.post(`${Env.api()}/auth/authenticate`, formValue).subscribe((response: LoginResponse) => {
      switch (response.status) {
        case 200:
          this.login(response);
          break;
        case 401:
          this.invalidLogin();
          break;
        }
    });
  }

  /**
   * Invalid login alert
   */
  async invalidLogin() {
    const alert = await this.alertCtrl.create({
      header: 'Login Error',
      message: 'Invalid login credentials. Please try again.'
    });
    alert.present();
  }

  /**
   * Authenticate User, Store JWT Data, Redirect by Role
   *
   * @param response /auth/login response
   */
   async login(response: LoginResponse) {
    await this.storage.set('jwt', response.data.JWT).then(() => {
      this.loadingCtrl.create({ keyboardClose: true, message: 'Logging in' }).then(loadingEl => {
        loadingEl.present();
        this.jwt = response.data.JWT;
        this.userAuthenticated = true;
        this.userData = Utils.parseJwt(response.data.JWT);
        this.storage.set('userData', this.userData).then(() => {
          this.user.fetchProfile(this.userData.sub.user_id).then(() => {
            switch (this.userData.sub.user_role) {
              case 1:
                console.log('Admin login...');
                this.router.navigate(['/app/start-admin']);
                setTimeout(() => {
                  loadingEl.dismiss();
                }, 750);
                break;
              case 2:
                console.log('Member login...');
                this.router.navigate(['/app/start']);
                setTimeout(() => {
                  loadingEl.dismiss();
                }, 750);
                break;
              case 3:
                console.log('Free Member login...');
                this.router.navigate(['/app/start']);
                setTimeout(() => {
                  loadingEl.dismiss();
                }, 750);
                break;
            }
          });
        });
      });
    });
  }

  /**
   * Log out
   * Remove data from local storage and de-authenticate
   */
  logout() {
    this.loadingCtrl.create({ keyboardClose: true, message: 'Logging out' }).then(loadingEl => {
        loadingEl.present();
        this.storage.remove('jwt').then(() => {
            this.storage.remove('userData').then(() => {
                this.storage.remove('userProfile').then(() => {
                    this.storage.clear();
                    this.userAuthenticated = false;
                    window.location.reload();
                });
            });
        });
    });
  }

}
