/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Injectable } from '@angular/core';
import { Goals } from '../_interfaces/Goals.interface';
import { HttpClient } from '@angular/common/http';

// Interfaces
import { UserProfile } from '../_interfaces/UserData.interface';
import { MacroLookup, MacroQuestionLossGoal, MacroSet } from '../_interfaces/Onboarding.interface';
import Macros, { MacrosResponse } from '../_interfaces/Macros.interface';

// Services
import { ResponderService } from './responder.service';

// Macros Data
import * as macrosData from '../_data/macros.json';

// Env
import Env from './../env';

@Injectable({
  providedIn: 'root'
})
export class MacrosService {

  macros: MacroLookup;

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) {
    this.macros = macrosData.default;
  }

  /**
   * Calcualte Macros Based on User's Input
   *
   * @param weight Weight number in lbs
   * @param height Height in inches
   * @param gender Gender string
   * @param demanding_profession Question boolean
   * @param multiplier Multiplier from macroQuestionLossGoals()
   * @param goal Goal from goalsService
   */
  async calculateMacros(weight: number, height: number, gender: string, demanding_profession: boolean, multiplier: number, goal: string) {
    return new Promise<MacroSet>((resolve) => {

      if (!weight) { console.warn('Weight not provided'); }
      if (!height) { console.warn('Height not provided'); }
      if (!gender) { console.warn('Gender not provided'); }
      if (!multiplier) { console.warn('Multiplier not provided'); }
      if (!goal) { console.warn('Goal not provided'); }

      this.macroLookup(
        this.calculateDailyMaintenance(weight, multiplier),
        height,
        demanding_profession,
        gender,
        goal
      ).then(macros => {
        resolve(macros);
      });

    });
  }

  /**
   * Calculate daily maintenance calories
   *
   * @param unit_of_measurement Unit of measurement (imperial or metric)
   * @param weight Weight input (lbs)
   * @param multiplier Multiplier from macroQuestionLossGoals()
   */
  calculateDailyMaintenance(weight: number, multiplier: number) {
    const weightMultiplied = weight * multiplier;
    return Math.floor(weightMultiplied / 100) * 100;
  }

  /**
   * Calculate macros from the macroJSON() object
   *
   * @param dailyMaintenance Daily maintenance calories
   * @param height Height in inches
   * @param demanding_professsion Boolean
   * @param gender Gender string
   * @param goal Goal from goalService
   */
  async macroLookup(dailyMaintenance: number, height: number, demanding_professsion: boolean, gender: string, goal: string) {
    return new Promise<MacroSet>((resolve) => {

      if (!goal) {
        console.warn('macroLookup no goal provided', goal);
        return;
      }

      let genderKey: 'm' | 'f';
      switch (gender) {
        case 'Male':
          genderKey = 'm';
          break;
        case 'Female':
          genderKey = 'f';
          break;
      }

      // Add additional calories
      if (demanding_professsion) {
        dailyMaintenance += 100;
      }

      // Adjust calories based on height and gender
      if (genderKey === 'm' && height > 72) {
        dailyMaintenance += 100;

      }
      // if (genderKey === 'f' && height > 66) {
      //   // dailyMaintenance += 200;
      // }

      // Set limits based on gender
      switch (genderKey) {
        case 'm':
          if (dailyMaintenance > 2900) {
            dailyMaintenance = 2900;
          }
          break;
        case 'f':
          if (dailyMaintenance > 2200) {
            dailyMaintenance = 2200;
          }
          break;
      }

      const macrosByGender = this.macros[`${genderKey}`];

      // The Daily Energy Expenditure
      const macrosByTDEE = macrosByGender.filter(macros => macros.tdee === dailyMaintenance)[0];

      console.log('macroJSON', this.macroJSON);
      console.log('dailyMaintenance', dailyMaintenance);
      console.log('macrosByGender', macrosByGender);
      console.log('macrosByTDEE', macrosByTDEE);
      console.log('goal', goal);

      // Based on goal selection's name, find the JSON relative key to define macro set

      let macroSet: MacroSet;

      if (!macrosByTDEE) {
        return;
      }

      switch (goal) {
        case 'Lose Fat':
          macroSet = macrosByTDEE.lose;
          break;
        case 'Gain Muscle':
          macroSet = macrosByTDEE.gain;
          break;
        case 'Maintain / Body Recomposition':
          macroSet = macrosByTDEE.recomp;
          break;
      }

      resolve(macroSet);

    });
  }

  /**
   * Get list of Macro Loss Goal Answers
   *
   * @returns Array
   */
  macroQuestionLossGoals(): MacroQuestionLossGoal[] {
    return [
      {
        multiplier: 12,
        name: 'Over 30 pounds'
      },
      {
        multiplier: 13,
        name: '20-30 pounds'
      },
      {
        multiplier: 14,
        name: '10-20 pounds'
      },
      {
        multiplier: 15,
        name: '5-10 pounds'
      },
      {
        multiplier: 16,
        name: '5 pounds or less'
      }
    ];
  }

  /**
   * Return Macro JSON Object for Lookup
   *
   * @returns JSON object
   */
  macroJSON(): MacroLookup {
    return this.macros;
  }

  /**
   * Save Macros, and conditionally delete existing macros
   *
   * @param user User's profile object
   * @param macros Macro set object
   * @returns promise
   */
  async saveMacros(user: UserProfile, macros: MacroSet, existingMacros: Macros[]) {
    return new Promise((resolve) => {

      // If we have existing macros, delete them
      if (existingMacros.length) {
        this.deleteMacros(existingMacros).then(() => {
          console.log('Existing macros deleted');
          this.saveMacrosLoop(user, macros).then(() => {
            resolve(true);
          });
        });
      } else {
        this.saveMacrosLoop(user, macros).then(() => {
          resolve(true);
        });
      }

    });
  }

  /**
   * Loop through macros and save
   *
   * @param user User's profile object
   * @param macros Macro set object
   * @returns promise
   */
  async saveMacrosLoop(user, macros) {
    return new Promise((resolve) => {

      let macroSetCount = 0;
      if (macros.straight) { macroSetCount++; }
      if (macros.training) { macroSetCount++; }
      if (macros.rest) { macroSetCount++; }

      let loopCount = 0;
      for (const property in macros) {
        if (macros[property]) {
          this.saveMacroRecord(user, macros[property], `${property}`).then((payload) => {
            if (loopCount === macroSetCount) {
              resolve(true);
            }
          });
          loopCount++;
        }
      }

    });
  }

  /**
   * Delete Macros for a User
   *
   * @param macros Existing Macro Set
   * @returns promise
   */
  async deleteMacros(existingMacros: Macros[]) {
    return new Promise((resolve, reject) => {

      const macroSetCount = existingMacros.length;
      let loopCount = 0;
      existingMacros.forEach(macro => {
        this.deleteMacroRecord(macro).then((payload) => {
          if (loopCount === macroSetCount) {
            resolve(true);
          }
        });
        loopCount++;
      });

    });
  }

  /**
   * Save Macro Set Record
   *
   * @param user User Profile Object
   * @param macros Macro Set
   * @param setName Name of Macro Set
   * @returns promise
   */
  async saveMacroRecord(user: UserProfile, macros: { protein: number; carbs: number; fat: number}, setName: string) {
    return new Promise((resolve) => {

      const payload = {
        user: user.id,
        name: setName,
        protein: macros.protein,
        fat: macros.fat,
        carb: macros.carbs,
        calorie: this.caloriesCalc(macros.protein, macros.fat, macros.carbs)
      };

      this.http.post(`${Env.api()}/macros/create`, payload).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Saving Macros',
          'There was an error saving a set of macros. Please try again or contact support.');
      });

    });
  }

  /**
   * Update Macro Set Record
   *
   * @param user User Profile Object
   * @param macros Macro Set
   * @param setName Name of Macro Set
   * @returns promise
   */
   async updateMacroRecord(user: UserProfile, macros: { id: number; user: number; protein: number; carbs: number; fat: number, dateCreated: string; removed: number; dateRemoved: string}, setName: string) {
    return new Promise((resolve) => {

      const payload = {
        id: macros.id,
        user: macros.user,
        name: setName,
        protein: macros.protein,
        fat: macros.fat,
        carb: macros.carbs,
        calorie: this.caloriesCalc(macros.protein, macros.fat, macros.carbs),
        date_created: macros.dateCreated,
        removed: macros.removed,
        date_removed: macros.dateRemoved,
      };

      this.http.post(`${Env.api()}/macros/update/${macros.id}`, payload).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Saving Macros',
          'There was an error saving a set of macros. Please try again or contact support.');
      });

    });
  }

  /**
   * Delete Macro Set Record
   *
   * @param macros Macro Set
   * @returns promise
   */
  async deleteMacroRecord(macros: Macros) {
    // console.log(macros);
    return new Promise((resolve) => {

      this.http.post(`${Env.api()}/macros/delete/${macros}`, {}).subscribe(() => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Deleting Macro Set',
          'There was an error deleting a set of macros. Please try again or contact support.');
      });

    });
  }

  /**
   * Calculate calories from a set of macros
   *
   * @param protein Protein amount
   * @param fat Fat amount
   * @param carbs Carb amount
   */
  caloriesCalc(protein: any = 0, fat: any = 0, carbs: any = 0) {
		return (carbs * 4) + (fat * 9) + (protein * 4);
	}

  /**
   * Fetch Macros by User
   *
   * @param user User's profile object
   */
  async fetchMacrosByUserID(user: UserProfile)  {
    return new Promise<Macros[]>((resolve) => {

      this.http.get(`${Env.api()}/macros/user/${user.id}`).subscribe((macros: MacrosResponse) => {
        resolve(macros.data.macros);
      }, err => {
        this.responder.error(err,
          'Error Fetching Macros',
          'There was an error saving a set of macros. Please try again or contact support.');
      });

    });
  }

  /**
   * Fetch Macros by ID
   *
   * @param id Macros ID
   */
   async fetchMacrosByID(id: Number)  {
    return new Promise<Macros[]>((resolve) => {

      this.http.get(`${Env.api()}/macros/${id}`).subscribe((macros: MacrosResponse) => {
        resolve(macros.data.macros);
      }, err => {
        this.responder.error(err,
          'Error Fetching Macros',
          'There was an error saving a set of macros. Please try again or contact support.');
      });

    });
  }

}
