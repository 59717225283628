import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subscription'
})
export class SubscriptionPipe implements PipeTransform {

  transform(value: string | number): string {

    value = Number(value);

    switch (value) {
      case 5:
        return 'Legacy Membership';
      case 18:
        return 'Lifetime Membership';
      case 17:
        return 'Yearly Subscription';
      case 13:
        return 'Monthly Subscription';
    }

    return null;
  }

}
