/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Interfaces
import UserWorkoutList, { UserSessionWorkouts, UserWorkoutRecord } from '../_interfaces/UserWorkout.interface';

// Services
import { ResponderService } from './responder.service';

// Environment
import Env from './../env';

// Utils
import * as moment from 'moment';
import { UserExerciseData } from '../_interfaces/UserExercise.interface';
@Injectable({
  providedIn: 'root'
})
export class UserExerciseService {

  constructor(
    private http: HttpClient,
    private responder: ResponderService
  ) { }

  /**
   * Create User Workout
   *
   * @param exerciseData Processed payload from exercise-begin.page.ts
   * @returns promise
   */
   async createExerciseToWorkout(exerciseData: UserExerciseData) {
    return new Promise((resolve, reject) => {

      if (!exerciseData) {
        reject('No exerciseData provided');
      }

      this.http.post(`${Env.api()}/users/workouts/exercises/create`, exerciseData).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Creating User Exercise Data',
          'There was an error creating the user exercise data. Please try again or contact support.');
      });

    });

  }

  /**
   * Edit User Workout
   *
   * @param exerciseData Processed payload from exercise-begin.page.ts
   * @returns promise
   */
   async editExerciseToWorkout(exerciseData: UserExerciseData, user_workout_exercise_id: number) {
    return new Promise((resolve, reject) => {

      if (!exerciseData) {
        reject('No exerciseData provided');
      }

      this.http.post(`${Env.api()}/users/workouts/exercises/update/${user_workout_exercise_id}`, exerciseData).subscribe((res: any) => {
        resolve(res);
      }, err => {
        this.responder.error(err,
          'Error Updating User Exercise Data',
          'There was an error updating the user exercise data. Please try again or contact support.');
      });



    });

  }


}
