/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Angular
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Ionic
import { AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';

// Services
import { UserService } from 'src/app/_services/user.service';
import { MacrosService } from 'src/app/_services/macros.service';

// Interfaces
import Macros from 'src/app/_interfaces/Macros.interface';
import { UserProfile } from 'src/app/_interfaces/UserData.interface';

// Components
import { TooltipPage } from 'src/app/_components/tooltip/tooltip.page';

// Utils
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-edit-macros',
  templateUrl: './edit-macros.page.html',
  styleUrls: ['./edit-macros.page.scss'],
})
export class EditMacrosPage implements OnInit {

  @Input() macros: Macros;

  @Input() date: any = moment();
  @Input() dateView: any = moment(this.date).format('ddd, MMM Do, YYYY');

  form: FormGroup;

  userProfile: UserProfile;

  constructor(
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    public user: UserService,
    private macrosService: MacrosService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    public popoverController: PopoverController
  ) {

    this.form = this.fb.group({
      user: [''],
      id: [''],
      name: [''],
      protein: [''],
      carb: [''],
      fat: [''],
      dateCreated: [''],
      removed: [''],
      dateRemoved: ['']
    });

    this.form.valueChanges.subscribe(data => {
      // console.log('macros form', data);
    });

  }

  ngOnInit() {

    this.fetchMacros(this.macros);

  }

  /**
   * Get method for form control array
   */
   get errorControl() {
    return this.form.controls;
  }

  /**
   * Fetch Macros
   */
   fetchMacros(macros: Macros) {

    console.log('fetch macros content', macros);

    this.form.patchValue({
      user: this.userProfile.id,
      id: this.macros.id,
      name: this.macros.name,
      protein: this.macros.protein,
      carb: this.macros.carb,
      fat: this.macros.fat,
      dateCreated: moment(this.date).format('YYYY-MM-DD'),
      removed: 0,
      dateRemoved: null
    }, {emitEvent: false});

  }

  /**
   * Edit Macros Set
   */
   editMacros() {
    if (!this.form.valid) {
      console.log('Please provide all the required values!');
      return false;
    } else {


        const macros = {
          id: this.form.value.id,
          user: this.form.value.user,
          protein: this.form.value.protein,
          carbs: this.form.value.carb,
          fat: this.form.value.fat,
          dateCreated: this.form.value.dateCreated,
          removed: this.form.value.removed,
          dateRemoved: this.form.value.dateRemoved,
        };

        console.log('Form', macros);

      this.macrosService.updateMacroRecord(this.userProfile,macros,this.form.value.name).then(() => {
        this.addedToast();
      });
    }
  }

  /**
   * Delete Macros Set
   */
   deleteMacros() {
    if (!this.form.valid) {
      console.log('Please check if Macros is there yet!');
      return false;
    } else {


        const macros = {
          id: this.form.value.id
        };

        console.log('Form', macros);

      this.macrosService.deleteMacroRecord(macros.id).then(() => {
        this.deletedToast();
      });
    }
  }

  /**
   * Save Macros
   */
   async addedToast() {
    const toast = await this.toastCtrl.create({
      message: 'Macros Updated',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
  * Delete Macros
  */
  async deletedToast() {
    const toast = await this.toastCtrl.create({
      message: 'Macros Deleted',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Present Tooltip Text
   *
   * @param event Click event used to keep the tooltip relative to element clicked
   * @param tooltip String to display as tooltip
   */
   async tooltip(event, tooltip: string) {
    const popover = await this.popoverController.create({
      component: TooltipPage,
      cssClass: 'amf-tooltip',
      translucent: false,
      event,
      mode: 'md',
      componentProps: {
        tooltip
      }
    });
    await popover.present();
  }

}
