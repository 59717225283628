/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Angular
import { Component, OnInit } from '@angular/core';

// Ionic
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

// Services
import { UserService } from 'src/app/_services/user.service';
import { MacrosService } from 'src/app/_services/macros.service';

// Pages
import { AddMacrosPage } from '../add-macros/add-macros.page';
import { EditMacrosPage } from '../edit-macros/edit-macros.page';

// Interfaces
import Macros from 'src/app/_interfaces/Macros.interface';
import { UserProfile } from 'src/app/_interfaces/UserData.interface';

// Env
import Env from './../../env';




@Component({
  selector: 'app-macros',
  templateUrl: './macros.page.html',
  styleUrls: ['./macros.page.scss'],
})
export class MacrosPage implements OnInit {

  userProfile: UserProfile;

  macroGoals: Macros[]; // Array of Macros
  macros: Macros; // Selected Macros

  constructor(
    private user: UserService,
    public modalCtrl: ModalController,
    private storage: Storage,
    private macrosService: MacrosService
  ) {

    this.user.userProfile.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;

      // If we have a user object, fetch their associated macros
      if (this.userProfile?.id) {
        this.macrosService.fetchMacrosByUserID(this.userProfile).then(macros => {
          this.macroGoals = macros;

          // Check local storage for saved preference
          this.storage.get('macros').then(savedMacros => {
            if (savedMacros && this.macroGoals.length) {
              savedMacros = JSON.parse(savedMacros);
              this.macros = savedMacros;
            }
          });

          // If we have no macros yet, default to zero
          if (!this.macroGoals.length) {
            this.macros = {
              id: null,
              name: null,
              protein: 0,
              carb: 0,
              fat: 0,
              calorie: 0
            };
          }

        });
      }
    });


   }

  ngOnInit() {
  }

  /**
   * Add Macros Manually
   *
   */
   async openAddMacrosManually() {
    this.modalCtrl.create({
      component: AddMacrosPage,
      cssClass: 'anyman-modal dark half onboarding',
      componentProps: {
        userProfile: this.userProfile
      },
      backdropDismiss: false
    }).then(summary => {
      summary.present().then(() => {
        summary.onDidDismiss().then(() => {
          this.user.fetchProfile(this.userProfile.id);
        });
      });
    });
  }

  /**
   * Edit Macros
   *
   * @param id Macro id
   */
   async editMacro(macros: Macros) {
    this.modalCtrl.create({
      component: EditMacrosPage,
      cssClass: 'anyman-modal dark half onboarding',
      componentProps: {
        userProfile: this.userProfile,
        macros
      },
      backdropDismiss: false
    }).then(summary => {
      summary.present().then(() => {
        summary.onDidDismiss().then(() => {
          this.user.fetchProfile(this.userProfile.id);
        });
      });
    });
  }

}
