import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'exercise-summary',
    loadChildren: () => import('./_components/exercise-summary/exercise-summary.module').then( m => m.ExerciseSummaryPageModule)
  },
  {
    path: 'timer',
    loadChildren: () => import('./_components/timer/timer.module').then( m => m.TimerPageModule)
  },
  {
    path: 'view-measurements',
    loadChildren: () => import('./_components/view-measurements/view-measurements.module').then( m => m.ViewMeasurementsPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./member/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'tooltip',
    loadChildren: () => import('./_components/tooltip/tooltip.module').then( m => m.TooltipPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./_components/image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },
  {
    path: 'exercise-sub',
    loadChildren: () => import('./_components/exercise-sub/exercise-sub.module').then( m => m.ExerciseSubPageModule)
  },
  {
    path: 'renew',
    loadChildren: () => import('./member/renew/renew.module').then( m => m.RenewPageModule)
  },
  {
    path: 'session-complete',
    loadChildren: () => import('./_components/session-complete/session-complete.module').then( m => m.SessionCompletePageModule)
  },
  {
    path: 'view-sessions',
    loadChildren: () => import('./_components/view-sessions/view-sessions.module').then( m => m.ViewSessionsPageModule)
  },
  {
    path: 'view-session',
    loadChildren: () => import('./_components/view-session/view-session.module').then( m => m.ViewSessionPageModule)
  },
  {
    path: 'add-macros',
    loadChildren: () => import('./member/add-macros/add-macros.module').then( m => m.AddMacrosPageModule)
  },
  {
    path: 'macros',
    loadChildren: () => import('./member/macros/macros.module').then( m => m.MacrosPageModule)
  },
  {
    path: 'edit-macros',
    loadChildren: () => import('./member/edit-macros/edit-macros.module').then( m => m.EditMacrosPageModule)
  },
  {
    path: 'exercise-edit',
    loadChildren: () => import('./_components/exercise-edit/exercise-edit.module').then( m => m.ExerciseEditPageModule)
  },
  {
    path: 'featured-image-modal',
    loadChildren: () => import('./_components/featured-image-modal/featured-image-modal.module').then( m => m.FeaturedImageModalPageModule)
  },
  {
    path: 'filter-modal',
    loadChildren: () => import('./_components/filter-modal/filter-modal.module').then( m => m.FilterModalPageModule)
  },
  {
    path: 'promotion',
    loadChildren: () => import('./member/promotion/promotion.module').then( m => m.PromotionPageModule)
  },
  {
    path: 'exercise-library',
    loadChildren: () => import('./member/exercise-library/exercise-library.module').then( m => m.ExerciseLibraryPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
