import { Pipe, PipeTransform } from '@angular/core';

// Interface
import { UserExerciseRecordHistory } from '../_interfaces/UserWorkout.interface';

@Pipe({
    name: 'previousfilterloop',
    pure: false
})
export class PreviousFilterLoopPipe implements PipeTransform {
    transform(items: UserExerciseRecordHistory[], filter: Object, orderSuperset?: number): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if(orderSuperset) {
          return items.filter(item => item.exerciseId === filter && item.orderSuperset === orderSuperset);
        } else {
          return items.filter(item => item.exerciseId === filter);
        }

    }
}
