/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';


// Interfaces
import { UserProfile } from '../_interfaces/UserData.interface';
import { OnboardingComplete, OnboardingStep1, OnboardingStep2} from '../_interfaces/Onboarding.interface';

// Environment
import Env from './../env';

// Services
import { ResponderService } from './responder.service';

// Utils
import Utils from './../utils';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Member Profile Observable
  private userProfileSource = new BehaviorSubject<UserProfile>(null);
  userProfile = this.userProfileSource.asObservable();

  pushNotificationRegistrationRequest: any;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private responder: ResponderService
  ) {

  }

  /**
   * Fetch User Profile
   *
   * @param user_id User ID number
   * @returns promise
   */
  fetchProfile(user_id: number): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(`${Env.api()}/users/${user_id}`).subscribe((response: { status: number; data: UserProfile }) => {
        this.userProfileSource.next(response.data);
        resolve(response.data);
      });
    });
  }

  /**
   * Fetch User Profile By WP ID number
   *
   * @param user_id User ID number
   * @returns promise
   */
   fetchProfileWP(wp_user_id: number): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(`${Env.api()}/users/wordpress/${wp_user_id}`).subscribe((response: { status: number; data: UserProfile }) => {
        this.userProfileSource.next(response.data);
        resolve(response.data);
      });
    });
  }

  /**
   * Fetch List of Users
   *
   * @returns promise
   */
  fetchList(): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(`${Env.api()}/users`).subscribe((response: { status: number; data: UserProfile[] }) => {
        resolve(response.data);
      });
    });
  }

  /**
   * Save Profile Data
   *
   * @param payload Payload of form data
   * @returns promise
   */
  update(userProfile: UserProfile, payload: OnboardingStep1 | OnboardingStep2 | OnboardingComplete): Promise<any> {
    return new Promise((resolve) => {
      this.http.post(`${Env.api()}/users/update/${userProfile.id}`, payload).subscribe(() => {
          this.fetchProfile(userProfile.id).then(() =>{
            resolve(true);
          });
      }, err => {
        this.responder.error(err,
          'Error Updating Profile',
          'There was an error updating your profile data. Please try again or contact support.');
      });
    });
  }

  async updateAppAccess(user: UserProfile, app_access: number) {
    return new Promise((resolve) => {
      this.http.post(`${Env.api()}/users/update/wordpress/${user.wp_user_id}`, {
        key: 'app_access',
        value: app_access
      }).subscribe(res => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Updating Access',
          'There was an error updating this member\'s access. Please try again or contact support.');
      });
    });
  }

  async updateUser(user: UserProfile, key: string, value: string) {
    return new Promise((resolve) => {
      this.http.post(`${Env.api()}/users/update/wordpress/${user.wp_user_id}`, {
        key: key,
        value: value
      }).subscribe(res => {
        resolve(true);
      }, err => {
        this.responder.error(err,
          'Error Updating Member Info',
          'There was an error updating this member\'s info. Please try again or contact support.');
      });
    });
  }

  async updateUserEmailWordpress(user: UserProfile, email: string) {
    return new Promise((resolve) => {

      const payload = {
        user_id: user.wp_user_id,
        email: email
      }

      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

      const payloadString = JSON.stringify(payload);

      console.log('Payload update wordpress email:', payloadString);

      this.http.post(`${Env.eCommerce()}/wp-json/amf/v1/update_email`, payloadString, { headers, responseType: 'text'}).subscribe(res => {
        resolve(true);
      }, err => {
        console.log(err);
        this.responder.error(err,
          'Error Updating Email',
          'There was an error updating this member\'s email. Please try again or contact support.');
      });

    });
  }

  /**
   * Save unit_of_measurement
   *
   * @param unitMeasurement Payload of form data
   * @returns promise
   */
   updateUnitMeasurement(userProfile: UserProfile, unitMeasurement: string): Promise<any> {
    return new Promise((resolve) => {
      this.http.post(`${Env.api()}/users/update/${userProfile.id}`, {
        unit_of_measurement: unitMeasurement,
      }).subscribe(() => {
          this.fetchProfile(userProfile.id).then(() =>{
            resolve(true);
          });
      }, err => {
        this.responder.error(err,
          'Error Updating Profile',
          'There was an error updating your profile data. Please try again or contact support.');
      });
    });
  }


  /**
   * Register Device Token for Push Notifications
   *
   * @param user_id User's ID
   * @param user_role User's Role ID
   * @param device_token Device Token ID delivered through Firebase SDK
   * @param device_agent Device Agent from Ionic Capacitor Platform
   */
   async registerDeviceToken(user_id, user_role, device_token, device_agent)  {
    return new Promise((resolve, reject) => {

      // Cancel any outstanding requests
      if (this.pushNotificationRegistrationRequest) {
        this.pushNotificationRegistrationRequest.unsubscribe();
      }

      const date = new Date().toISOString().substring(0, 10);

      const payload = {
        user_id,
        user_role,
        device_token,
        device_agent,
        is_active: 1,
        registered_at: date,
        created_at: date,
        modified_at: date
      };

      console.log('Push notification payload user_id: ',payload.user_id);
      console.log('Push notification payload user_role: ',payload.user_role);
      console.log('Push notification payload device_agent: ',payload.device_agent);
      console.log('Push notification payload registered_at: ',payload.registered_at);
      console.log('Push notification payload created_at: ',payload.created_at);
      console.log('Push notification payload modified_at: ',payload.modified_at);

      this.pushNotificationRegistrationRequest = this.http.post(`${Env.api()}/device/create`, payload).subscribe(response => {

        console.log('Push notification registration response', response);

        // Show confirmation dialog for iOS users
        // Android users are automatically enrolled without prompt, no confirmation needed
        if (device_agent === '2') {
          this.alertCtrl.create({
            header: 'All Set!',
            message: 'You have successfully set up push notifications 🎉',
            buttons: ['Dismiss']
          }).then(alertEl => alertEl.present());
        }

        resolve(true);

      }, err => {

        // console.log('Error setting up push notifications', err);

      });

    });
  }

  /**
   * Reset User data
   *
   * @param userProfile Payload of form data
   * @returns promise
   */
   resetUserData(userProfile: UserProfile): Promise<any> {
    return new Promise((resolve) => {
      this.http.post(`${Env.api()}/users/reset/${userProfile.id}`, {}).subscribe(() => {
          this.fetchProfile(userProfile.id).then(() =>{
            resolve(true);
          });
      }, err => {
        this.responder.error(err,
          'Error Resetting Profile',
          'There was an error resetting your data profile data. Please try again or contact support.');
      });
    });
  }

}
