/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// Angular

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Ionic
import { AlertController, IonContent, ModalController, PopoverController, ToastController } from '@ionic/angular';

// Interfaces
import { UserProfile } from 'src/app/_interfaces/UserData.interface';
import Macros from 'src/app/_interfaces/Macros.interface';

// Services
import { UserService } from 'src/app/_services/user.service';
import { MacrosService } from 'src/app/_services/macros.service';

// Components
import { TooltipPage } from 'src/app/_components/tooltip/tooltip.page';

@Component({
  selector: 'app-add-macros',
  templateUrl: './add-macros.page.html',
  styleUrls: ['./add-macros.page.scss'],
})
export class AddMacrosPage implements OnInit {

  @Input() mode = 'add-macros';

  form: FormGroup;

  userProfile: UserProfile;

  constructor(
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    public user: UserService,
    private macrosService: MacrosService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    public popoverController: PopoverController,
  ) {

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      protein: ['', [Validators.required]],
      carb: ['', [Validators.required]],
      fat: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      // console.log('measurement form', data);
    });

  }

  ngOnInit() {
  }

  /**
   * Get method for form control array
   */
   get errorControl() {
    return this.form.controls;
  }

  /**
   * Add New Macros Set
   */
   addMacros() {
    if (!this.form.valid) {
      console.log('Please provide all the required values!');
      return false;
    } else {


        const macros = {
          protein: this.form.value.protein,
          carbs: this.form.value.carb,
          fat: this.form.value.fat,
        };

        // console.log('Form', macros);

      this.macrosService.saveMacroRecord(this.userProfile,macros,this.form.value.name).then(() => {
        this.addedToast();
      });
    }
  }

  /**
   * Save Profile
   */
   async addedToast() {
    const toast = await this.toastCtrl.create({
      message: 'Macros Added',
      duration: 1500,
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle-outline',
          text: '',
          handler: () => {

          }
        }
      ]
    });
    toast.present();
  }

  /**
   * Present Tooltip Text
   *
   * @param event Click event used to keep the tooltip relative to element clicked
   * @param tooltip String to display as tooltip
   */
   async tooltip(event, tooltip: string) {
    const popover = await this.popoverController.create({
      component: TooltipPage,
      cssClass: 'amf-tooltip',
      translucent: false,
      event,
      mode: 'md',
      componentProps: {
        tooltip
      }
    });
    await popover.present();
  }

}
